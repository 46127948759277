import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { CircularProgress } from "@material-ui/core";

import { getDividendListDetail } from "../../../store/actions";
import { isAnnuity, isGold /*, shimmerColors*/ } from "../../../helpers";
// import { formatDate } from '../../../helpers/dateHelper'
import SpecificTable from "./../../DividendCalendarSymbolView/components/SpecificTable";
// import AreaFilters from "../components/common/AreaFilters";
import { Container, Content } from "./components";
// import useWindowSize from "../../../helpers/hooks/useWindowSize";
// import { useStyles } from "./styles";

export default function IncomeTab({
	currency,
	symbol,
	dividendCalendarType,
	selectedInitialDate,
	selectedEndDate,
}) {
	const location = useLocation();
	// const styles = useStyles();
	const dispatch = useDispatch();

	// const dNow = new Date();
	// const yearNow = dNow.getFullYear().toString();
	//   const monthInd = dNow.getMonth();
	// const [windowWidth, windowHeight] = useWindowSize();

	const themeReducer = useSelector((state) => state.themeReducer);
	const loadingDividendDetailReducer = useSelector(state => state.isDividendDetailLoading);
	// const dividendListDetailReducer = useSelector(state => state.dividendListDetailGetReducer);
	//   const stockSymbolCountry = useSelector(state => state.stockSymbolCountry);
	const dividendListDetailReducer = useSelector(
		(state) => state.dividendListDetailGetReducer
	);
	const getDividendsListError = useSelector(
		(state) => state.getDividendsListError
	);

	const [, /*userGold*/ setUserGold] = useState(false);
	const [, /*visibleLoadDiag*/ setVisibleLoadDiag] = useState(false);
	//   const [arrYears, setArrYears] = useState([]);
	//   const [selectedYear, setSelectedYear] = useState("");
	// const [dialogOnlyPremium, setDialogOnlyPremium] = useState(false);
	//   const [visibleCountryDialog, setVisibleCountryDialog] = useState(false);

	const [dividends, setDividends] = useState(null);
	const [dividendsReady, setDividendsReady] = useState(false);
	const [, /*refreshing*/ setRefreshing] = useState(false);

	/* ---------------- Filters ---------------- */
	/*const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    return new Date(date.getFullYear(), 0, 1);
  });
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    return new Date(date.getFullYear(), 11, 31);
  });
  const [selectedInitialDate, setSelectedInitialDate] = useState(() => {
    const date = new Date();
    return formatDate(new Date(date.getFullYear(), 0, 1))
  })
  const [selectedEndDate, setSelectedEndDate] = useState(() => {
    const date = new Date();
    return formatDate(new Date(date.getFullYear(), 11, 31))
  })*/

	//   const [stocks, setStocks] = useState([]);
	const [hideResultsAutocomplete, setHideResultsAutocomplete] =
		useState(false);
	//   const [inputSearchAtivo, setInputSearchAtivo] = useState("");
	const [canAccess, setCanAccess] = useState(false);

	useEffect(() => {
		setDividendsReady(false);
	}, [location]);

	const reloadDividends = useCallback(
		(
			selectedInitialDate,
			selectedEndDate,
			symbolV,
			dividendCalendarTypeV
		) => {
			if (
				selectedInitialDate &&
				selectedEndDate &&
				symbolV &&
				dividendCalendarTypeV
			) {
				setTimeout(() => {
					let payload = {
						startDate: selectedInitialDate, // newFormatStartDate,
						endDate: selectedEndDate, // newFormatEndDate,
						symbol: symbolV,
						dividendCalendarType: dividendCalendarTypeV,
					};

					// console.warn('payload IncomeTab')
					// console.log(payload)
					dispatch(getDividendListDetail(payload));
				}, 1500);
			} else {
				setVisibleLoadDiag(false);
				setDividendsReady(true);
			}
		},
		[dispatch]
	);

	useEffect(() => {
		reloadDividends(
			selectedInitialDate,
			selectedEndDate,
			symbol,
			dividendCalendarType
		);
	}, [
		selectedInitialDate,
		selectedEndDate,
		reloadDividends,
		symbol,
		dividendCalendarType,
	]);

	const loadData = useCallback(async () => {
		//setVisibleLoadDiag(true);
		reloadDividends(
			selectedInitialDate,
			selectedEndDate,
			symbol,
			dividendCalendarType
		);
	}, [
		reloadDividends,
		dividendCalendarType,
		selectedEndDate,
		selectedInitialDate,
		symbol,
	]);

	useEffect(() => {
		setHideResultsAutocomplete(true);
		setDividendsReady(false);
	}, []);

	useEffect(() => {
		isGold()
			.then((gold) => {
				isAnnuity()
					.then((annuity) => {
						setUserGold(gold);
						setCanAccess(annuity || gold);
					})
					.catch(() => console.log("erro annuity"));
			})
			.catch(() => console.log("erro gold"));

		loadData();
	}, [loadData]);

	useEffect(() => {
		setDividendsReady(false);

		loadData();
	}, [loadData, symbol]);

	useEffect(() => {
		if (!_.isNull(dividendListDetailReducer)) {
			setDividends(dividendListDetailReducer);
			dividendsReducerUpdade();
			setVisibleLoadDiag(false);
			setDividendsReady(true);
			// console.log('dividendListDetailReducer');
			// console.log(dividendListDetailReducer);
		}
	}, [dividendListDetailReducer]);

	useEffect(() => {
		if (getDividendsListError) {
			alert("Ocorreu um erro ao carregar os dados!");
			setVisibleLoadDiag(false);
		}
	}, [getDividendsListError]);

	async function dividendsReducerUpdade() {
		setDividendsReady(true);
		setRefreshing(false);
		setVisibleLoadDiag(false);
	}

	//   function goToPremium() {
	//     setDialogOnlyPremium(false);
	//     history.push("/Premium");
	//   }

	/*function handleChangeStartDate(value) {
      const formatedDate = formatDate(value)
      setSelectedInitialDate(formatedDate)

      setStartDate(value);
      setVisibleLoadDiag(true);
      setDividendsReady(false);

      reloadDividends(formatedDate, selectedEndDate, symbol, dividendCalendarType);
      // reloadDividends(value, endDate, symbol, dividendCalendarType);
    }*/

	/*function handleChangeEndDate(value) {
      const formatedDate = formatDate(value)
      setSelectedEndDate(formatedDate)
  
      setEndDate(value);
      setVisibleLoadDiag(true);
      setDividendsReady(false);

      reloadDividends(selectedInitialDate, formatedDate, symbol, dividendCalendarType);
      // reloadDividends(startDate, value, symbol, dividendCalendarType);
    }*/

	//   function handleRefreshScreen() {
	//     setRefreshing(true);
	//     setDividendsReady(false);

	//     reloadDividends(startDate, endDate, symbol, dividendCalendarType);
	//   }

	return (
		<React.Fragment>
			<Container
				style={{ backgroundColor: themeReducer ? "#131A20" : "#fff" }}
			>
				{/* <AreaFilters
          initialDate={startDate}
          endDate={endDate}
          handleInitialDate={handleChangeStartDate}
          handleEndDate={handleChangeEndDate}
        /> */}

				<Content>
					{dividendsReady ? (
						<div
							style={{
								maxWidth: 800,
								paddingLeft: 20,
								backgroundColor: themeReducer
									? "#131A20"
									: "#fff",
							}}
						>
							{/* <MounthTitle>
                  {dividends.month} {year}
                </MounthTitle> */}

							{dividends && (
								<React.Fragment>
									<SpecificTable
										dividendName={symbol}
										dividendCalendarType={
											dividendCalendarType
										}
										loadingDividendDetailReducer={
											loadingDividendDetailReducer
										}
										dividendListDetailReducer={
											dividendListDetailReducer
										}
										// loadingDividendDetailReducer={{ isLoading: !dividendsReady }}
										// dividendListDetailReducer={dividends}
										currency={currency}
									/>
								</React.Fragment>
							)}
						</div>
					) : (
						<div
							style={{
								height: 300,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<CircularProgress />
						</div>

						// <ShimmerComponent />
					)}
				</Content>
				{/* {country === 'EUA' ? <BlurComponent navigation={navigation} /> : null} */}
			</Container>

			{/* Dialog Only premium */}
			{/* <h4>{"<Dialog Premium>"}</h4>
      <PremiumModalView  setPremium={p => setPremium(p)}/> */}
		</React.Fragment>
	);
}
