import {
  CHART_DIVIDEND_CALENDAR_YEAR_GET_SAGA,
  GET_DIVIDENDS_LIST_SAGA,
  GET_DIVIDENDS_LIST_DETAIL_SAGA,
  GET_DIVIDENDS_LIST_ONLY_MY_SAGA,
  GET_DIVIDENDS_LIST_BY_TYPE_SAGA,
  GET_DIVIDENDS_LIST_DETAIL_CLEAR_REDUCER,

} from './index';

export function getDividendCalendar(payload) {
  return {
    type: CHART_DIVIDEND_CALENDAR_YEAR_GET_SAGA,
    payload,
  };
}

export function getDividendCalendarList(payload) {
  return {
    type: GET_DIVIDENDS_LIST_SAGA,
    payload,
  };
}

export function getDividendCalendarListDetail(payload) {
  return {
    type: GET_DIVIDENDS_LIST_DETAIL_SAGA,
    payload,
  };
}

export function clearDividendsListDetail() {
  return {
    type: GET_DIVIDENDS_LIST_DETAIL_CLEAR_REDUCER,
  };
}

export function getDividendCalendarListOnlyMy(payload) {
  return {
    type: GET_DIVIDENDS_LIST_ONLY_MY_SAGA,
    payload,
  };
}

export function getDividendCalendarListByType(payload) {
  return {
    type: GET_DIVIDENDS_LIST_BY_TYPE_SAGA,
    payload,
  };
}
