import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

// import BoxFollow from "./components/Follow/BoxFollow";
// import BoxFollow from "../Home/Follow/BoxFollow";

import {
	ScrollView,
	BoxData,
	BoxDataHeader,
	BoxDataLogo,
	BoxDataAreaName,
	BoxDataName,
	BoxDataBody,
	BoxDataBodySide,
	BoxDataBodySideRow,
	BoxDataBodyTitle,
	BoxDataBodyValue,
} from "./components";
import { getLocaleMoney } from "./functions";

export default function DataTab({
	responseData,
	// followsItems,
	// loadAlerts,
	// itemGuid,
	companyIndicators,
	currency,
}) {
	const dispatch = useDispatch();
	const themeReducer = useSelector((state) => state.themeReducer);

	const bgColor = themeReducer ? "#131A20" : "#fff";

	// console.warn('responseData')
	// console.log(responseData)

	useEffect(() => {
		//fadf
	}, [dispatch]);

	return (
		<React.Fragment>
			<ScrollView
			// showsVerticalScrollIndicator={false}
			>
				<BoxData
					style={{
						backgroundColor: bgColor,
						// borderColor: themeReducer ? "#1D262F" : "#ccc",
						border: "2px solid",
						borderColor: themeReducer ? "#777" : "#ccc",
					}}
				>
					{responseData && (
						<>
							<BoxDataHeader>
								<BoxDataLogo
									source={responseData.logo}
									resizeMode="contain"
								/>
								<BoxDataAreaName>
									<BoxDataName /*allowFontScaling={false}*/>
										{responseData.symbol}
									</BoxDataName>
								</BoxDataAreaName>
								{/* <BoxDataBtnMenu onClick={goToExtract}>
              <Icons name="arrow-right" size={14} color="#535353" />
            </BoxDataBtnMenu> */}
							</BoxDataHeader>
							<BoxDataBody>
								{companyIndicators ? (
									<>
										{_.isArray(
											companyIndicators.indicators
										) &&
											companyIndicators.indicators.map(
												(indicator, ind) => {
													const { name, value } =
														indicator;

													return (
														<BoxDataBodySide
															key={
																"indicators-" +
																ind
															}
														>
															<BoxDataBodySideRow>
																<BoxDataBodyTitle>
																	{
																		indicator.name
																	}
																</BoxDataBodyTitle>
																<BoxDataBodyValue>
																	{name ===
																	"Cotistas"
																		? getLocaleMoney(
																				value
																		  )
																		: value.indexOf(
																				"%"
																		  ) > -1
																		? value
																		: currency +
																		  value}
																</BoxDataBodyValue>
															</BoxDataBodySideRow>
														</BoxDataBodySide>
													);
												}
											)}
									</>
								) : (
									<div>Não há dados para mostrar</div>
								)}
							</BoxDataBody>
						</>
					)}
				</BoxData>

				{/* <BoxFollow
          followsItems={followsItems}
          loadAlerts={loadAlerts}
          itemGuid={itemGuid}
        /> */}
			</ScrollView>
		</React.Fragment>
	);
}
