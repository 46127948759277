import {
  DIVIDEND_GET_REDUCER,
  DIVIDEND_GET_LIST_ONLY_MY_REDUCER,
  CLEAR_ALL_REDUCER,
  DIVIDEND_GET_LIST_REDUCER,
  DIVIDEND_GET_LIST_DETAIL_REDUCER,
  DIVIDEND_GET_YIELD_LIST_REDUCER,
  DIVIDEND_GET_YIELD_DETAIL_REDUCER,
  DIVIDEND_GET_YIELD_DETAIL_ERROR_REDUCER,
  DIVIDEND_GET_YIELD_DETAIL_CLEAR_REDUCER,
  GET_DIVIDEND_RANKING_REDUCER
 } from "../actions";

export const dividendGetReducer = (state = [], action) => {
  switch (action.type) {
    case DIVIDEND_GET_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return [];
    default:
      return state;
  }
};


export const dividendGetYieldListReducer = (state = null, action) => {
  switch (action.type) {
    case DIVIDEND_GET_YIELD_LIST_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return null;
    default:
      return state;
  }
};

export const dividendGetYieldDetailReducer = (state = null, action) => {
  switch (action.type) {
    case DIVIDEND_GET_YIELD_DETAIL_REDUCER:
      return action.payload;
    case DIVIDEND_GET_YIELD_DETAIL_CLEAR_REDUCER:
      return null;
    case CLEAR_ALL_REDUCER:
      return null;
    default:
      return state;
  }
};

export const dividendYieldDetailErrorReducer = (state = false, action) => {
  switch (action.type) {
    case DIVIDEND_GET_YIELD_DETAIL_ERROR_REDUCER:
      return action.payload;
    case DIVIDEND_GET_YIELD_DETAIL_CLEAR_REDUCER:
      return false;
    case CLEAR_ALL_REDUCER:
      return false;
    default:
      return state;
  }
};

export const dividendListOnlyMyGetReducer = (state = {}, action) => {
  switch (action.type) {
    case DIVIDEND_GET_LIST_ONLY_MY_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return [];
    default:
      return state;
  }
};

export const dividendListGetReducer = (state = {}, action) => {
  switch (action.type) {
    case DIVIDEND_GET_LIST_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return [];
    default:
      return state;
  }
};

export const dividendListDetailGetReducer = (state = [], action) => {
  switch (action.type) {
    case DIVIDEND_GET_LIST_DETAIL_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return [];
    default:
      return state;
  }
};

export const dividendRankingReducer = (state = null, action) => {
  switch (action.type) {
    case GET_DIVIDEND_RANKING_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return null;
    default:
      return state;
  }
};
