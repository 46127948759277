import {
  GET_SEARCH_SAGA,
  GET_FOLLOW_SEARCH_SAGA,
  GET_SPLIT_BYSTOCK_SAGA
} from "./index";

export function getSearch(payload) {
  return {
    type: GET_SEARCH_SAGA,
    payload
  };
}
export function getFollowSearch(payload) {
  return {
    type: GET_FOLLOW_SEARCH_SAGA,
    payload
  };
}

export function getSplitByStock(payload) {
  return {
    type: GET_SPLIT_BYSTOCK_SAGA,
    payload
  };
}
