import { STOCK_SYMBOL_COUNTRY_REDUCER } from "../actions";

export const stockSymbolCountry = (state = [], action) => {
	switch (action.type) {
		case STOCK_SYMBOL_COUNTRY_REDUCER:
			return action.payload;
		default:
			return state;
	}
};
