import styled from "styled-components";

export const IndContainer = styled.div`
	width: 98%;
	display: flex;
	justify-content: center;

	position: absolute;
	top: 90px;
	left: 10px;

	@media only screen and (max-width: 700px) {
		top: 58px;
		left: 20px;
		width: unset;
	}
`;

export const Top = styled.div`
	color: #ffffff;
	font-size: 15px;
	margin-bottom: 12px;

	display: flex;
	align-items: center;
`;

export const Title = styled.span`
	margin: 0 auto;
`;

export const IndBody = styled.div`
	display: flex;
	flex-wrap: wrap;
	/*width: 365px;
	gap: 32px 45px;
	overflow-y: auto;
	max-height: 450px;*/

	gap: 13px 13px;
	overflow-x: auto;
	height: 203px;

	@media only screen and (max-width: 700px) {
		/* max-height: 284px; */

		width: 100%;
		gap: 10px 20px;

		background: #00000069;
		padding-left: 20px;
		margin-left: -20px;
		padding-bottom: 10px;

		max-height: unset;
		overflow: unset;
	}
`;
