import styled from "styled-components";

export const RankingsList = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 2%;

	margin-top: -67px;

	@media only screen and (min-width: 1920px) {
		gap: 83px;
	}

	@media only screen and (max-width: 700px) {
		margin-top: 50px;
	}
`;
