import React, { useEffect, useState } from "react";
import { Link/*, useLocation*/ } from "react-router-dom";

import { ActiveBottom, ItemText, MenuItem } from "./styles";
function ListItem({ item, showList, onClick, isActive=false }) {
    const { text, to } = item;
    // const location = useLocation();
    const [active, setActive] = useState(false);

    // useEffect(() => {
    //     setActive(location.pathname === to) 
    // }, [location.pathname, to])

	useEffect(() => {
        setActive(isActive) 
    }, [isActive])

	return (
		<MenuItem showList={!showList ? (active): true} onClick={onClick}>
			<Link to={to} style={{ textDecoration: "none" }}>
				<ItemText active={active}>{text}</ItemText>
				<ActiveBottom active={active} />
			</Link>
		</MenuItem>
	);
}

export default ListItem;
