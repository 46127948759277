import { takeLeading, call, put } from "redux-saga/effects";
import {
	STOCK_GET_SYMBOL_SAGA,
	STOCK_GET_SYMBOL_REDUCER,
	GET_STOCK_MARKET_MOVERS_SAGA,
	GET_STOCK_MARKET_MOVERS_REDUCER,
} from "../actions";

import {
	/*getStockBySymbolApi,*/
	getStockBySymbolCountryApi,
	getStockMarketMoversApi,
} from "../apis";

import { clearStorage, getToken } from "./index";

export function* watchGetStockBySymbolAsync() {
	yield takeLeading(STOCK_GET_SYMBOL_SAGA, getStockBySymbolCountry);
}

export function* watchGetStockBySymbolCountryAsync() {
	yield takeLeading(STOCK_GET_SYMBOL_SAGA, getStockBySymbolCountry);
}

export function* watchGetStockMarketMoversAsync() {
	yield takeLeading(GET_STOCK_MARKET_MOVERS_SAGA, getStockMarketMovers);
}

// export function* getStockBySymbol(action) {
//   try {
//       let token = yield call(getToken);
//       const response = yield call(getStockBySymbolApi, token, action.payload);

//       if (response.ok) {
//           const payload = response.data && response.data.value ? response.data.value : [];
//           yield put({ type: STOCK_GET_SYMBOL_REDUCER, payload });
//       } else {

//         switch (response.status) {
//           case 401:
//               yield call(clearStorage);
//             break;
//           default:
//             break;
//         }
//       }
//   } catch (error) {
//     console.log(error);
//   }
// }

export function* getStockBySymbolCountry(action) {
	try {
		let token = yield call(getToken);
		const response = yield call(
			getStockBySymbolCountryApi,
			token,
			action.payload
		);

		if (response.ok) {
			const payload =
				response.data && response.data.value ? response.data.value : [];
			yield put({ type: STOCK_GET_SYMBOL_REDUCER, payload });
		} else {
			switch (response.status) {
				case 401:
					yield call(clearStorage);
					break;
				default:
					break;
			}
		}
	} catch (error) {
		console.log(error);
	}
}

export function* getStockMarketMovers(action) {
	let status;
	let responseOk = false;
	try {
		let token = yield call(getToken);
		let resObj = {};
		let typesArr = [];
		let { value, country, page } = action.payload;
		
		const typesBRStocks = [
			"BiggestFallsStocksBR",
			"BiggestFallsStocksBRAll",
			"BiggestHighsStocksBR",
			"BiggestHighsStocksBRAll",
		];

		const arrTopDividend = [
			"TopDividendPaidStocksBR",
			"TopDividendPaidFIIsBR",
			"TopDividendPaidUS",
			"TopDividendPaidREITsEUA",

			"TopDividendYieldStocksBR",
			"TopDividendYieldFIIsBR",
			"TopDividendYieldUS",
			"TopDividendYieldREITsEUA",
		];

		if (page === "home") {
			typesArr = [
				...arrTopDividend,
				...typesBRStocks,
			];
		} else if (page === "highAndLow") {
			const typesUSArr = ["BiggestHighsUS", "BiggestFallsUS"];
			const typesBRFIIsArr = ["BiggestHighsFIIsBR", "BiggestFallsFIIsBR"];
			const typesBDRArr = ["BiggestHighsBDR", "BiggestFallsBDR"];

			if (!value) {
				typesArr = typesBRStocks;
			} else if (country === "EUA") {
				typesArr = typesUSArr;
			} else {
				switch (value) {
					case "all": // all BR
						typesArr = [
							...typesBRStocks,
							...typesBRFIIsArr,
							...typesBDRArr,
						];
						break;

					case "Stocks":
						typesArr = [...typesBRStocks];
						break;

					case "FIIs":
						typesArr = [...typesBRFIIsArr];
						break;

					case "BDR":
						typesArr = [...typesBDRArr];
						break;

					default:
						break;
				}
			}
		} else if (page === "bestDividendPayers") {
			let { paidOrYield } = action.payload;

			if(!country && !value && !paidOrYield) {
				country = "Brazil";
				paidOrYield = "Paid";
				value = "all";
			}

			const auxCountry  = 'BR';
			let arrCountry;

			if (country === "Brazil") {
				arrCountry = arrTopDividend.filter(
					(item) => item.indexOf(auxCountry) > -1
				);
			} else { // EUA
				arrCountry = arrTopDividend.filter(
					(item) => item.indexOf(auxCountry) === -1
				);
			}

			const arrCountryType1 = arrCountry.filter(
				(item) => item.indexOf(paidOrYield) > -1
			);

			if(value === 'all') {
				typesArr = arrCountryType1
			} else {
				typesArr = arrCountryType1.filter(
					(item) => item.indexOf(value) > -1
				);
			}
			
		} else {
			console.warn("page not defined");
			return;
		}

		for (let i = 0; i < typesArr.length; i++) {
			const res = yield call(getStockMarketMoversApi, token, typesArr[i]);
			if (i === 0) {
				status = res.status;
				responseOk = res.ok;
			}
			resObj[typesArr[i]] = res && res.data.value;
		}

		const keys = Object.keys(resObj);
		let falls = [];
		let highs = [];

		keys.forEach((item, index) => {
			const auxProp = resObj[item];

			if (item.indexOf("Falls") > -1) {
				falls = [...falls, ...auxProp];

				if (page === "home") delete resObj[item];
			} else if (item.indexOf("Highs") > -1) {
				highs = [...highs, ...auxProp];
				if (page === "home") delete resObj[item];
			}
		});

		if (page === "highAndLow") {
			resObj = { falls, highs };
		} else if(page === 'home') {
			const firstsHighs = highs.slice(0, 4);
			const firstsFalls = falls.slice(0, 4);
			resObj = {
				...resObj,
				highsAndFallsToday: [...firstsHighs, ...firstsFalls],
			};
		} else if(page === 'bestDividendPayers') {
			let aux = []
			const keys = Object.keys(resObj)
			for(let i=0; i<keys.length; i++) {
				aux = aux.concat(resObj[keys[i]])
			}
			resObj = aux;
		}

		if (responseOk) {
			const payload = resObj;
			yield put({ type: GET_STOCK_MARKET_MOVERS_REDUCER, payload });
		} else {
			switch (status) {
				case 401:
					yield call(clearStorage);
					break;
				default:
					break;
			}
		}
	} catch (error) {
		console.log(error);
	}
}
