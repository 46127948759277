import { call, put, takeLeading } from "redux-saga/effects";
import {
  STOCK_SYMBOL_COUNTRY_REDUCER,
  STOCK_SYMBOL_COUNTRY_SAGA
} from "../actions";
import {
  stockBySymbolAndCountryApi
} from "../apis";
import { clearStorage, getToken } from "./index";

export function* watchStockSymbolCountry() {
  yield takeLeading(STOCK_SYMBOL_COUNTRY_SAGA, stockBySymbolAndCountrySaga);
}

export function* stockBySymbolAndCountrySaga(action) {
  try {
    const { payload } = action
    let token = yield call(getToken);
    let symbol = payload.symbol;
    let country = payload.country || payload.idCountry;
    const response = yield call(
      stockBySymbolAndCountryApi,
      token,
      symbol,
      country
    );

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];

      yield put({ type: STOCK_SYMBOL_COUNTRY_REDUCER, payload });
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}