import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  filtersContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 10,
    borderBottom: '1px solid #cfcfcf',
    maxWidth: 601,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '15px',
    flexWrap: 'wrap',
  },
  textField: {
    width: '60%',
    backgroundColor: '#fff'
  },
  formControl: {
    minWidth: 100,
    // backgroundColor: '#fff'
  },
  select: {
    textAlign: "center",
    textDecoration: "none",
    backgroundColor: 'green'
  },
  labelRoot: {
    // color: '#535353',
    font: 'normal normal normal 12px/14px Usual Bold',
    textTransform: 'uppercase'
  }
}));