import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function CustomAutocomplete({
	showLogo,
	options,
	label,
	setValue,
	value,
	setInput,
	optionName,
	style,
}) {
	const auxOptionName = optionName || "symbol";

	return (
		<Autocomplete
			style={style}
			value={value}
			onInput={(event) => setInput(event.target.value)}
			onChange={(event, newValue) => {
				setValue(newValue);
			}}
			selectOnFocus
			clearOnBlur
			handleHomeEndKeys
			id="custom-autocomplete"
			options={options}
			getOptionLabel={(option) => {
				return option[auxOptionName] || "";
			}}
			// renderOption={option => option[auxOptionName]}
			renderOption={(option) => {
				if (showLogo) {
					return (
						<div>
							<img
								loading="lazy"
								width="20"
								src={option.logo}
								alt="pesquisar ação / ativo"
								style={{ marginRight: 10 }}
							/>
							{option[auxOptionName]}
						</div>
					);
				} else {
					return option[auxOptionName];
				}
			}}
			freeSolo
			renderInput={(params) => (
				<TextField {...params} label={label} variant="outlined" />
			)}
		/>
	);
}
