import React from 'react'

import styles from './styles.module.scss';

export function ContainerPartnerButton({ children }) {
    return (
        <div className={styles.ContainerPartnerButton}>
          {children}  
        </div>
    )
}


export function PartnerButton({ children, onClick }) {
    return (
        <div className={styles.PartnerButton} onClick={onClick}>
          {children}  
        </div>
    )
}