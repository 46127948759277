import React, { useEffect, useState, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";
import TodayIcon from "@material-ui/icons/Today";

import {
	getDividendList,
	getDividendListDetail,
	setDivCalendarSymbolData,
	clearDividendsListDetail,
} from "../../store/actions";
import { countries } from "../../helpers/countries";

import {
	dividendCalendarSymbolLink,
	generalDividendsScheduleLink,
	dividendCalendarByTypeLink,
} from "../../helpers/internalLinks";
// import VerticalAds from '../../components/Ads/VerticalAds';
import Table from "../../components/dividensDetails/Table/index";
import Filters from "../../components/dividensDetails/Filters/index";

import { months } from "../../data/months";
// import { getBgColor, getTextColor, getTextColor2 } from "../../styles/themes/themeFunctions";
import HeaderTabsComp from "../../components/common/MoversGenericPage/HeaderTabsComp";
import { Header, List } from "../../components/common/MoversGenericPage/styles";
import HorizontalAds from "../../components/common/Annoucements/HorizontalAds";

import { AreaMenu, BtnMenu, BtnMenuIcon, BtnMenuText } from "./styles";

import styles from "./styles.module.scss";
import { PageContainer } from "./styles";

function mapStateToProps(state) {
	return {
		loadingReducer: state.loadingReducer,
		loadingDividendDetailReducer: state.isDividendDetailLoading,
		dividendListReducer: state.dividendListGetReducer,
		dividendListDetailReducer: state.dividendListDetailGetReducer,
		// purchaseReducer: state.purchaseReducer
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			getDividendList,
			getDividendListDetail,
		},
		dispatch
	);
}

function GeneralDividendsSchedule({
	dividendListReducer,
	loadingReducer,
	getDividendList,
	// purchaseReducer,
	hideHeader,
}) {
	const dispatch = useDispatch();
	// const themeReducer = useSelector((state) => state.themeReducer);
	const history = useHistory();

	const [active, setActive] = useState(0);

	const [dividendCalendarType, setDividendCalendarType] =
		useState("ByDataCom");
	const [country, setCountry] = useState("Brazil");
	const [open, setOpen] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedMonth, setselectedMonth] = useState(
		new Date().getUTCMonth() + 1
	);
	const [selectedYear, setselectedYear] = useState(
		new Date().getUTCFullYear()
	);
	const [titleResult, setTitleResult] = useState("");
	// const [tableHeight, setTableHeight] = React.useState(0);
	const [width /*height*/] = useWindowSize();

	// React.useEffect(() => {
	//   const pageDetailContainer = document.getElementById('page-detail-container');
	//   setTableHeight(pageDetailContainer.clientHeight);
	// }, [width])

	useEffect(() => {
		function initTitlePage() {
			setTitleResult(
				`${months[selectedMonth - 1]} ${selectedYear.toString()}`
			);
		}
		initTitlePage();
		getDividendList({
			year: selectedYear,
			mouth: selectedMonth,
			countryType: country,
			dividendCalendarType: dividendCalendarType,
		});
	}, []);

	useEffect(() => {
		getDividendList({
			year: selectedYear,
			mouth: selectedMonth,
			countryType: country,
			dividendCalendarType: dividendCalendarType,
		});
	}, [dividendCalendarType, country, selectedMonth, selectedYear]);

	function useWindowSize() {
		const [size, setSize] = React.useState([0, 0]);
		useLayoutEffect(() => {
			function updateSize() {
				setSize([window.innerWidth, window.innerHeight]);
			}
			window.addEventListener("resize", updateSize);
			updateSize();
			return () => window.removeEventListener("resize", updateSize);
		}, []);
		return size;
	}

	const handleChangeDividendCalendarType = (event) => {
		setDividendCalendarType(event.target.value);
	};

	const handleCountryChange = (event) => {
		setCountry(event.target.value);
	};

	const handleDateChange = (date) => {
		const selectedMonth = date.getUTCMonth();
		const selectedYear = date.getUTCFullYear();
		setTitleResult(`${months[selectedMonth]} ${selectedYear.toString()}`);
		setselectedMonth(selectedMonth + 1);
		setselectedYear(selectedYear);
		setSelectedDate(date);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	// right filter
	const handleClickRow = (event, tableCell) => {
		dispatch(clearDividendsListDetail());

		dispatch(setDivCalendarSymbolData(tableCell));

		history.push({
			pathname: `/${tableCell.stockName}`,
			obj: { ...tableCell, symbol: tableCell.stockName },
			country
		});
		// history.push(`${dividendCalendarSymbolLink}?symbol=${tableCell.stockName}`);
	};

	const textColor = "#434343"; // getTextColor(themeReducer);
	const page = "generalDividendsSchedule";

	const CalendarDayIcon = () => (
		<TodayIcon style={{ color: "#01cc7f", fontSize: 28 }} />
	);

	const menuDividendItems = [
		{
			key: "AllDividends",
			label: "Todos Ativos (B3)",
			icon: <CalendarDayIcon />,
			iconType: "awesome",
		},
		// {
		// 	key: "OnlySymbolBR",
		// 	label: "Filtro por ativo",
		// 	icon: <CalendarDayIcon />,
		// 	iconType: "awesome",
		// },
		{
			key: "acoes-br",
			label: "Apenas Ações (BR)",
			icon: <CalendarDayIcon />,
			iconType: "awesome",
		},
		{
			key: "fii",
			label: "Apenas FIIs",
			icon: <CalendarDayIcon />,
			iconType: "awesome",
		},
		{
			key: "etf",
			label: "Apenas ETFs",
			icon: <CalendarDayIcon />,
			iconType: "awesome",
		},
		{
			key: "reit",
			label: "Apenas REITs",
			icon: <CalendarDayIcon />,
			iconType: "awesome",
		},
		{
			key: "bdr",
			label: "Apenas BDRs",
			icon: <CalendarDayIcon />,
			iconType: "awesome",
		},
		{
			key: "acoes-eua",
			label: "Apenas Ações (EUA)",
			icon: <CalendarDayIcon />,
			iconType: "awesome",
		},
	];

	function redirectDividendCalendar(params) {
		if (params === "AllDividends") {
			//asyncLocalStorage.setItem(COUNTRY_CALENDAR, "Brazil").then(() => {
			history.push(generalDividendsScheduleLink);
			// history.push('DividendCalendar');
			//});
			return;
		} else if (params === "OnlySymbolBR") {
			//asyncLocalStorage.setItem(COUNTRY_CALENDAR, "Brazil").then(() => {
			history.push({
				pathname: dividendCalendarSymbolLink,
				state: {
					symbol: "",
					country: "Brazil",
					dividendCalendarType: "ByDataCom",
				},
			});
			//});
			return;
		} else {
			history.push(`${dividendCalendarByTypeLink}/${params}`);
		}
	}

	return (
		<div className={styles.upCommingDividendsView}>
			<div style={{ display: "flex", flexDirection: "row" }}>
				<PageContainer>
					<Grid
						container
						className={styles.gridContainer}
						style={{ width: "100vw" }}
					>
						<div className={styles.upCommingDividendsContent}>
							<HorizontalAds />

							<AreaMenu style={{ marginTop: 10 }}>
								{menuDividendItems.map((item) => (
									<BtnMenu
										key={item.key}
										onClick={() =>
											redirectDividendCalendar(item.key)
										}
									>
										{item.iconType === "awesome" ? (
											item.icon
										) : (
											<BtnMenuIcon src={item.icon} />
										)}
										<BtnMenuText>{item.label}</BtnMenuText>
									</BtnMenu>
								))}
							</AreaMenu>
							<Header page={page}>
								<HeaderTabsComp
									page={page}
									firstTabMoreImportant
									tabs={[
										{ title: "Agenda Geral de Dividendos" },
									]}
									active={active}
									setActive={setActive}
								/>

								{/* List Header Filters */}
								<Filters
									countries={countries}
									country={country}
									dividendCalendarType={dividendCalendarType}
									handleChangeDividendCalendarType={
										handleChangeDividendCalendarType
									}
									handleCloseCountrySelectInput={handleClose}
									handleOpenCountrySelectInput={handleOpen}
									handleCountryChange={handleCountryChange}
									handleDateChange={handleDateChange}
									openCountrySelec={open}
									selectedDate={selectedDate}
								/>
							</Header>

							<List page={page}>
								{/* ----------------------- List ----------------------- */}
								<div className={styles.dividendsResults}>
									<h2 style={{ color: textColor }}>
										{titleResult}
									</h2>
									{!dividendListReducer.hasOwnProperty(
										"dividendCalendarWrappers"
									) || loadingReducer.isLoading ? (
										<CircularProgress />
									) : (
										<div
											className={
												styles.dividendsResultsContainer
											}
										>
											{dividendListReducer.dividendCalendarWrappers.map(
												(dividend) => {
													return (
														<div
															className={
																styles.dividendsResultsContent
															}
															key={dividend.date}
														>
															<span
																style={{
																	backgroundColor:
																		"#f7f8fa" /*getBgColor(themeReducer)*/,
																}}
															>
																<h3
																	style={{
																		color: textColor,
																	}}
																>
																	{dividendCalendarType ===
																	"ByPaymentDate"
																		? "Pagamentos"
																		: "Data Com"}{" "}
																	dia{" "}
																	{
																		dividend.date
																	}
																</h3>
															</span>
															<Table // EnhancedTable
																dateIdType={
																	dividendCalendarType
																}
																tableItems={
																	dividend.dividends
																}
																dividendCalendarType={
																	dividendCalendarType
																}
																onClickCallback={
																	handleClickRow
																}
																country={
																	country
																}
															/>
														</div>
													);
												}
											)}
										</div>
									)}
								</div>
							</List>
						</div>
					</Grid>
				</PageContainer>

				{/* {purchaseReducer && !purchaseReducer.active && (
            <div className="ads-container">
              <VerticalAds />
            </div>
          )} */}
			</div>
		</div>
	);
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GeneralDividendsSchedule);
