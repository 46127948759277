export const homeLink = "/";
export const hightAndLowLink = "/maiores-altas-e-baixas";
export const bestDividendPayersLink = "/melhores-pagadores-de-dividendos";
export const generalDividendsScheduleLink = "/agenda-geral-de-dividendos";
export const dividendCalendarByTypeLink = "/agenda";

export const dividendCalendarSymbolLink = "/agenda-de-dividendos-por-ativo";
export const relevantFactLink = "/fatos-relevantes";
export const learnerCenterLink = "/central-do-aprendiz";

export const arrInternalURLs = [
    homeLink,
    hightAndLowLink,
    bestDividendPayersLink,
    generalDividendsScheduleLink,
    dividendCalendarByTypeLink,
    dividendCalendarSymbolLink,
    relevantFactLink,
    learnerCenterLink
]