import { api } from "./apiBase";

const urlSearch = "/Search";
const urlFollow = "/FollowStock";
const urlSplit = "/StockSplit";
// const urlSplit = "/Split";

export function getSearchApi(accessToken, data) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.get(`${urlSearch}/${data.name}`);
}

export function getFollowSearchApi(accessToken, data) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.get(`${urlFollow}/${data.idStock}`);
}

export function getSplitSearchApi(accessToken, data) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  /* console.log(
    `${urlSplit}/byStock?stockGuid=${data.stockGuid}&startDate=${data.startDate}&endDate=${data.endDate}`,
  ); */

  return api.get(
    `${urlSplit}/byStock?stockGuid=${data.stockGuid}&startDate=${data.startDate}&endDate=${data.endDate}`
  );
}
