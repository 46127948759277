import styled from "styled-components";

export const Container = styled.div`
	/* display: flex; */
	/* flex-direction: column; */
`;

export const Header = styled.div`
	height: 122px;
	background: #878787 0% 0% no-repeat padding-box;
	margin-top: 49px;
	display: flex;
	align-items: center;

	@media only screen and (max-width: 700px) {
		flex-wrap: wrap;
		height: auto;
	}
`;
export const HeaderLeft = styled.div`
	width: calc(50vw + 78.5px);
	display: flex;
	justify-content: flex-end;
`;

export const HeaderRight = styled.div`
	width: calc(50vw - 78.5px);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	padding-right: 64.12px;

	@media only screen and (max-width: 700px) {
		width: 100vw;
		justify-content: center;
		align-items: center;
		padding-right: unset;
		padding-top: 20px;
	}
`;

export const HeaderRightApp = styled.div`
	display: flex;
	flex-direction: column;
    align-items: flex-end;
}
`;

export const HeaderRightAppTitle = styled.span`
	height: 17px;
	font: normal normal bold 14px/17px Usual;
	letter-spacing: 0px;
	color: #262626;
	margin-right: 45px;
`;

export const Footer = styled.div`
	display: flex;
	flex-direction: column;
	/* height: 204px; */
	background: #262626 0% 0% no-repeat padding-box;
	box-shadow: 0px -3px 12px #00000040;
	padding: 0 7%;
`;

export const DividendosDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const TopText = styled.div`
	font: normal normal normal 15px/18px Usual;
	letter-spacing: 0px;
	color: #262626;
`;

export const LogosDownload = styled.div`
	display: flex;
	align-items: center;
`;

export const LinkStyledApple = styled.a`
	border-radius: 8px;
	transition: 0.5s;

	&:hover {
		background: #535353;
	}

	img {
		&:hover {
			background: #fff;
			border-radius: 8px;
		}
	}
`;

export const LinkStyledGoogle = styled.a`
	height: 33px;
	border-radius: 5px;
	margin-left: 15px;
	margin-top: 10px;
	margin-bottom: 11px;
	border-bottom: 4px solid transparent;
	transition: 0.5s;

	img {
		margin-top: -7px;
		margin-left: -8px;
		margin-right: -8px;
	}

	&:hover {
		background: #fff;
		border-bottom: 4px solid #535353;
	}
`;

export const FooterLinks = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	flex-wrap: wrap;

	@media only screen and (max-width: 586px) {
		flex-direction: column;
	}
`;

export const FooterBottomLeft = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
`;

export const InternalLinks = styled.div`
	display: flex;
	justify-content: space-between;
	flex: 2;

	@media only screen and (max-width: 700px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	@media only screen and (max-width: 586px) {
		margin-top: 20px;
	}
`;

export const FooterBottomLink = styled.a`
	font: normal normal 800 15px/18px Usual;
	letter-spacing: 0px;
	color: #b2b2b2;
`;

export const SocialDiv = styled.div`
	height: 20px;
	width: 100px;
	display: flex;
	gap: 16.8px;
	justify-content: end;

	flex: 1;

	svg {
		font-size: 34px;
		border-radius: 40px;
		padding: 3px;
		background-color: #878787;

		&:hover {
			background-color: #008d57;
		}
	}

	a {
		text-decoration: none;
		color: #262626;
	}

@media only screen and (max-width: 586px) {
    margin 20px auto;
}
`;

export const CopyRight = styled.div`
	text-align: center;
	font: normal normal normal 8px/10px Usual;
	letter-spacing: 0px;
	color: #b2b2b2;
	padding-bottom: 8px;
`;

export const ProductFooterText = styled.div`
	text-align: left;
	font: normal normal normal 8px/10px Usual;
	letter-spacing: 0px;
	color: #b2b2b2;
	margin-top: 18px;
	margin-right: 2px;
`;

export const DividendosLink = styled.a`
	text-decoration: none;
	color: #b2b2b2;
	transition: 0.5s;

	&:hover {
		background: white;
		border-radius: 5px;
	}
`;

export const DividendosLink2 = styled.a`
	text-decoration: none;
	color: #b2b2b2;
	transition: 0.5s;

	&:hover {
		font-weight: 600;
		color: #01cc7f;
	}
`;
