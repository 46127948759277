import { api } from "./apiBase";

const url = "/v2/AdvertiserExternal";

export function getAdvertiserExternalApi(accessToken) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.get(url);
}

export function getAdvertiserExternalDetailApi(accessToken, id) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.get(`${url}/${id}/details`);
}
