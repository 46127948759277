import { countries } from '../../../../../helpers/countries';

// select input
export const getMoverTypes = (country, page) => {
	const auxCountry = countries.find((item) => item.value === country).label;

	if (country === "EUA") {
		const arr = [{ label: "TODAS - EUA", value: "all" }];
    if(page === 'hightAndLow') {
    } else {
      arr.push({ label: "Ações", value: "US" })
      arr.push({ label: "REITs", value: "REITs" })
    }
    return arr;
	}

	const arr = [
		{ label: `TODAS - ${auxCountry}`, value: "all" },
		// { label: 'TODAS - EUA', value: 'all' },
		// { label: 'TODAS - BRASIL', value: 'all' },
		{ label: "IBOVESPA", value: "Stocks" },
		{ label: "FIIs", value: "FIIs" },
	];

	if (page === "highAndLow") arr.push({ label: "BDR", value: "BDR" });

	return arr;
};
