import { createTheme /*, useTheme*/ } from "@material-ui/core";
// import { NONE } from "apisauce";

import { getBgColor, getTextColor } from "./themeFunctions";
export const DefaultTheme = themeR => {
  // const bgColor = getBgColor(themeR)
  const textColor = getTextColor(themeR);

  return createTheme({
    palette: {
      mode: themeR ? "dark" : "light",
      text: {
        primary: textColor,
        secondary: themeR ? "#fff" : "#000"
      },
      primary: {
        main: "#01CC7F"
      },
      background: {
        paper: themeR ? "#1D262F" : "#FEFEFE",
        default: themeR ? "#000" : "#fff",
      }
    },
    typography: {
      fontFamily: `"Usual", "Helvetica", "Arial", sans-serif`,
      h6: {
        fontFamily: "Usual Bold",
        color: textColor
      }
    },
    overrides: {
      MuiDialogContentText: {
        root: {
          fontFamily: "Usual Regular"
        }
      },
      MuiOutlinedInput: {
        root: {
          backgroundColor: '#fff', //bgColor,
        },
      },
      MuiInputBase: {
        root: {
          height: "40px",
        },
        input: {
          color: textColor,
          font: "normal normal normal 18px/22px Usual Bold",
          fontSize: 16,
          letterSpacing: "-0.18px",
          marginRight: '-10px'
        },
      },
      MuiAutocomplete: {
        input: {
          textTransform: "uppercase",
          position: 'relative',
          bottom: 6,
        },
      },
      MuiFormLabel: {
        root: {
          fontFamily: "Usual Bold",
          color: '#535353',
        }
      },
      MuiTab: {
        root: {
          fontFamily: "Usual ExtraBold"
        }
      },
      MuiButton: {
        root: {
          fontFamily: "Usual ExtraBold",
          fontSize: "14px"
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: themeR ? "#1D262F" : "#FFFFFF",
          color: textColor
        }
      },
      MuiDrawer: {
        paperAnchorDockedLeft: {
          borderRight: "none"
        }
      },
      MuiSelect: {
        // selectMenu: {
        //   background: 'green',
        // },
        // select: {
        //   formControl: {
        //     // backgroundColor: 'pink',
        //   }
        // },
        root: {
          color: textColor,
          font: "normal normal normal 14px/16px Usual Bold",
          letterSpacing: "-0.18px",
          textTransform: "uppercase",
        },
        outlined: {
          paddingRight: "40px !important"
        }
      },
      MuiInputLabel: {
        root: {
          font: "normal normal normal 14px/16px Usual Bold",
        },
        outlined: {
          background: '#fff',
          position: 'absolute',
          top: -7
        }
      },
      MuiTextField: {
        root: {
          width: "100%",
          // backgroundColor: getBgColor(themeR),
        }
      },
      MuiPickersCalendarHeader: {
        dayLabel: {
          color: textColor
        },
      },

      MuiAccordion: {
        root: {
          // backgroundColor: bgColor,
          color: textColor,
        },        
      },
      MuiIconButton: {
        label: {
          color: textColor,
        }
      }
    }
  });
};
