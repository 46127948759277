import {
  CLEAR_ALL_REDUCER,
  DIVIDEND_CALENDAR_SYMBOL_DATA_REDUCER
} from '../actions';

export const divCalendarSymbolDataReducer = (state = null, action) => {
  switch (action.type) {
    case DIVIDEND_CALENDAR_SYMBOL_DATA_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return null;
    default:
      return state;
  }
};
