import styled from "styled-components";

export const PageContent = styled.div`
	background-color: #f7f8fa;
	max-width: 100%;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 60px;

    @media only screen and (max-width: 700px) {
		margin-top: 18px;

	}
`;
