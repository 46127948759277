import React from "react";
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';

import { IndicatorDiv, Value } from './styles.js'

const negativeColors = [ '#BB0303', '#DB1356' ];
const positiveColors = [ '#01CC7F', '#0ED93D' ];

const IndicatorValueComponent = ({
  value,
  fontSize = 17,
  iconSize = 12,
  showPercentage = false,
  neutral,
  type = 0,
}) => {
  const isPositive = value => {
    if (value.charAt(0) === "-") {
      return false;
    } else {
      return true;
    }
  };

  const negativeColor = negativeColors[type]
  const positiveColor = positiveColors[type]

  return (
    <IndicatorDiv fontSize={fontSize}>
      {!neutral && (isPositive(value) ? (
        <ArrowUpwardRoundedIcon style={{ color: positiveColor }} />
      ) : (
        <ArrowDownwardRoundedIcon style={{ color: negativeColor }} />
      ))}

      <Value
        style={!neutral ? { 
          color: isPositive(value) ? positiveColor : negativeColor,
         } : null }
         fontSize={fontSize}
        // type={`${neutral ? 'neutral' : isPositive(value) ? "positive" : "negative"}`}
      >
        {value}
        {showPercentage && "%"}
      </Value>
    </IndicatorDiv>
  );
};

export default IndicatorValueComponent;
