import {takeLeading, call, put} from 'redux-saga/effects';
import {GET_AFFILIATION_SAGA, GET_AFFILIATION_REDUCER} from '../actions';
import {getAffiliationItensApi} from '../apis';
import {clearStorage, getToken} from './index';

export function* watchAffiliationItensAsync() {
  yield takeLeading(GET_AFFILIATION_SAGA, getAffiliationItens);
}

export function* getAffiliationItens(action) {
  try {
    let token = yield call(getToken);
    // yield put({ type: Types.IS_GET_AFFILIATION_LOADING, isLoading: true });
    let { type } = action.payload;

    const response = yield call(getAffiliationItensApi, token, type);

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];
      yield put({type: GET_AFFILIATION_REDUCER, payload});
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}
