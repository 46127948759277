export const getStateByType = pathname => {
  const route = pathname.split("/")[2];

  switch (route) {
    case "acoes-br":
      return {
        screenTitle: "Calendário (Ações)",
        stockType: "Stocks"
      };

    case "fii":
      return {
        screenTitle: "Calendário (FIIs)",
        stockType: "FII"
      };

    case "etf":
      return {
        screenTitle: "Calendário (ETFs)",
        stockType: "ETF",
        country: "EUA"
      };

    case "reit":
      return {
        screenTitle: "Calendário (REITs)",
        stockType: "REIT",
        country: "EUA"
      };

    case "bdr":
      return {
        screenTitle: "Calendário (BDRs)",
        stockType: "BDR"
      };

    case "acoes-eua":
      return {
        screenTitle: "Calendário (Stocks)",
        stockType: "Stocks",
        country: "EUA"
      };

    default:
      return {
        screenTitle: "Calendário (Ações)",
        stockType: "Stocks"
      };
  }
};