import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@material-ui/core";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

import Nav from "./components/Nav";
import PageFooter from "./components/PageFooter";
import Routes from "./routes";
import "./style.scss";
import { PageContent, Container } from "./styles";
import { DefaultTheme } from "./styles/themes/default-theme";

const tokenValue =
	"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6WyI4OGVhM2MxYi01ZTM1LTRkNjQtYWVkMS1lOTU4OTlmM2UwYmIiLCI4OGVhM2MxYi01ZTM1LTRkNjQtYWVkMS1lOTU4OTlmM2UwYmIiXSwianRpIjoiYWVjY2U0Y2Y4OWJhNGMxNWI0YjFhMjQ3OWFiYzk5NWMiLCJyb2xlIjoiVXNlciIsIkVtYWlsIjoiZ3VpYWRpdmlkZW5kb3NAdGVzdGVkaXYuY29tIiwibmJmIjoxNjQxNDcyODk5LCJleHAiOjE3OTkxNTI4OTksImlhdCI6MTY0MTQ3Mjg5OSwiaXNzIjoiRGl2aWRlbmRvcyIsImF1ZCI6IkRpdmlkZW5kb3MifQ.2L_WROzngu2iAyRayXWUt39gC8yQ7LlFuTdDwcohQEA";

const handleToken = async () => {
	const key = "TOKEN_ACCESS";
	const token = await localStorage.getItem(key);

	if (token !== tokenValue) {
		await localStorage.setItem(key, tokenValue);
		window.location.reload();
	}
};

function App() {
	const themeReducer = useSelector((state) => state.themeReducer);
	const theme = DefaultTheme(themeReducer);

	const styledTheme = {
		isDark: themeReducer,
	};

	useEffect(() => {
		handleToken();
	}, []);

	return (
		<StyledThemeProvider theme={styledTheme}>
			<ThemeProvider theme={theme}>
				<Router>
					<PageContent>
						<Nav />

						<Container>
							<Routes />
						</Container>

						<PageFooter />
					</PageContent>
				</Router>
			</ThemeProvider>
		</StyledThemeProvider>
	);
}

export default App;
