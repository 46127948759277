import { create } from "apisauce";

export const api = create({
  baseURL: 'https://api.guiadividendos.com/', // "https://api.dividendos.me/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

// export const apiProd = create({
//   baseURL: "https://api.dividendos.me/",
//   headers: {
//     Accept: "application/json",
//     "Content-Type": "application/json"
//   }
// });
