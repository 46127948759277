import React from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";

import { getTextColor } from "../../../../styles/themes/themeFunctions";
import { generalDividendsScheduleLink } from "../../../../helpers/internalLinks";
import DividendTable from "./DividentTable/index";
import styles from "./styles.module.scss";

function SpecificTable({
	dividendName,
	dividendCalendarType,
	loadingDividendDetailReducer,
	dividendListDetailReducer,
	currency,
}) {
	const themeReducer = useSelector((state) => state.themeReducer);

	if (loadingDividendDetailReducer.isLoading) {
		return (
			<div className={styles.callToClick}>
				<CircularProgress />
			</div>
		);
	} else if (dividendListDetailReducer.length > 0 && dividendName) {
		return (
			<div className={styles.specificTable}>
				<DividendTable
					// headerCells={headSpecificDividendsCells}
					tableItems={dividendListDetailReducer}
					dividendCalendarType={dividendCalendarType}
					currency={currency}
					// maxHeight={tableHeight - 340}
				/>
			</div>
		);
	} else {
		return (
			<div className={styles.callToClick}>
				<p style={{ color: getTextColor(themeReducer) }}>
					Nenhum dado encontrato para o ativo{" "}
					<strong>{dividendName}</strong> no período filtrado.
					<br />
					Procure o 'Nome do Ativo' que gostaria de ver, ou
					entre&nbsp;
					<a href={generalDividendsScheduleLink}>aqui</a>
					&nbsp;e clique na linha do ativo que você quer
				</p>
			</div>
		);
	}
}

export default SpecificTable;
