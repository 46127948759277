import React from "react";
import MoversGenericPage from "../../components/common/MoversGenericPage";

function HightAndLow() {
	return (
		<MoversGenericPage
            page='highAndLow'
			title="Altas e Baixas"
			text="As maiores altas e baixas entre as ações de empresas
                listadas na B3 e nos Estados Unidos. Para refinar sua busca
                filtre por maiores baixas, maiores altas, país e índices."
			tabs={[{ title: "Maiores Altas" }, { title: "Maiores Baixas" }]}
		/>
	);
}

export default HightAndLow;
