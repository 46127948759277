import styled from "styled-components";

export const Announcement = styled.div`
/* width: 300px; */
height: 600px;
/* background: #ffffff 0% 0% no-repeat padding-box; */
border: 1px solid #f0f0f0;
border-radius: 10px;
margin-top: 74px;

display: flex;
justify-content: center;
align-items: center;

font: normal normal bold 30px/36px Usual;
letter-spacing: 0px;
color: #262626;
opacity: 0.45;

@media only screen and (max-width: 700px) {
    margin: 74px auto 0;
}
`;