import React from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from '@material-ui/core';

import { getTextColor } from "../../../../../styles/themes/themeFunctions";

import DividendTable from './DividentTable/index'
import styles from "./styles.module.scss";

function SpecificTable({
  // dividendName,
  // dividendCalendarType,
  loadingDividendDetailReducer,
  dividendListDetailReducer,
}) {

  const auxList = dividendListDetailReducer.map(item => {
    item.type = (item.proportionFrom < item.proportionTo) ? 'Desdobramento': 'Agrupamento'
    return item
  })

  const themeReducer = useSelector((state) => state.themeReducer);

  if (loadingDividendDetailReducer.isLoading) {
    return (
      <div className={styles.callToClick}>
        <CircularProgress />
      </div>
    );
  } else if (dividendListDetailReducer.length > 0/* && dividendName*/) {
    return (
      <div className={styles.specificTable}>
        <DividendTable
          // headerCells={headSpecificDividendsCells}
          tableItems={auxList}
          // maxHeight={tableHeight - 340}
        />
      </div>
    );
  } else {
    return (
      <div className={styles.callToClick}>
        <p style={{ color: getTextColor(themeReducer) }}>
          {/* Filtre o Ativo que gostaria de ver, ou entre&nbsp;
          <a href="/#/generalDividendsSchedule">aqui</a>
          &nbsp;e clique na linha do ativo que você quer */}
          {"Descupe! mas não foi encontrado nenhum dado :("}
        </p>
      </div>
    );
  }
}

export default SpecificTable;
