import { combineReducers } from "redux";

import {
	indicatorsReducer,
	companyIndicatorsReducer,
	indicatorsByTypeReducer,
} from "./indicatorsReducer";

import { affiliationTypeReducer } from "./affiliationReducer";

import {
	stocksBySymbolReducer,
	getStockMarketMoversReducer,
	idStockReducer,
} from "./stockReducer";

import {
	loadingReducer,
	isDividendDetailLoading,
	// isGetAffiliationLoading,
} from "../../utils/ducks/loading";

import {
	dividendGetReducer,
	dividendListOnlyMyGetReducer,
	dividendListGetReducer,
	dividendListDetailGetReducer,
	dividendGetYieldListReducer,
	dividendGetYieldDetailReducer,
	dividendYieldDetailErrorReducer,
	dividendRankingReducer,
} from "./dividendReducer";

import { stockSymbolCountry } from "./followReducer";

import {
	advertiserExternalReducer,
	advertiserExternalDetailReducer,
} from "./advertiserExternalReducer";

import {
	searchReducer,
	followSearchReducer,
	splitByStockReducer,
} from "./searchReducer";

import {
	// dividendCalendarReducer,
	getDividendsListReducer,
	getDividendsListByTypeReducer,
	getDividendsListDetailReducer,
	getDividendsListOnlyMyReducer,
	getDividendsListError,
  } from './dividendCalendarReducer';


import { divCalendarSymbolDataReducer } from './divCalendarSymbolReducer';

import {
	getRelevantFactReducer,
	getRelevantFactDetailReducer,
  } from './relevantFactReducer';

const rootReducer = combineReducers({
	indicatorsReducer,
	companyIndicatorsReducer,
	indicatorsByTypeReducer,
	affiliationTypeReducer,
	stocksBySymbolReducer,
	getStockMarketMoversReducer,
	idStockReducer,

	loadingReducer,
	isDividendDetailLoading,

	dividendGetReducer,
	dividendListOnlyMyGetReducer,
	dividendListGetReducer,
	dividendListDetailGetReducer,
	dividendGetYieldListReducer,
	dividendGetYieldDetailReducer,
	dividendYieldDetailErrorReducer,
	dividendRankingReducer,

	stockSymbolCountry,

	advertiserExternalReducer,
	advertiserExternalDetailReducer,

	searchReducer,
	followSearchReducer,
	splitByStockReducer,

	getDividendsListReducer,
	getDividendsListByTypeReducer,
	getDividendsListDetailReducer,
	getDividendsListOnlyMyReducer,
	getDividendsListError,

	divCalendarSymbolDataReducer,

	getRelevantFactReducer,
	getRelevantFactDetailReducer,
});

export default rootReducer;
