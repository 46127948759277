import React from "react";

import InstagramIcon from "@material-ui/icons/Instagram";
import TelegramIcon from "@material-ui/icons/Telegram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import GuiaLogo from "../../assets/images/footer/logo-guia@2x.png";
import DividendosLogo from "../../assets/images/footer/logo-dividendos@2x.png";
import AppleLogo from "../../assets/images/footer/apple@2x.png";
import GoogleLogo from "../../assets/images/footer/google-play-badge@2x.png";

import {
	Container,
	Footer,
	Header,
	TopText,
	HeaderLeft,
	HeaderRight,
	DividendosDiv,
	HeaderRightApp,
	HeaderRightAppTitle,
	LogosDownload,
	LinkStyledApple,
	LinkStyledGoogle,
	FooterLinks,
	FooterBottomLink,
	InternalLinks,
	SocialDiv,
	FooterBottomLeft,
	CopyRight,
	ProductFooterText,
	DividendosLink,
	DividendosLink2
} from "./styles";

function PageFooter() {
	return (
		<Container>
			<Header>
				<HeaderLeft>
					<DividendosDiv>
						<TopText>um produto:</TopText>
						<DividendosLink
							href="http://dividendos.me"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={DividendosLogo}
								alt="logo Dividendos.me"
								width={157}
							/>
						</DividendosLink>
					</DividendosDiv>
				</HeaderLeft>
				<HeaderRight>
					<HeaderRightApp>
						<HeaderRightAppTitle>
							Você conhece o nosso app?
						</HeaderRightAppTitle>
						<LogosDownload>
							<LinkStyledApple
								href="https://apps.apple.com/br/app/dividendos-me/id1479496256"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={AppleLogo}
									alt="Apple Store logo"
									width={105.08}
									height={35.14}
								/>
							</LinkStyledApple>
							<LinkStyledGoogle
								href="https://play.google.com/store/apps/details?id=com.dividendos.dividendosme"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={GoogleLogo}
									alt="Google Play logo"
									width={126}
									height={48}
								/>
							</LinkStyledGoogle>
						</LogosDownload>
					</HeaderRightApp>
				</HeaderRight>
			</Header>
			<Footer>
				<FooterLinks>
					<FooterBottomLeft>
						<img
							src={GuiaLogo}
							alt="logo Guia Dividendos"
							width={139}
							height={57}
						/>
						<div>
							<ProductFooterText>um produto </ProductFooterText>
							<DividendosLink2
								href="http://dividendos.me"
								target="_blank"
								rel="noreferrer"
							>
								dividendos.me
							</DividendosLink2>
						</div>
					</FooterBottomLeft>

					{/* <InternalLinks>
						<FooterBottomLink>
							Política de Privacidade
						</FooterBottomLink>
						<FooterBottomLink>Termos de Uso</FooterBottomLink>
						<FooterBottomLink>Suporte</FooterBottomLink>
					</InternalLinks> */}

					<SocialDiv>
						<a
							href="https://www.instagram.com/appdividendos/"
							target="_blank"
							rel="noreferrer"
						>
							<InstagramIcon className="social-link" />
						</a>
						<a
							href="https://t.me/appdividendos"
							target="_blank"
							rel="noreferrer"
						>
							<TelegramIcon className="social-link" />
						</a>

						{/* <WhatsAppIcon className="social-link" /> */}
					</SocialDiv>
				</FooterLinks>
				<CopyRight>
					dividendos.me © 2021 . Todos os direitos reservados
				</CopyRight>
			</Footer>
		</Container>
	);
}

export default PageFooter;
