import {GET_AFFILIATION_REDUCER, CLEAR_ALL_REDUCER} from '../actions';

export const affiliationTypeReducer = (state = [], action) => {
  switch (action.type) {
    case GET_AFFILIATION_REDUCER:
      return action.payload;
      case CLEAR_ALL_REDUCER:
        return null;
      default:
        return state;
  }
};
