import React, { useEffect } from "react";
import Ranking from "./Ranking";
import { RankingsList } from "./styles";


import { useDispatch, useSelector } from "react-redux";

import { getMover } from "../../store/actions";


function Rankings() {
	const dispatch = useDispatch();
	const marketMoversReducer = useSelector((state) => state.getStockMarketMoversReducer);
	const dude = <span style={{ color: 'red' }}>???</span>

	const arr = [
		{
			name: `FII'S`,
			desc: "Melhores pagadores de dividendos",
			list: marketMoversReducer.TopDividendPaidFIIsBR || [],
		},
		{
			name: `AÇÕES`,
			desc: "Melhores pagadores de dividendos",
			list: marketMoversReducer.TopDividendPaidStocksBR || [],
		},
		{
			name: "Maiores Altas e Baixas do Mercado Hoje",
			list: marketMoversReducer.highsAndFallsToday || [],
		},
		{
			name: `FII's`,
			desc: "Melhores Yield",
			list: marketMoversReducer.TopDividendYieldFIIsBR || [],
		},
		{
			name: `AÇÕES`,
			desc: "Melhores Yield",
			list: marketMoversReducer.TopDividendYieldStocksBR || [],
		},
		/*{
			name: dude,
			desc: "Melhores Dividendos - Ações EUA",
			list: marketMoversReducer.TopDividendPaidUS || [],
		},
		{
			name: dude,
			desc: "Melhores Yield - EUA",
			list: marketMoversReducer.TopDividendYieldUS || [],
		},*/
		{
			name: 'REITs',
			desc: "Melhores Div.",
			list: marketMoversReducer.TopDividendPaidREITsEUA || [],
		},
		{
			name: 'REITs',
			desc: "Melhores Yield",
			list: marketMoversReducer.TopDividendYieldREITsEUA || [],
		},
	];

	
	useEffect(() => {
		dispatch(getMover({ page: 'home' }));
	}, [dispatch]);

	return (
		<RankingsList>
			{arr.map((item, index) => (
				<Ranking key={`${item.name} ${index}`} item={item} />
			))}
		</RankingsList>
	);
}

export default Rankings;
