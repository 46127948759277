import {takeLeading, call, put} from 'redux-saga/effects';
import {
  GET_RELEVANT_FACTS_SAGA,
  GET_RELEVANT_FACT_REDUCER,
  GET_RELEVANT_FACTS_DETAIL_SAGA,
  GET_RELEVANT_FACTS_DETAIL_REDUCER,
} from '../actions';
import {getRelevantFactDetailApi, getRelevantFactsApi} from '../apis';
import {clearStorage, getToken} from './index';

export function* watchGetRelevantFactsAsync() {
  yield takeLeading(GET_RELEVANT_FACTS_SAGA, getRelevantFacts);
}
export function* watchGetRelevantFactDetailAsync() {
  yield takeLeading(GET_RELEVANT_FACTS_DETAIL_SAGA, getRelevantFactDetail);
}

export function* getRelevantFacts(action) {
  try {
    let token = yield call(getToken);
    const response = yield call(getRelevantFactsApi, token, action.payload);

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];
      yield put({type: GET_RELEVANT_FACT_REDUCER, payload});
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getRelevantFactDetail(action) {
  try {
    let token = yield call(getToken);
    let id = action.payload.id;

    const response = yield call(getRelevantFactDetailApi, token, id);

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];
      yield put({type: GET_RELEVANT_FACTS_DETAIL_REDUCER, payload});
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}
