import { api } from "./apiBase";

const url = "/Stock";
const urlMarketMovers = "/Stock/market-movers?makertMoversType=";

export function getStockBySymbolApi(accessToken, data) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.get(`${url}/bysymbol/${data}`);
}

export function getStockBySymbolCountryApi(accessToken, data) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.get(`${url}/bysymbolcountry/${data.symbol}/${data.idCountry}`);
}

export function getStockMarketMoversApi(accessToken, data) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.get(`${urlMarketMovers + data}`);
}
