import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { ThemeProvider } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";

import { getSearch } from "../../../store/actions";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import CustomAutocomplete from "../../../components/common/CustomAutocomplete";

import { useStyles } from "./useStyles";
import { Row } from "./styles";
import { getStockOptions } from "../../../helpers/autocompleteStocks";
// import { getBgColor } from '../../../styles/themes/themeFunctions';

const Filters = ({
	openCountrySelec,
	handleCloseCountrySelectInput,
	handleOpenCountrySelectInput,
	handleCountryChange,
	country,
	countries,
	handleInitialDate,
	handleEndDate,
	handleChangeDividendCalendarType,
	dividendCalendarType,
	initialDate,
	endDate,
	handleChangeDividendName,
	dividendName,
	stocks,
}) => {
	const dispatch = useDispatch();
	const themeReducer = useSelector((state) => state.themeReducer);
	const [userInput, setUserInput] = useState(null);

	useEffect(() => {
		if (userInput) {
			let payload = {
				name: userInput,
			};
			dispatch(getSearch(payload));
		}
	}, [dispatch, userInput]);

	const classes = useStyles();

	const verifyError = (date, callback) => {
		if (String(date) !== "Invalid Date" && date !== null) {
			callback(date);
		}
	};

	const bgColor = "#fff"; //getBgColor(themeReducer);

	return (
		<div className={classes.filtersContent}>
			<div className={classes.row}>
				<form autoComplete="off">
					<FormControl
						variant="outlined"
						className={classes.formControl}
					>
						<InputLabel htmlFor="open-select" />
						<Select
							open={openCountrySelec}
							onClose={(e) => handleCloseCountrySelectInput(e)}
							onOpen={(e) => handleOpenCountrySelectInput(e)}
							value={country}
							name="country"
							onChange={(e) => handleCountryChange(e)}
							IconComponent={KeyboardArrowDownIcon}
							inputProps={{
								id: "open-select",
							}}
							style={{ backgroundColor: bgColor }}
						>
							{countries.map((option, key) => (
								<MenuItem value={option.value} key={key}>
									<img
										src={option.src}
										alt={option.label}
										height="30px"
									/>{" "}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</form>
				<div style={{ width: "70%" }}>
					{/* <ThemeProvider theme={themeReducer} > */}
					<CustomAutocomplete
						optionName="symbol"
						showLogo
						options={getStockOptions(stocks)}
						label={"Nome do ativo"}
						setValue={handleChangeDividendName}
						value={dividendName}
						setInput={setUserInput} // on Change Text
					/>
					{/* </ThemeProvider> */}
				</div>
			</div>
			<Row>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
					<ThemeProvider theme={themeReducer}>
						<KeyboardDatePicker
							label="DE"
							autoOk
							variant="inline"
							inputVariant="outlined"
							format="dd/MM/yyyy"
							value={initialDate}
							keyboardIcon={<KeyboardArrowDownIcon />}
							onChange={(date) =>
								verifyError(date, handleInitialDate)
							}
							style={{ width: 171 }}
						/>
					</ThemeProvider>
				</MuiPickersUtilsProvider>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
					<ThemeProvider theme={themeReducer}>
						<KeyboardDatePicker
							label="ATÉ"
							autoOk
							variant="inline"
							inputVariant="outlined"
							format="dd/MM/yyyy"
							value={endDate}
							keyboardIcon={<KeyboardArrowDownIcon />}
							onChange={(date) =>
								verifyError(date, handleEndDate)
							}
							style={{ width: 171 }}
						/>
					</ThemeProvider>
				</MuiPickersUtilsProvider>
				<FormControl
					variant="outlined"
					className={classes.formControl}
					style={{ width: "30%" }}
				>
					<Select
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						value={dividendCalendarType}
						onChange={(e) => handleChangeDividendCalendarType(e)}
						IconComponent={KeyboardArrowDownIcon}
						style={{ backgroundColor: bgColor, minWidth: 140 }}
					>
						<MenuItem value={"ByDataCom"}>Data-Com</MenuItem>
						<MenuItem value={"ByPaymentDate"}>Pagamento</MenuItem>
					</Select>
				</FormControl>
			</Row>
		</div>
	);
};

export default Filters;
