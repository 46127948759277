import {
  INDICATORS_GET_REDUCER,
  GET_COMPANY_INDICATORS_REDUCER,
  GET_INDICATORS_BY_TYPE_REDUCER,
  CLEAR_INDICATORS_BY_TYPE_REDUCER,
  CLEAR_ALL_REDUCER,
} from '../actions';

export const indicatorsReducer = (state = [], action) => {
  switch (action.type) {
    case INDICATORS_GET_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return [];
    default:
      return state;
  }
};

export const companyIndicatorsReducer = (state = null, action) => {
  switch (action.type) {
    case GET_COMPANY_INDICATORS_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return null;
    default:
      return state;
  }
};

export const indicatorsByTypeReducer = (state = null, action) => {
  switch (action.type) {
    case GET_INDICATORS_BY_TYPE_REDUCER:
      return action.payload;
    case CLEAR_INDICATORS_BY_TYPE_REDUCER:
      return null;
    case CLEAR_ALL_REDUCER:
      return null;
    default:
      return state;
  }
};
