import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import ShareIcon from "@material-ui/icons/Share";

import { getIndicators } from "../../store/actions";
import IndicatorItem from "./IndicatorItem";
import { IndContainer, Top, Title, IndBody } from "./styles";

function Indicators() {
	const dispatch = useDispatch();
	const indicatorsReducer = useSelector((state) => state.indicatorsReducer);

	useEffect(() => {
		dispatch(getIndicators());
	}, [dispatch]);

	// console.warn("indicatorsReducer");
	// console.log(indicatorsReducer);

	return (
		<IndContainer>
			<IndBody /*style={{ maxWidth: `calc(130px*${indicatorsReducer.length}/2)` }}*/>
				{indicatorsReducer &&
					indicatorsReducer.map((indicator, index) => (
						<IndicatorItem indicator={indicator} key={index} />
					))}
			</IndBody>
		</IndContainer>
	);
}

export default Indicators;
