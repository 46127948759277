import React from "react";
import { Announcement } from './styles';

function VerticalAds() {
	// lateral (Vertical)
	return (
		<Announcement>
			<ins
				className="adsbygoogle"
				style={{ display: "block" }}
				data-ad-client="ca-pub-5832284872206965"
				data-ad-slot="2871310694"
				data-ad-format="auto"
				data-full-width-responsive="true"
			></ins>
			<script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
		</Announcement>
	);
}

export default VerticalAds;
