import React from "react";

function HorizontalAds() { // Anúncio - home e outras páginas
	return (
		<div
			style={{
				// height: 100,
				width: "100%",
                maxWidth: 1000,
                margin: '0 auto',
				marginBottom: 15,
                // background: "#fff",
            }}
		>
			<ins
				className="adsbygoogle"
				style={{ display: "block" }}
				data-ad-client="ca-pub-5832284872206965"
				data-ad-slot="4567535747"
				data-ad-format="auto"
				data-full-width-responsive="true"
			></ins>
			<script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
		</div>
	);
}

export default HorizontalAds;
