import {
  GET_ADVERTISER_EXTERNAL_REDUCER,
  GET_ADVERTISER_EXTERNAL_DETAIL_REDUCER,
  CLEAR_ALL_REDUCER
} from "../actions";

export const advertiserExternalReducer = (state = [], action) => {
  switch (action.type) {
    case GET_ADVERTISER_EXTERNAL_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return null;
    default:
      return state;
  }
};

export const advertiserExternalDetailReducer = (state = [], action) => {
  switch (action.type) {
    case GET_ADVERTISER_EXTERNAL_DETAIL_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return null;
    default:
      return state;
  }
};
