import React, { useState } from "react";
import { useSelector } from "react-redux";

import { getAdvertiserExternalDetail } from "../../../../store/actions";
import AdvertiserDetail from "../../../../components/AdvertiserBox/AdvertiserDetail";
import { ContainerPartnerButton, PartnerButton } from "./components";

import styles from "./styles.module.scss";

function ContainerPartnerComp({ advertisesExternal }) {
	const advertiserDetailExternalReducer = useSelector(
		(state) => state.advertiserExternalDetailReducer
	);

	/* Advertiser External Detail */
	const [advertiserExternalId, setAdvertiserExternalId] = useState(null);
	const [modalOpen, setModalOpen] = useState(advertiserExternalId);

	const contentHtml = advertisesExternal[0].contentHtml;
	const handleOpenModal = (advertiserExternalId) => {
		// advertserDetail modal
		setAdvertiserExternalId(advertiserExternalId);
		setModalOpen(true);

	};

	return (
		<>
			<ContainerPartnerButton onClick={() => alert('ok')}>
				<PartnerButton
					onClick={() =>
						handleOpenModal(
							advertisesExternal[0].advertiserExternalGuid
						)
					}
				>
					<iframe
						frameBorder="0"
						title="Advertiser External"
						srcDoc={contentHtml.replaceAll(
							"width:100%; height:100%;",
							"width:100%; height:auto;"
						)}
						// srcDoc={advertisesExternal[0].contentHtml}
						className={styles.partnerCompIframe}
					/>
				</PartnerButton>
			</ContainerPartnerButton>

			<AdvertiserDetail
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				advertiserDetailReducer={advertiserDetailExternalReducer}
				getAdvertiserDetail={getAdvertiserExternalDetail}
				advertiserId={advertiserExternalId}
				hasButton={true}
			/>
		</>
	);
}

export default ContainerPartnerComp;
