import React from 'react';
import { useSelector } from "react-redux";
import { 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';

import { getTextColor } from "../../../../../../styles/themes/themeFunctions";
import {
  useEnhancedTableHeadStyles,
  useNoResultsStyles,
  useStyles
} from './style';

function formateDate(date) {
  let yy = date.substr(6,2);
  let newdate  = (yy < 90) ? '20' + yy : '19' + yy;
  return newdate += '-' + date.substr(0,2) + '-' + date.substr(3,2);
}

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'proportionFrom') {
      let newA = a[orderBy] 
      let newB = b[orderBy]
      if (typeof(newA) === 'string') {
        newA = newA.replace(',', '').replace('.', '')
        newA = parseFloat(newA) / 100
      }
      if (typeof(newB) === 'string') {
        newB = newB.replace(',', '').replace('.', '')
        newB = parseFloat(newB) / 100
      }
      if (newB < newA) {
        return -1;
      }
      if (newB > newA) {
        return 1;
      }
  } else if (orderBy === 'dateSplit') { // filter date
    let newA = a[orderBy] 
    let newB = b[orderBy]

    let newDateA = formateDate(newA).split('-')
    let newDateB = formateDate(newB).split('-')

    if (parseInt(newDateB[2]) < parseInt(newDateA[2])) {
      return -1;
    } else if (parseInt(newDateB[0]) < parseInt(newDateA[0])) {
      return -1;
    } else if (parseInt(newDateB[1]) < parseInt(newDateA[1])) {
      return -1;
    }

    if (parseInt(newDateB[2]) > parseInt(newDateA[2])) {
      return 1;
    } else if (parseInt(newDateB[0]) > parseInt(newDateA[0])) {
      return 1;
    } else if (parseInt(newDateB[1]) > parseInt(newDateA[1])) {
      return 1;
    }

  } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headerCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const classesStyle = useEnhancedTableHeadStyles();

  const styleCell = (index) => {
    if (index === 0) {
      return classesStyle.curvedRightTableElement
    } else if (index === (headerCells.length - 1)) {
      return classesStyle.curvedLeftTableElement
    } else {
      return classesStyle.styledTableRow
    }
  }

  return (
       <TableHead>
          <TableRow>
            {headerCells.map((headCell, index) => (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
                className={styleCell(index)}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  classes={{root: classesStyle.baseSortElement, active: classesStyle.baseSortActiveElement}}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
  );
}


function EnhancedTable({ /*headerCells,*/ tableItems, maxHeight }) {
  const themeReducer = useSelector((state) => state.themeReducer);

  const headerCells = [ // headSpecificDividendsCells
    // { id: 'stockName', label: 'ATIVO' },
    { id: 'type', label: 'TIPO' },
    { id: 'dateSplit', label: 'DATA' },
    { id: 'proportionFrom', label: 'PROP. INÍCIO' },
    { id: 'proportionTo', label: 'PROP. FINAL' }
  ];

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('type');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const classes = useStyles();
  const emptyStyle = useNoResultsStyles();

  return (
    <div
      className={classes.root}
      style={{ color: getTextColor(themeReducer) }}
    >
      {
        tableItems.length ? (
          <TableContainer className={classes.tableContainer} /*style={{maxHeight: maxHeight}}*/>
            <Table
              stickyHeader
              aria-label="sticky table, enhanced table"
              aria-labelledby="tableTitle"
              className={classes.table}
            >
              <EnhancedTableHead className={classes.emptySpan} 
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={tableItems.length}
                headerCells={headerCells}
              />
              <TableBody>
                {stableSort(tableItems, getComparator(order, orderBy))
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                      >
                        <TableCell component="th" id={labelId} scope="row" align="left" className={classes.neutralValueCell}>
                          <span className={classes.emptySpan} >.</span>
                          {row.type}
                        </TableCell>

                        <TableCell align="center" className={classes.neutralValueCell}>
                          <span className={classes.emptySpan} >.</span>
                          {row.dateSplit}
                        </TableCell>

                        <TableCell align="center" className={classes.neutralValueCell}>
                          <span className={classes.emptySpan} >.</span>
                          {row.proportionFrom}
                        </TableCell>

                        <TableCell align="center" className={classes.neutralValueCell}>
                          <span className={classes.emptySpan} >.</span>
                          {row.proportionTo}
                        </TableCell>
                        {/* <TableCell align="right" className={classes.positiveValueCell}>
                          <span className={classes.emptySpan} >.</span>
                          R$ {row.proportionTo}
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className={emptyStyle.content}>
            <p className={emptyStyle.text}>
              Não foram encontrados pagamentos para essa data
            </p>
          </div>
        )
      }
    </div>
  );
}

export default EnhancedTable;
