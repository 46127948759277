import { Route, Switch } from "react-router-dom";

import Home from "../pages/Home";
import HightAndLow from "../pages/HightAndLow";
import BestDividendPayers from "../pages/BestDividendPayers";
import GeneralDividendsSchedule from "../pages/GeneralDividendsSchedule";
import DividendCalendarByType from "../pages/DividendCalendarByType";
import DividendsCalendarSymbol from "../pages/DividendCalendarSymbolView";
import RelevantFact from "../pages/RelevantFact";
import LearnerCenter from "../pages/LearnerCenterView";

import {
	hightAndLowLink,
	bestDividendPayersLink,
	generalDividendsScheduleLink,
	dividendCalendarByTypeLink,
	// dividendCalendarSymbolLink,
	relevantFactLink,
	learnerCenterLink,
} from "../helpers/internalLinks";

export default function Routes() {
	return (
		<Switch>
			<Route path="/" exact component={Home} />
			<Route path={hightAndLowLink} exact component={HightAndLow} />
			<Route
				path={bestDividendPayersLink}
				component={BestDividendPayers}
			/>
			<Route
				path={generalDividendsScheduleLink}
				component={GeneralDividendsSchedule}
			/>
			<Route path={relevantFactLink} component={RelevantFact} />
			<Route path={learnerCenterLink} component={LearnerCenter} />

			<Route
				path={dividendCalendarByTypeLink}
				component={DividendCalendarByType}
			/>

			<Route
				// path={dividendCalendarSymbolLink}
				component={DividendsCalendarSymbol}
			/>
			{/* <Route component={() => <h1>404 NOT FOUND</h1>} /> */}
		</Switch>
	);
}