import {select, takeLeading, call, put} from 'redux-saga/effects';
import {
  DIVIDEND_GET_SAGA,
  DIVIDEND_GET_REDUCER,
  DIVIDEND_EDIT_SAGA,
  DIVIDEND_DELETE_SAGA,
  DIVIDEND_ADD_SAGA,
  DIVIDEND_GET_PERIOD_SAGA,
  PORTFOLIO_GET_DIVIDEND_DETAILS_REDUCER,

  DIVIDEND_GET_LIST_ONLY_MY_SAGA,
  DIVIDEND_GET_LIST_ONLY_MY_REDUCER,
  DIVIDEND_GET_LIST_REDUCER,
  DIVIDEND_GET_LIST_SAGA,
  DIVIDEND_GET_LIST_DETAIL_SAGA,
  DIVIDEND_GET_LIST_DETAIL_REDUCER,
  GET_DIVIDEND_RANKING_SAGA,
  GET_DIVIDEND_RANKING_REDUCER,
  RESTORE_DIVIDENDS_SAGA,

  DIVIDEND_GET_YIELD_LIST_SAGA,
  DIVIDEND_GET_YIELD_LIST_REDUCER,
  DIVIDEND_GET_YIELD_DETAIL_SAGA,
  DIVIDEND_GET_YIELD_DETAIL_REDUCER,
  DIVIDEND_GET_YIELD_DETAIL_ERROR_REDUCER,
  isLoading,
  // isDividendDetailLoading
} from "../actions";
import {
  getDividendApi,
  editDividendApi,
  deleteDividendApi,
  addDividendApi,
  getDividendPeriodApi,
  getDividendYieldListApi,
  getDividendYieldDetailApi,
  getDividendRankingApi,
  restoreDividendsApi,

  getDividendListOnlyMyApi,
  getDividendListApi,
  getDividendListDetailApi,
} from "../apis";
import { clearStorage, getToken/*, getDividendDetails*/ } from "./index";
import {getPortfolioSetdReducer} from '../../selectors';

export function* watchGetDividendAsync() {
  yield takeLeading(DIVIDEND_GET_SAGA, getDividend);
}

export function* watchEditDividendAsync() {
  yield takeLeading(DIVIDEND_EDIT_SAGA, editDividend);
}

export function* watchAddDividendAsync() {
  yield takeLeading(DIVIDEND_ADD_SAGA, addDividend);
}

export function* watchDeleteDividendAsync() {
  yield takeLeading(DIVIDEND_DELETE_SAGA, deleteDividend);
}

export function* watchGetDividendPeriodAsync() {
  yield takeLeading(DIVIDEND_GET_PERIOD_SAGA, getDividendPeriod);
}

export function* watchGetDividendYieldListAsync() {
  yield takeLeading(DIVIDEND_GET_YIELD_LIST_SAGA, getDividendYieldList);
}

export function* watchGetDividendYieldDetailAsync() {
  yield takeLeading(DIVIDEND_GET_YIELD_DETAIL_SAGA, getDividendYieldDetail);
}

export function* watchGetDividendRankingAsync() {
  yield takeLeading(GET_DIVIDEND_RANKING_SAGA, getDividendRanking);
}

export function* watchRestoreDividendsAsync() {
  yield takeLeading(RESTORE_DIVIDENDS_SAGA, restoreDividends);
}

export function* watchGetDividendListOnlyMyAsync() {
  yield takeLeading(DIVIDEND_GET_LIST_ONLY_MY_SAGA, getDividendListOnlyMyAsync);
}


export function* watchGetDividendsListAsync() {
  yield takeLeading(DIVIDEND_GET_LIST_SAGA, getDividendListAsync);
}

export function* watchGetDividendsListDetailAsync() {
  yield takeLeading(DIVIDEND_GET_LIST_DETAIL_SAGA, getDividendListDetailAsync);
}

export function* getDividendListDetailAsync(action) {
  try {
    let token = yield call(getToken);
    yield put({ type: "IS_DIVIDEND_DETAIL_LOADING", isLoading: true });
    const response = yield call(getDividendListDetailApi, token, action.payload);
    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];
      yield put({ type: DIVIDEND_GET_LIST_DETAIL_REDUCER, payload });
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }

  } catch (error) {
    console.log(error);
  } finally {
    yield put({ type: "IS_DIVIDEND_DETAIL_LOADING", isLoading: false });
  }
}

export function* getDividendListAsync(action) {
  try {
    let token = yield call(getToken);
    yield put(isLoading(true));
    const response = yield call(getDividendListApi, token, action.payload);
    
    // console.warn('response')
    // console.log(response)
    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];
        // console.warn('payload')
        // console.log(payload)
      yield put({ type: DIVIDEND_GET_LIST_REDUCER, payload });
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }

  } catch (error) {
    console.log(error);
  } finally {
    yield put(isLoading(false));
  }
}

export function* getDividendListOnlyMyAsync(action) {
  try {
    let token = yield call(getToken);
    yield put(isLoading(true));
    const response = yield call(getDividendListOnlyMyApi, token, action.payload);

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];
      yield put({ type: DIVIDEND_GET_LIST_ONLY_MY_REDUCER, payload });
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }

  } catch (error) {
    console.log(error);
  } finally {
    yield put(isLoading(false));
  }
}

export function* getDividend(action) {
  try {
    let token = yield call(getToken);
    const response = yield call(getDividendApi, token);

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];
      yield put({ type: DIVIDEND_GET_REDUCER, payload });
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* addDividend(action) {
  try {
    let token = yield call(getToken);
    const response = yield call(addDividendApi, token, action.payload);

    if (response.ok) {
      // yield call(getDividendDetails);

    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* editDividend(action) {
  try {
    let token = yield call(getToken);
    const response = yield call(editDividendApi, token, action.payload);

    if (response.ok) {
      // yield call(getDividendDetails, action);
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteDividend(action) {
  try {
    let token = yield call(getToken);
    const response = yield call(deleteDividendApi, token, action.payload);

    if (response.ok) {
      //yield call(getDividendDetails);
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getDividendPeriod(action) {
  try {
    let token = yield call(getToken);
    let portfolioGuid = yield select(getPortfolioSetdReducer);
    const response = yield call(
      getDividendPeriodApi,
      token,
      portfolioGuid,
      action.payload,
    );

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : null;
        
      yield put({
        type: PORTFOLIO_GET_DIVIDEND_DETAILS_REDUCER,
        payload,
      });
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}


export function* getDividendYieldList(action) {
  try {
    let token = yield call(getToken);
    // const portfolioGuid = yield select(getPortfolioSetdReducer);
    const response = yield call(
      getDividendYieldListApi,
      token,
      action.payload.guidPortfolioSub,
      action.payload,
    );

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : null;
      yield put({
        type: DIVIDEND_GET_YIELD_LIST_REDUCER,
        payload,
      });
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getDividendYieldDetail(action) {
  try {
    let token = yield call(getToken);
    const response = yield call(
      getDividendYieldDetailApi,
      token,
      action.payload.guidPortfolioSub,
      action.payload,
    );

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : null;
      yield put({
        type: DIVIDEND_GET_YIELD_DETAIL_REDUCER,
        payload,
      });
    } else {
      yield put({
        type: DIVIDEND_GET_YIELD_DETAIL_ERROR_REDUCER,
        payload: true,
      });
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getDividendRanking(action) {
  try {
    let token = yield call(getToken);
    const response = yield call(
      getDividendRankingApi,
      token,
      action.payload.guidPortfolioSub,
      action.payload,
    );

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : null;
      yield put({
        type: GET_DIVIDEND_RANKING_REDUCER,
        payload,
      });
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* restoreDividends(action) {
  try {
    let token = yield call(getToken);
    const response = yield call(restoreDividendsApi, token, action.payload);

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : null;
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}