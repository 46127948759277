import {
  DIVIDEND_GET_SAGA,
  DIVIDEND_GET_PERIOD_SAGA,
  DIVIDEND_EDIT_SAGA,
  DIVIDEND_DELETE_SAGA,
  DIVIDEND_ADD_SAGA,

  DIVIDEND_GET_LIST_ONLY_MY_SAGA,
  DIVIDEND_GET_LIST_SAGA,
  DIVIDEND_GET_LIST_DETAIL_SAGA,
  GET_DIVIDEND_RANKING_SAGA,
  RESTORE_DIVIDENDS_SAGA,

  DIVIDEND_GET_YIELD_LIST_SAGA,
  DIVIDEND_GET_YIELD_DETAIL_SAGA,
  DIVIDEND_GET_YIELD_DETAIL_CLEAR_REDUCER,
} from "./index";

export function getDividend(payload) {
  return {
    type: DIVIDEND_GET_SAGA,
    payload
  };
}

export function getDividendPeriod(payload) {
  return {
    type: DIVIDEND_GET_PERIOD_SAGA,
    payload,
  };
}

export function getDividendYieldList(payload) {
  return {
    type: DIVIDEND_GET_YIELD_LIST_SAGA,
    payload,
  };
}

export function getDividendYieldDetail(payload) {
  return {
    type: DIVIDEND_GET_YIELD_DETAIL_SAGA,
    payload,
  };
}

export function clearDividendYieldDetail() {
  return {
    type: DIVIDEND_GET_YIELD_DETAIL_CLEAR_REDUCER,
  };
}

export function editDividend(payload) {
  return {
    type: DIVIDEND_EDIT_SAGA,
    payload
  };
}

export function deleteDividend(payload) {
  return {
    type: DIVIDEND_DELETE_SAGA,
    payload
  };
}

export function addDividend(payload) {
  return {
    type: DIVIDEND_ADD_SAGA,
    payload
  };
}

export function getDividendListOnlyMy(payload) {
  return {
    type: DIVIDEND_GET_LIST_ONLY_MY_SAGA,
    payload
  }
}

export function getDividendList(payload) {
  return {
    type: DIVIDEND_GET_LIST_SAGA,
    payload
  }
}

export function getDividendListDetail(payload) {
  return {
    type: DIVIDEND_GET_LIST_DETAIL_SAGA,
    payload
  }
}

export function getDividendRanking(payload) {
  return {
    type: GET_DIVIDEND_RANKING_SAGA,
    payload,
  };
}

export function restoreDividends(payload) {
  return {
    type: RESTORE_DIVIDENDS_SAGA,
    payload,
  };
}
