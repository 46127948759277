import { /*call,*/ put } from "redux-saga/effects";
import {
  LOGOUT_OK,
} from "../actions";

import {
  TOKEN_ACCESS,
  // TOKEN_EXPIRE,
  // TOKEN_REFRESH,
  // TOKEN_USER,
} from "../../helpers/index";


export async function getToken() {
  try {
    const value = localStorage.getItem(TOKEN_ACCESS);
    return value;
  } catch (error) {}
}

// function saveTokenStorage(payload) {
//   localStorage.setItem(TOKEN_ACCESS, payload.token);
//   localStorage.setItem(TOKEN_EXPIRE, payload.expires);
//   localStorage.setItem(TOKEN_REFRESH, payload.refreshToken);
//   localStorage.setItem(TOKEN_USER, payload.username);
// }

export function* clearStorage() {
  localStorage.clear();
  yield put({ type: LOGOUT_OK });
}
