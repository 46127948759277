import {takeLeading, call, put} from 'redux-saga/effects';
import {
  CHART_DIVIDEND_CALENDAR_YEAR_GET_SAGA,
  CHART_DIVIDEND_CALENDAR_YEAR_GET_REDUCER,
  GET_DIVIDENDS_LIST_SAGA,
  GET_DIVIDENDS_LIST_BY_TYPE_SAGA,
  GET_DIVIDENDS_LIST_BY_TYPE_REDUCER,
  GET_DIVIDENDS_LIST_REDUCER,
  GET_DIVIDENDS_LIST_DETAIL_SAGA,
  GET_DIVIDENDS_LIST_DETAIL_REDUCER,
  GET_DIVIDENDS_LIST_ONLY_MY_SAGA,
  GET_DIVIDENDS_LIST_ONLY_MY_REDUCER,
  GET_DIVIDENDS_LIST_ERROR_REDUCER,
} from '../actions';
import {
  getDividendCalendarApi,
  getDividendList,
  getDividendListDetail,
  getDividendListOnlyMy,
} from '../apis';
import {getDividendListByType} from '../apis/dividendApi';
import {clearStorage, getToken} from './index';

export function* watchGetDividendCalendarAsync() {
  yield takeLeading(CHART_DIVIDEND_CALENDAR_YEAR_GET_SAGA, getDividendCalendar);
}
export function* watchGetDividendCalendarListAsync() {
  yield takeLeading(GET_DIVIDENDS_LIST_SAGA, getDividendCalendarList);
}
export function* watchGetDividendCalendarListByTypeAsync() {
  yield takeLeading(
    GET_DIVIDENDS_LIST_BY_TYPE_SAGA,
    getDividendCalendarListByType,
  );
}

export function* watchGetDividendCalendarListDetailAsync() {
  yield takeLeading(
    GET_DIVIDENDS_LIST_DETAIL_SAGA,
    getDividendCalendarListDetail,
  );
}
export function* watchGetDividendCalendarListOnlyMyAsync() {
  yield takeLeading(
    GET_DIVIDENDS_LIST_ONLY_MY_SAGA,
    getDividendCalendarListOnlyMy,
  );
}

export function* getDividendCalendar(action) {
  try {
    let token = yield call(getToken);
    const response = yield call(getDividendCalendarApi, token, action.payload);

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : {};
      yield put({type: CHART_DIVIDEND_CALENDAR_YEAR_GET_REDUCER, payload});
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getDividendCalendarList(action) {
  try {
    let token = yield call(getToken);
    const response = yield call(getDividendList, token, action.payload);

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];
      yield put({type: GET_DIVIDENDS_LIST_REDUCER, payload});
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          yield put({type: GET_DIVIDENDS_LIST_ERROR_REDUCER, response});
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getDividendCalendarListDetail(action) {
  try {
    let token = yield call(getToken);
    const response = yield call(getDividendListDetail, token, action.payload);

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];
      yield put({type: GET_DIVIDENDS_LIST_DETAIL_REDUCER, payload});
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          yield put({type: GET_DIVIDENDS_LIST_ERROR_REDUCER, response});
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getDividendCalendarListByType(action) {
  try {
    let token = yield call(getToken);
    const response = yield call(getDividendListByType, token, action.payload);

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];
      yield put({type: GET_DIVIDENDS_LIST_BY_TYPE_REDUCER, payload});
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          yield put({type: GET_DIVIDENDS_LIST_ERROR_REDUCER, response});
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}


const getFakeDividends = (payload) => {
  const newArr = payload.dividendCalendarWrappers.map(i => {
    if(i.dividends.length > 0) {
        return {
            "date": "**/**/****",
            "dividends": [
                {
                    "idStock": 0,
                    "stockName": "XXXXX",
                    "dividendType": "***",
                    "dataCom": "**/**/****",
                    "paymentDate": "**/**/**",
                    "dividendCalendarGuid": "00000000-0000-0000-0000-000000000000",
                    "value": "???",
                    "yield": "???"
                }
            ]
        }
    } else {
        return i;
    }
  })

  return {
    ...payload,
    dividendCalendarWrappers: newArr
  }
}

export function* getDividendCalendarListOnlyMy(action) {
  try {
    let token = yield call(getToken);
    const response = yield call(getDividendListOnlyMy, token, action.payload);

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];
      const { premium } = action.payload;

      if(premium || payload.length === 0) {
        yield put({type: GET_DIVIDENDS_LIST_ONLY_MY_REDUCER, payload});

      } else {
        const fakePayload = getFakeDividends(payload)
        yield put({type: GET_DIVIDENDS_LIST_ONLY_MY_REDUCER, payload: fakePayload});
      }

    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          yield put({type: GET_DIVIDENDS_LIST_ERROR_REDUCER, response});
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}
