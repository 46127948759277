import React from "react";
import { useSelector } from 'react-redux';

import styles from "./styles.module.scss";

export function Container({ children }) {
  return <div className={styles.Container}>{children}</div>;
}

export function SideBoxYield({ children }) {
  return <div className={styles.SideBoxYield}>{children}</div>;
}

export function BoxYield({ children }) {
  const themeReducer = useSelector((state) => state.themeReducer);

  return (
    <div
      className={styles.BoxYield}
      style={{
        backgroundColor: themeReducer ? "#1D262F" : "#FFF",
        borderColor: themeReducer ? "#131A20" : "#ebebeb"
      }}
    >
        {children}
    </div>
    )
}

export function BoxYieldTitle({ children, style }) {
  // text
  return <div className={styles.BoxYieldTitle} style={style}>{children}</div>;
}

export function BoxYieldValue({ children }) {
  return <div className={styles.BoxYieldValue}>{children}</div>;
}

export function BoxYieldAreaValue({ children }) {
  // text
  return <div className={styles.BoxYieldAreaValue}>{children}</div>;
}
