import _ from "lodash";
import { PRODUCT_ID } from "./index";

export function getCurrency(idCountry) {
  let currency = "R$";

  if (idCountry === 2) {
    currency = "$";
  }

  return currency;
}

export function getCurrencyByCountryName(country) {
  let currency = "R$";

  if (country === "EUA") {
    currency = "$";
  }

  return currency;
}

export function getIndex(index) {
  const premium = isPremium();

  if (index >= 2 && !premium) {
    index = index + 1;
  }
  return index;
}

export function isPremium(purchase) {
  let premium = true;

  return premium;
}

export async function isAnnuity() {
  let gold = false;

  const isAnnuityStorage = await localStorage.getItem(PRODUCT_ID);
  const premium = await isPremium();

  if (premium === true && _.isEqual(isAnnuityStorage, '"annuity"')) {
    gold = true;
  }
  return gold;
}

export async function isGold(/*purchase*/) {
  let gold = false;

  // if (_.isEqual(purchase.productIdentifier, "gold_plan")) {
  //   gold = true;
  // }

  const isGoldStorage = await localStorage.getItem(PRODUCT_ID);
  const premium = await isPremium();
  if (premium === true && _.isEqual(isGoldStorage, '"gold_plan"')) {
    gold = true;
  }

  // console.log(gold);
  return gold;
}

export function isSilver(purchase) {
  let silver = false;

  if (_.isEqual(purchase.productIdentifier, "gold_plan")) {
    silver = false;
  } else if (_.isEqual(purchase.productIdentifier, "silver_plan")) {
    silver = true;
  }

  return silver;
}
