import styled from "styled-components";

export const Indicator = styled.div`
	/* top: 217px; */
	/* left: 1499px; */
	width: 100px;
	height: 85px;
	background: #2626267a 0% 0% no-repeat padding-box;
	border-radius: 11px;

	display: flex;
	/* justify-content: center; */
	flex-direction: column;
	color: #ffffff;

	@media only screen and (max-width: 700px) {
		width: 95px;
		height: 80px;
	}
`;

export const Text = styled.span`
	height: 45%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	font: normal normal bold 16px/19px Usual;
	letter-spacing: 0px;

	background: #5353537d;
    width: 100%;
    margin-left: 0px;
    border-top-right-radius: 11px;
    border-top-left-radius: 11px;
    color: #FFFFFF;
`;

// export const Value = styled.span`
// 	font-size: 25px;
// 	height: 61px;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	font: normal normal 800 25px/30px Usual;
// 	letter-spacing: 0px;
// 	color: #01cc7f;
// `;
