import { makeStyles } from "@material-ui/core/styles";

import { getBgColor2 } from "../../../../../../styles/themes/themeFunctions";

export const useEnhancedTableHeadStyles = makeStyles(theme => {
  const isDark = theme.palette.mode === "dark";
  const textColor = isDark ? "#eee" : "#8C8C8C";
  const bgColor = getBgColor2(isDark);
  return {
    styledTableRow: {
      border: "1px solid #DCDCDC",
      borderRight: 0,
      borderLeft: 0,
      textAlign: "center",
      backgroundColor: bgColor,
      color: textColor
    },
    curvedRightTableElement: {
      border: "1px solid #DCDCDC",
      borderBottomLeftRadius: 9,
      borderTopLeftRadius: 9,
      borderRight: 0,
      textAlign: "start",
      backgroundColor: bgColor,
      color: textColor
    },
    curvedLeftTableElement: {
      border: "1px solid #DCDCDC",
      borderBottomRightRadius: 9,
      borderTopRightRadius: 9,
      borderLeft: 0,
      textAlign: "end",
      backgroundColor: bgColor,
      color: textColor
    },
    baseSortElement: {
      flexDirection: "row-reverse",
      color: textColor,
      fontFamily: "Usual Regular"
    },
    baseSortActiveElement: {
      fontWeight: 800
    }
  };
});

export const useNoResultsStyles = makeStyles(() => ({
  content: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  text: {
    fontFamily: "Usual Regular",
    fontWeight: "normal",
    fontSize: 16,
    // color: '#535353',
    textAlign: "center"
  }
}));

export const useStyles = makeStyles(props => ({
  root: {
    height: "auto",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  table: {
    borderCollapse: "separate",
    paddingRight: 20
  },
  emptySpan: {
    width: 18,
    height: 1,
    opacity: 0,
    display: "inline-block"
  },
  positiveValueCell: {
    color: "#0ed93d"
  },
  negativeValueCell: {
    color: "#DB1356"
  }
  // neutralValueCell: {
  //   color: '#535353'
  // },
}));
