import styled from "styled-components";

export const Row = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #535353;
	color: #535353;
	padding-top: 20px;

	max-width: 943px;
	margin: 0 auto;
	/*height: 102px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #dedede;
	border-radius: 8px;
	padding: 0 23px;
	margin-bottom: 20px;

	@media only screen and (max-width: 1900px) {
		height: 80px;
	}

	@media only screen and (max-width: 700px) {
		font-size: 15px;
		height: 80px;
		padding: 0 5px;
	}*/
`;

export const CardHeader = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

export const Logo = styled.img`
	height: 95px;
	height: auto;
	width: auto;
	max-height: 95px;
	max-width: 113px;

	margin-right: 23px;
	border-radius: 7px;
`;

export const CardBody = styled.div`
	padding-bottom: 50px;
	width: 100%;
	margin-top: 22px;

	@media only screen and (max-width: 1900px) {
		padding-bottom: 30px;
	}
`;

export const Title = styled.div`
	font: normal normal bold 22px/26px Usual;
	letter-spacing: -0.22px;

	@media only screen and (max-width: 1900px) {
		font-size: 17px;
	}
`;

export const Desc = styled.div`
	font: normal normal normal 15px/25px Usual;
	letter-spacing: 0px;

	@media only screen and (max-width: 1900px) {
		font-size: 12px;
	}
`;

export const MoreButton = styled.div`
	font: normal normal bold 15px/18px Usual;
	letter-spacing: -0.07px;

	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
	cursor: pointer;
	float: right;
	transition: 0.3s;

	span {
		font-size: 15px;
		font-weight: 950;
	}

	&:hover {
		border-radius: 5px;
		border: 2px solid #01cc7f;
		padding: 0 5px;
		background: #01cc7f;
		color: #fff;
	}

	@media only screen and (max-width: 1900px) {
		margin-top: 20px;
		font-size: 11px;
	}
`;

// export const StockName = styled.span`
// 	font: normal normal 800 20px/24px Usual;
// 	color: #535353;
// 	margin-left: 16px;

// 	@media only screen and (max-width: 830px) {
// 		font-size: 15px;
// 		margin-left: 5px;
// 	}
// `;
