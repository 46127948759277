import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import _ from "lodash";

import { getAffiliationType } from "../../store/actions";

import {
	Container,
	Title2,
	Title3,
	List,
	Card,
	Top,
	Image,
	Body,
	FooterLink,
	FooterText,
	ButtonExpand,
} from "./styles";

function LearnerCenter() {
	const dispatch = useDispatch();

	// const [refreshing, setRefreshing] = useState(false);
	const [, /*discoveryReady*/ setDiscoveryReady] = useState(false);
	// const themeReducer = useSelector((state) => state.themeReducer);
	// const affiliationReducer = useSelector((state) => state.affiliationReducer);
	const [, /*affiliateId*/ setAffiliateId] = useState(null);
	const getAffiliateReducer = useSelector(
		(state) => state.affiliationTypeReducer
	);

	const [canShowMore, setCanShowMore] = useState(false);

	useEffect(() => {
		/*const affiliateIdParam = route.params.affiliateId
          ? route.params.affiliateId
          : null;*/
		const affiliateIdParam = "Book";

		if (affiliateIdParam) {
			setAffiliateId(affiliateIdParam);

			dispatch(getAffiliationType({ type: affiliateIdParam }));
		}
	}, [dispatch]);

	useEffect(() => {
		if (!_.isEmpty(getAffiliateReducer)) {
			// console.warn('getAffiliateReducer')
			// console.warn(getAffiliateReducer)
			setDiscoveryReady(true);
			// setRefreshing(false);
		}
	}, [getAffiliateReducer]);

	return (
		<Container>
			<Title2>Central do Aprendiz</Title2>
			<List>
				{getAffiliateReducer &&
					getAffiliateReducer.map(({ name, text, image, link }, index) => {
						if (index > 2 && !canShowMore) return <></>;

						return (
							<Card key={name}>
								<Top key={index}>
									<Image src={image} alt="logo do livro" />
									<Title3>{name}</Title3>
								</Top>
								<Body>{text}</Body>
								<FooterLink href={link} target='_blank' rel="noreferrer">
									<FooterText>VER MAIS</FooterText>
									<ArrowForwardIcon />
								</FooterLink>
							</Card>
						);
					})}
			</List>

			<ButtonExpand onClick={() => setCanShowMore(!canShowMore)}>
				{canShowMore ? "VER MENOS" : "VER TUDO"}
			</ButtonExpand>
		</Container>
	);
}

export default LearnerCenter;
