import React from "react";
import MoversGenericPage from "../../components/common/MoversGenericPage";

function BestDividendPayers() {
	return (
		<MoversGenericPage
            page='bestDividendPayers'
			title="Maiores Pagadores de Dividendos"
			text="Escolha ver ações ou fundos imobiliários. Sabia que você também pode pesquisar dividendos de ações americanas?"
			// tabs={[{ title: "Ações" }, { title: "Fundos Imobiliários" }]}
		/>
	);
}

export default BestDividendPayers;
