import {
  STOCK_GET_SYMBOL_SAGA,
  GET_STOCK_MARKET_MOVERS_SAGA,
  STOCK_SET_ID_REDUCER
} from './index';

export function getStockBySymbol(payload) {
  return {
    type: STOCK_GET_SYMBOL_SAGA,
    payload
  };
}

export function getMover(payload) { // getStockMarketMovers
  return {
    type: GET_STOCK_MARKET_MOVERS_SAGA,
    payload
  };
}

export function setIdStock(payload) {
  return {
    type: STOCK_SET_ID_REDUCER,
    payload,
  };
}
