import { takeLeading, call, put } from "redux-saga/effects";
import {
  GET_ADVERTISER_EXTERNAL_SAGA,
  GET_ADVERTISER_EXTERNAL_REDUCER,
  GET_ADVERTISER_EXTERNAL_DETAIL_SAGA,
  GET_ADVERTISER_EXTERNAL_DETAIL_REDUCER
} from "../actions";
import {
  getAdvertiserExternalApi,
  getAdvertiserExternalDetailApi
} from "../apis";
import { clearStorage, getToken } from "./index";

export function* watchGetAdvertiserExternalAsync() {
  yield takeLeading(GET_ADVERTISER_EXTERNAL_SAGA, getAdvertiserExternal);
}
export function* watchGetAdvertiserExternalDetailAsync() {
  yield takeLeading(
    GET_ADVERTISER_EXTERNAL_DETAIL_SAGA,
    getAdvertiserExternalDetail
  );
}

export function* getAdvertiserExternal() {
  try {
    let token = yield call(getToken);
    const response = yield call(getAdvertiserExternalApi, token);

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];

      yield put({ type: GET_ADVERTISER_EXTERNAL_REDUCER, payload });
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getAdvertiserExternalDetail(action) {
  try {
    let token = yield call(getToken);
    let id = action.payload.id;

    const response = yield call(getAdvertiserExternalDetailApi, token, id);

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];
      yield put({ type: GET_ADVERTISER_EXTERNAL_DETAIL_REDUCER, payload });
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}
