import { all } from "redux-saga/effects";

import {
	watchGetIndicatorsAsync,
	watchGetCompanyIndicatorsAsync,
	watchGetIndicatorsByTypeAsync,
} from "./indicatorsSaga";

import { watchAffiliationItensAsync } from "./affiliationSaga";

import {
	watchGetStockBySymbolAsync,
	watchGetStockMarketMoversAsync,
} from "./stockSaga";

import {
	watchGetDividendAsync,
	watchEditDividendAsync,
	watchDeleteDividendAsync,
	watchAddDividendAsync,
	watchGetDividendPeriodAsync,
	watchGetDividendYieldListAsync,
	watchGetDividendYieldDetailAsync,
	watchGetDividendRankingAsync,
	watchRestoreDividendsAsync,
	watchGetDividendListOnlyMyAsync,
	watchGetDividendsListAsync,
	watchGetDividendsListDetailAsync,
} from "./dividendSaga";

import {
watchStockSymbolCountry,
} from "./followSaga";

import {
	watchGetAdvertiserExternalAsync,
	watchGetAdvertiserExternalDetailAsync,
} from './advertiserExternalSaga';

import {
	watchGetSearch,
	watchGetFollowSearch,
	watchGetStockBySplit,
} from './searchSaga';

import {
	watchGetDividendCalendarAsync,
	watchGetDividendCalendarListAsync,
	watchGetDividendCalendarListDetailAsync,
	watchGetDividendCalendarListOnlyMyAsync,
	watchGetDividendCalendarListByTypeAsync,
  } from './dividendCalendarSaga';

import {
	watchGetRelevantFactsAsync,
	watchGetRelevantFactDetailAsync,
} from './relevantFactSaga';

// single entry point to start all Sagas at once
export default function* rootSaga() {
	yield all([
		watchGetIndicatorsAsync.call(),
		watchGetCompanyIndicatorsAsync.call(),
		watchGetIndicatorsByTypeAsync.call(),
		watchAffiliationItensAsync.call(),
		watchGetStockBySymbolAsync.call(),
		watchGetStockMarketMoversAsync.call(),

		watchGetDividendAsync.call(),
		watchEditDividendAsync.call(),
		watchDeleteDividendAsync.call(),
		watchAddDividendAsync.call(),
		watchGetDividendPeriodAsync.call(),
		watchGetDividendYieldListAsync.call(),
		watchGetDividendYieldDetailAsync.call(),
		watchGetDividendRankingAsync.call(),
		watchRestoreDividendsAsync.call(),
		watchGetDividendListOnlyMyAsync.call(),
		watchGetDividendsListAsync.call(),
		watchGetDividendsListDetailAsync.call(),

		watchStockSymbolCountry.call(),

		watchGetAdvertiserExternalAsync.call(),
		watchGetAdvertiserExternalDetailAsync.call(),

		watchGetSearch.call(),
		watchGetFollowSearch.call(),
		watchGetStockBySplit.call(),

		watchGetDividendCalendarAsync.call(),
		watchGetDividendCalendarListAsync.call(),
		watchGetDividendCalendarListDetailAsync.call(),
		watchGetDividendCalendarListOnlyMyAsync.call(),
		watchGetDividendCalendarListByTypeAsync.call(),
		watchGetRelevantFactsAsync.call(),
		watchGetRelevantFactDetailAsync.call()
	]);
}

export { clearStorage, getToken } from "./authSaga";
