import React from "react";

import { HeaderTabs, Tab, TabText, TabActive } from "./styles";

function HeaderTabsComp({
	page,
	firstTabMoreImportant = false,
	tabs,
	active,
	setActive,
}) {
	return (
		<HeaderTabs page={page}>
			{tabs &&
				tabs.map((item, index) => (
					<Tab
						key={index}
						page={page}
						active={active === index}
						onClick={() => setActive(index)}
						style={
							page === "dividendsCalendarSymbol" && index === 0
								? { width: 279 }
								: null
						}
					>
						<TabText>
							{(firstTabMoreImportant && index === 0) ? (
								<h1>{item.title}</h1>
							) : (
								<h2>{item.title}</h2>
							)}
							{active === index && <TabActive />}
						</TabText>
					</Tab>
				))}
		</HeaderTabs>
	);
}

export default HeaderTabsComp;
