import React from "react";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export const ArrowUp = () => (
	<ArrowUpwardIcon style={{ color: "#00da7f", fontSize: 11 }} />
);

export const ArrowDown = () => (
	<ArrowDownwardIcon style={{ color: "#f00", fontSize: 11 }} />
);