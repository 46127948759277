import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
  Container,
  SideBoxYield,
  BoxYield,
  BoxYieldTitle,
  BoxYieldValue,
  BoxYieldAreaValue,
} from './components';

const ScrollView = ({ children, style }) => (
    <div style={{ ...style, overflowY: 'auto' }}>{children}</div>
)

export default function YieldTab({ companyIndicators, currency }) {
  const dispatch = useDispatch();
  const themeReducer = useSelector((state) => state.themeReducer);

  useEffect(() => {
    //fadf
  }, [dispatch]);

  return (
    <React.Fragment>
      <ScrollView
        style={{
          flex: 1,
          color: themeReducer ? '#fff' : '#535353',
        }}
      >
        <Container>
          {!_.isNull(companyIndicators) ? (
            <>
              <SideBoxYield>
                <BoxYield>
                  <BoxYieldTitle style={{ color: themeReducer ? '#ccc' : '#888' }}>
                    Dividendo Yield (12 meses)
                    </BoxYieldTitle>
                  <BoxYieldAreaValue>
                    <BoxYieldValue>
                      {currency} {companyIndicators.dividend12Months}
                    </BoxYieldValue>
                    <BoxYieldValue>
                      {companyIndicators.dividend12MonthsYield}
                    </BoxYieldValue>
                  </BoxYieldAreaValue>
                </BoxYield>
              </SideBoxYield>

              <SideBoxYield>
                <BoxYield>
                  <BoxYieldTitle>Dividendo Yield (24 meses)</BoxYieldTitle>
                  <BoxYieldAreaValue>
                    <BoxYieldValue>
                      {currency} {companyIndicators.dividend24Months}
                    </BoxYieldValue>
                    <BoxYieldValue>
                      {companyIndicators.dividend24MonthsYield}
                    </BoxYieldValue>
                  </BoxYieldAreaValue>
                </BoxYield>
              </SideBoxYield>

              <SideBoxYield>
                <BoxYield>
                  <BoxYieldTitle>Dividendo Yield (36 meses)</BoxYieldTitle>
                  <BoxYieldAreaValue>
                    <BoxYieldValue>
                      {currency} {companyIndicators.dividend36Months}
                    </BoxYieldValue>
                    <BoxYieldValue>
                      {companyIndicators.dividend36MonthsYield}
                    </BoxYieldValue>
                  </BoxYieldAreaValue>
                </BoxYield>
              </SideBoxYield>
            </>
          ) : <div>Não há dados para mostrar</div>}
        </Container>
      </ScrollView>
    </React.Fragment>
  );
}