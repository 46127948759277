import { takeLeading, takeLatest, call, put } from "redux-saga/effects";
import {
  GET_FOLLOW_SEARCH_SAGA,
  GET_FOLLOW_SEARCH_REDUCER,
  GET_SEARCH_SAGA,
  GET_SEARCH_REDUCER,
  GET_SPLIT_BYSTOCK_SAGA,
  GET_SPLIT_BYSTOCK_REDUCER
} from "../actions";
import { getSearchApi, getFollowSearchApi, getSplitSearchApi } from "../apis";
import { clearStorage, getToken/*, getUserInfo*/ } from "./index";

export function* watchGetSearch() {
  yield takeLatest(GET_SEARCH_SAGA, getSearch);
}
export function* watchGetFollowSearch() {
  yield takeLeading(GET_FOLLOW_SEARCH_SAGA, getFollowSearch);
}
export function* watchGetStockBySplit() {
  yield takeLeading(GET_SPLIT_BYSTOCK_SAGA, getSplitByStock);
}

export function* getSearch(action) {
  try {
    let token = yield call(getToken);

    const response = yield call(getSearchApi, token, action.payload);

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];
      yield put({ type: GET_SEARCH_REDUCER, payload });
    } else {
      let error = { error: true };
      yield put({ type: GET_SEARCH_REDUCER, error });
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getFollowSearch(action) {
  try {
    let token = yield call(getToken);

    const response = yield call(getFollowSearchApi, token, action.payload);
    //console.log(response);
    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];
      yield put({ type: GET_FOLLOW_SEARCH_REDUCER, payload });
    } else {
      let error = { error: true };
      yield put({ type: GET_FOLLOW_SEARCH_REDUCER, error });
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getSplitByStock(action) {
  try {
    let token = yield call(getToken);

    const response = yield call(getSplitSearchApi, token, action.payload);
    //console.log(response);
    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];
      //console.log(response.data);
      yield put({ type: GET_SPLIT_BYSTOCK_REDUCER, payload });
    } else {
      // let error = { error: true };
      //yield put({type: GET_SPLIT_BYSTOCK_REDUCER, error});
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}
