import styled from "styled-components";

export const IndicatorsDiv = styled.div`
	max-width: 500px;
	margin: 30px auto;

	@media only screen and (max-width: 700px) {
		width: 100vw;
	}
`;

export const ScrollView = styled.div`
	overflow-y: auto;
	flex: 1;
`;

export const List = styled.div`
	display: flex;
	overflow-x: auto;
`;

export const Container = styled.div`
	flex: 1;
	background-color: ${(props) => (props.theme.isDark ? "#131A20" : "#fff")};
`;

export const AreaBtn = styled.button`
	border: none;
	cursor: pointer;

	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const AreaSearchBar = styled.div`
	flex: 1;
	padding: 0 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	align-self: center;
	z-index: 9999;
`;

export const AreaMenuSearch = styled.div`
	/* width: 100%; */
	background-color: ${(props) => (props.theme.isDark ? "#1D262F" : "#fff")};
	padding: 2px 5px;
	position: relative;
`;

export const BtnMenuSearch = styled.button`
	background: #fff;
	border: none;
	cursor: pointer;

	flex: 1;
	width: 110px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: ${(props) => (props.active ? 3 : 0)}px solid #01cc7f;
	margin: 1px;
	margin-bottom: 0;
`;

export const BtnMenuSearchText = styled.span`
	font-size: 15px;
	font-weight: ${(props) => (props.active ? "bold" : 500)};
	color: ${(props) => (props.theme.isDark ? "#fff" : "#535353")};
`;

export const BoxData = styled.div`
	flex: 1;
	background-color: ${(props) => (props.theme.isDark ? "#1D262F" : "#FFF")};
	border-bottom: 1px solid
		${(props) => (props.theme.isDark ? "#1D262F" : "#ccc")};
	padding: 16px 14px;
	margin-bottom: 2px;
`;

export const BoxDataHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

export const BoxDataLogo = styled.img`
	width: 28px;
	height: 28px;
`;

export const BoxDataAreaName = styled.div`
	flex: 1;
	flex-direction: row;
	align-items: center;
`;

export const BoxDataName = styled.span`
	font-size: 16px;
	font-weight: bold;
	color: ${(props) => (props.theme.isDark ? "#CCC" : "#535353")};
	margin-left: 12px;
	height: 22px;
`;

export const BoxDataBtnMenu = styled.button`
	border: none;
	cursor: pointer;

	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const BoxDataBody = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 8px;
`;

export const BoxDataBodySide = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
`;

export const BoxDataBodySideRow = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

export const BoxDataBodyTitle = styled.span`
	flex: 1;
	font-size: 12px;
	color: ${(props) => (props.theme.isDark ? "#fff" : "#535353")};
`;

export const BoxDataBodyValue = styled.span`
	flex: 1;
	font-size: 14px;
	font-weight: bold;
	color: ${(props) => (props.theme.isDark ? "#fff" : "#535353")};
	margin-top: 4px;
`;

export const AreaIndicatorValue = styled.span`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
`;

export const AreaIndicatorValueText = styled.span`
	flex: 1;
	font-size: 16px;
	font-weight: bold;
	color: ${(props) => (props.theme.isDark ? "#fff" : "#535353")};
`;

export const LoadingDiv = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 300px;
`;
