export const CLEAR_ALL_REDUCER = "CLEAR_ALL_REDUCER";
export const LOGOUT_OK = "LOGOUT_OK";

export const INDICATORS_GET_SAGA = "INDICATORS_GET_SAGA";
export const INDICATORS_GET_REDUCER = "INDICATORS_GET_REDUCER";
export const GET_COMPANY_INDICATORS_SAGA = "GET_COMPANY_INDICATORS_SAGA";
export const GET_COMPANY_INDICATORS_REDUCER = "GET_COMPANY_INDICATORS_REDUCER";
export const GET_INDICATORS_BY_TYPE_SAGA = 'GET_INDICATORS_BY_TYPE_SAGA';
export const GET_INDICATORS_BY_TYPE_REDUCER = 'GET_INDICATORS_BY_TYPE_REDUCER';
export const CLEAR_INDICATORS_BY_TYPE_REDUCER =
  'CLEAR_INDICATORS_BY_TYPE_REDUCER';

export const GET_AFFILIATION_SAGA = "GET_AFFILIATION_SAGA";
export const GET_AFFILIATION_REDUCER = "GET_AFFILIATION_REDUCER";
export const STOCK_GET_SYMBOL_SAGA = "STOCK_GET_SYMBOL_SAGA";
export const STOCK_GET_SYMBOL_REDUCER = "STOCK_GET_SYMBOL_REDUCER";
export const GET_STOCK_MARKET_MOVERS_SAGA = "GET_STOCK_MARKET_MOVERS_SAGA";
export const GET_STOCK_MARKET_MOVERS_REDUCER =
	"GET_STOCK_MARKET_MOVERS_REDUCER";
export const STOCK_SET_ID_REDUCER = "STOCK_SET_ID_REDUCER";

export const DIVIDEND_EDIT_SAGA = "DIVIDEND_EDIT_SAGA";
export const DIVIDEND_DELETE_SAGA = "DIVIDEND_DELETE_SAGA";
export const DIVIDEND_ADD_SAGA = "DIVIDEND_ADD_SAGA";
export const DIVIDEND_GET_REDUCER = "DIVIDEND_GET_REDUCER";
export const DIVIDEND_GET_SAGA = "DIVIDEND_GET_SAGA";
export const DIVIDEND_GET_PERIOD_SAGA = "DIVIDEND_GET_PERIOD_SAGA";
export const DIVIDEND_GET_PERIOD_REDUCER = "DIVIDEND_GET_PERIOD_REDUCER";

export const DIVIDEND_GET_YIELD_LIST_SAGA = "DIVIDEND_GET_YIELD_LIST_SAGA";
export const DIVIDEND_GET_YIELD_LIST_REDUCER =
	"DIVIDEND_GET_YIELD_LIST_REDUCER";
export const DIVIDEND_GET_YIELD_DETAIL_SAGA = "DIVIDEND_GET_YIELD_DETAIL_SAGA";
export const DIVIDEND_GET_YIELD_DETAIL_REDUCER =
	"DIVIDEND_GET_YIELD_DETAIL_REDUCER";
export const DIVIDEND_GET_YIELD_DETAIL_ERROR_REDUCER =
	"DIVIDEND_GET_YIELD_DETAIL_ERROR_REDUCER";
export const DIVIDEND_GET_YIELD_DETAIL_CLEAR_REDUCER =
	"DIVIDEND_GET_YIELD_DETAIL_CLEAR_REDUCER";

export const DIVIDEND_GET_LIST_ONLY_MY_SAGA = "DIVIDEND_GET_LIST_ONLY_MY_SAGA";
export const DIVIDEND_GET_LIST_ONLY_MY_REDUCER =
	"DIVIDEND_GET_LIST_ONLY_MY_REDUCER";

export const DIVIDEND_GET_LIST_SAGA = "DIVIDEND_GET_LIST_SAGA";
export const DIVIDEND_GET_LIST_REDUCER = "DIVIDEND_GET_LIST_REDUCER";

export const DIVIDEND_GET_LIST_DETAIL_SAGA = "DIVIDEND_GET_LIST_DETAIL_SAGA";
export const DIVIDEND_GET_LIST_DETAIL_REDUCER =
	"DIVIDEND_GET_LIST_DETAIL_REDUCER";

export const GET_DIVIDEND_RANKING_SAGA = "GET_DIVIDEND_RANKING_SAGA";
export const GET_DIVIDEND_RANKING_REDUCER = "GET_DIVIDEND_RANKING_REDUCER";

export const PORTFOLIO_GET_DIVIDEND_DETAILS_SAGA =
	"PORTFOLIO_GET_DIVIDEND_DETAILS_SAGA";
export const PORTFOLIO_GET_DIVIDEND_DETAILS_REDUCER =
	"PORTFOLIO_GET_DIVIDEND_DETAILS_REDUCER";
export const GET_DIVIDENDS_LIST_BY_TYPE_REDUCER = 'GET_DIVIDENDS_LIST_BY_TYPE_REDUCER';
export const GET_DIVIDENDS_LIST_BY_TYPE_SAGA = 'GET_DIVIDENDS_LIST_BY_TYPE_SAGA';
	
export const RESTORE_DIVIDENDS_SAGA = "RESTORE_DIVIDENDS_SAGA";

export const STOCK_SYMBOL_COUNTRY_SAGA = "STOCK_SYMBOL_COUNTRY_SAGA";
export const STOCK_SYMBOL_COUNTRY_REDUCER = "STOCK_SYMBOL_COUNTRY_REDUCER";

export const GET_ADVERTISER_EXTERNAL_SAGA = "GET_ADVERTISER_EXTERNAL_SAGA";
export const GET_ADVERTISER_EXTERNAL_DETAIL_SAGA =
	"GET_ADVERTISER_EXTERNAL_DETAIL_SAGA";
export const GET_ADVERTISER_EXTERNAL_REDUCER =
	"GET_ADVERTISER_EXTERNAL_REDUCER";
export const GET_ADVERTISER_EXTERNAL_DETAIL_REDUCER =
	"GET_ADVERTISER_EXTERNAL_DETAIL_REDUCER";

export const GET_SEARCH_SAGA = 'GET_SEARCH_SAGA';
export const GET_SEARCH_REDUCER = 'GET_SEARCH_REDUCER';
export const GET_FOLLOW_SEARCH_SAGA = 'GET_FOLLOW_SEARCH_SAGA';
export const GET_FOLLOW_SEARCH_REDUCER = 'GET_FOLLOW_SEARCH_REDUCER';
export const GET_SPLIT_BYSTOCK_SAGA = 'GET_SPLIT_BYSTOCK_SAGA';
export const GET_SPLIT_BYSTOCK_REDUCER = 'GET_SPLIT_BYSTOCK_REDUCER';

export const CHART_DIVIDEND_CALENDAR_YEAR_GET_REDUCER =
  "CHART_DIVIDEND_CALENDAR_YEAR_GET_REDUCER";
export const CHART_DIVIDEND_CALENDAR_YEAR_GET_SAGA =
  'CHART_DIVIDEND_CALENDAR_YEAR_GET_SAGA';
export const GET_DIVIDENDS_LIST_SAGA = 'GET_DIVIDENDS_LIST_SAGA';
export const GET_DIVIDENDS_LIST_DETAIL_SAGA = 'GET_DIVIDENDS_LIST_DETAIL_SAGA';
export const GET_DIVIDENDS_LIST_ONLY_MY_SAGA =
  'GET_DIVIDENDS_LIST_ONLY_MY_SAGA';
export const GET_DIVIDENDS_LIST_DETAIL_REDUCER =
  'GET_DIVIDENDS_LIST_DETAIL_REDUCER';
export const GET_DIVIDENDS_LIST_DETAIL_CLEAR_REDUCER =
  'GET_DIVIDENDS_LIST_DETAIL_CLEAR_REDUCER';
export const GET_DIVIDENDS_LIST_ERROR_REDUCER =
  'GET_DIVIDENDS_LIST_ERROR_REDUCER';
export const GET_DIVIDENDS_LIST_ONLY_MY_REDUCER =
  'GET_DIVIDENDS_LIST_ONLY_MY_REDUCER';
export const GET_DIVIDENDS_LIST_REDUCER = 'GET_DIVIDENDS_LIST_REDUCER';

export const DIVIDEND_CALENDAR_SYMBOL_DATA_REDUCER = 'DIVIDEND_CALENDAR_SYMBOL_DATA_REDUCER';

export const GET_RELEVANT_FACTS_DETAIL_SAGA = 'GET_RELEVANT_FACTS_DETAIL_SAGA';
export const GET_RELEVANT_FACTS_SAGA = 'GET_RELEVANT_FACTS_SAGA';
export const GET_RELEVANT_FACTS_DETAIL_REDUCER =
  'GET_RELEVANT_FACTS_DETAIL_REDUCER';
export const GET_RELEVANT_FACT_REDUCER = 'GET_RELEVANT_FACT_REDUCER';

export {
	getIndicators,
	getCompanyIndicators,
	getIndicatorsByType,
	clearIndicatorsByType,
  } from './indicatorsAction'; 

export { getAffiliationType } from "./affiliationAction";

export {
	getStockBySymbol,
	getMover, //getStockMarketMovers,
	setIdStock,
} from "./stockAction";

export {
	/*editDividend,
	deleteDividend,
	addDividend,
	getDividend,
	getDividendPeriod,
	getDividendYieldList,
	getDividendYieldDetail,
	clearDividendYieldDetail,
	getDividendRanking,
	restoreDividends,
	getDividendListOnlyMy,*/
	getDividendList,
	getDividendListDetail,
} from "./dividendAction";

export { isLoading, isDividendDetailLoading } from // isGetAffiliationLoading,
"../../utils/ducks/loading";

export {
	stockBySymbolAndCountry,
} from "./followAction";

export {
	getAdvertiserExternal,
	getAdvertiserExternalDetail,
} from "./advertiserExternalAction";

export {getSearch, getFollowSearch, getSplitByStock} from './searchAction';

export {
	getDividendCalendar,
	getDividendCalendarList,
	getDividendCalendarListDetail,
	clearDividendsListDetail,
	getDividendCalendarListOnlyMy,
	getDividendCalendarListByType,
} from './dividendCalendarAction';

export { setDivCalendarSymbolData } from './divCalendarSymbolAction';
export {getRelevantFacts, getRelevantFactDetail} from './relevantFactAction';