import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import IndicatorValueComponent from "../../common/IndicatorValueComponent/IndicatorValueComponent";
import { hightAndLowLink } from '../../../helpers/internalLinks'

import {
	Card,
	Top,
	Title,
	Desc,
	Body,
	List,
	LoadingDiv,
	ListRow,
	RowDiv1,
	RowItem,
	RowItemBtn
} from "./styles";

function Ranking({ item }) {
	const { name, desc, list } = item;
	const history = useHistory();
	const [completeRanking, setCompleteRanking] = useState(false);

	const handleCompleteRanking = () => {
		if (desc) {
			setCompleteRanking(!completeRanking);
		} else {
			history.push(hightAndLowLink);
			window.scrollTo(0, 0)
		}
	};

	return (
		<Card>
			<Top>
				{desc ? (
					<>
						<Title>{name}</Title>
						<Desc>{desc}</Desc>
					</>
				) : (
					<Title style={{ fontSize: 17 }}>{name}</Title>
				)}
			</Top>
			<Body>
				<List>
					{desc && (
						<RowItem style={{ fontSize: 12, marginLeft: "51%" }}>
							YELD 12 MESES
						</RowItem>
					)}

					{(list.length > 0) ? (
						list.map((item, index) => {
							if (!completeRanking && index > 6) return <></>;

							return (
								<ListRow key={item.stock + index}>
									<RowDiv1>
										{desc && (
											<RowItem
												style={{
													fontSize:
														index < 3 ? 25 : 17,
													width: 33,
													marginRight: 9,
												}}
											>
												#{index + 1}
											</RowItem>
										)}
										<RowItem>{item.stock}</RowItem>

										{!desc && (
											<RowItem
												style={{
													marginLeft: 15,
													fontSize: 13,
												}}
											>
												{item.stock.substr(0, 4)}
											</RowItem>
										)}
									</RowDiv1>

									<RowItem>
										<IndicatorValueComponent
											fontSize={19}
											value={item.value}
											neutral={desc ? true : false}
											showPercentage={true}
										/>
									</RowItem>
									<RowItem>+</RowItem>
								</ListRow>
							);
						})
					) : (
						<LoadingDiv>
							<CircularProgress />
						</LoadingDiv>
					)}
				</List>
				<RowItemBtn
					style={{
						fontSize: 12,
						textAlign: "center",
						cursor: "pointer",
					}}
					onClick={handleCompleteRanking}
				>
					{completeRanking
						? "VER MENOS"
						: desc
						? "VER RANKING COMPLETO"
						: "VER COMPLETO"}
				</RowItemBtn>
			</Body>
		</Card>
	);
}

export default Ranking;
