import {api} from './apiBase';

const url = 'v3/RelevantFact';

export function getRelevantFactsApi(accessToken, data) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.get(`${url}?onlyMyUser=${data.onlyMyUser}`);
}

export function getRelevantFactDetailApi(accessToken, id) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.get(`${url}/${id}/details`);
}
