import React from "react";
import { useSelector } from "react-redux";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";

import {
	Row,
	CardHeader,
	Logo,
	CardBody,
	Title,
	Desc,
	MoreButton,
} from "./styles";

const Affiliation = ({ Affiliations, type }) => {
    // console.warn('Affiliations')
    // console.log(Affiliations)

	const handleOpenBookLink = (link) => {
		window.open(link);
	};

	return (
		<>
			{Affiliations.map((affiliation) => {
                const { affiliateProductGuid, link, image, name, description } = affiliation
				return (
					<Row
						key={affiliateProductGuid}
						onClick={() => handleOpenBookLink(link)}
					>
						<CardHeader>
							<Logo
								src={image}
								alt={description}
							/>
							<Title>{name}</Title>
						</CardHeader>
						<CardBody>
							{(description !== type && description !== 'EBook') && <Desc>{description}</Desc>}

							<MoreButton>
								<span>LER MAIS</span>
								<ArrowForwardRoundedIcon
									style={{ marginLeft: 25 }}
								/>
							</MoreButton>
						</CardBody>
					</Row>
				);
			})}
		</>
	);
};

export default Affiliation;
