import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

import ListItem from "./ListItem";
import { MenuList, Navbar, ButtonIcon } from "./styles";

import {
	hightAndLowLink,
	bestDividendPayersLink,
	generalDividendsScheduleLink,
	dividendCalendarSymbolLink,
	relevantFactLink,
	learnerCenterLink,
	arrInternalURLs,
} from "../../helpers/internalLinks";

const arrMenuItems = [
	{ text: "Início", to: "/" },
	{ text: "Maiores Altas e Baixas", to: hightAndLowLink },
	{ text: "Melhores Pagadores de Dividendos", to: bestDividendPayersLink },
	{ text: "Agenda Dividendos", to: generalDividendsScheduleLink },
	{ text: "Agenda Dividendos (Ativo)", to: dividendCalendarSymbolLink },
	{ text: "Fatos Relevantes", to: relevantFactLink },
	{ text: "Central do Aprendiz", to: learnerCenterLink },
];

function Nav() {
	const location = useLocation();

	const [showList, setShowList] = useState(false);
	const [currentPath, setCurrentPath] = useState(false);

	useEffect(() => {
		const test = arrInternalURLs.find((url) => url === location.pathname);
		setCurrentPath(test);
	}, [location.pathname]);

	function showTopNav() {
		// aparecer menu (mobile) quando clica
		setShowList(!showList);
	}

	const isRelevantFactPage = () => location.pathname.split('/')[1] === relevantFactLink.split('/')[1]

	return (
		<Navbar>
			<MenuList id="topNav">
				{arrMenuItems.map((item, index) => {
					let isActive = currentPath
						? item.to === currentPath
						: (item.to === dividendCalendarSymbolLink && !isRelevantFactPage());

					if(item.to === '/fatos-relevantes' && location.pathname) {
						if(item.to.split('/')[1] === location.pathname.split('/')[1]) {
							isActive = true;
						}
					}
					// if (isActive) console.log(item.to, isActive);

					return (
						<ListItem
							key={index}
							item={item}
							showList={showList}
							onClick={showTopNav}
							isActive={isActive}
						/>
					);
				})}
			</MenuList>
			<ButtonIcon className="icon" onClick={showTopNav}>
				{showList ? <CloseIcon /> : <MenuIcon />}
			</ButtonIcon>
		</Navbar>
	);
}

export default Nav;
