import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import styles from "./styles.module.scss";

const BtnSubscribeNow = ({ url, callToActionButtonTitle }) => {
  return (
    <a
    className={styles.BtnSubscribeNow}
    href={url}
    target="_blank"
    rel="noopener noreferrer"
  >
    <span className={styles.BtnSubscribeNowText}>
      {callToActionButtonTitle}
    </span>
  </a>
  )
}
export default function AdvertiserDetail({
  open,
  onClose,
  advertiserDetailReducer,
  getAdvertiserDetail,
  advertiserId,
  hasButton
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (advertiserId) {
      // createHit('AdvertiserDetail'); or AdvertiserExternalDetail
      dispatch(getAdvertiserDetail({ id: advertiserId }));
    }
  }, [dispatch, advertiserId]);

  let contentHtml = ''
  if(advertiserDetailReducer) {
    contentHtml = advertiserDetailReducer.contentHtml;
    if(contentHtml) contentHtml.replace("<img", "<img style='width: 100%;'")
  }

  return (
    <div className={styles.DialogComponent}>
      <Dialog open={open || false} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <i
            className="fas fa-chevron-left"
            onClick={onClose}
            style={{ cursor: "pointer" }}
          ></i>
          <span
            className={styles.dialogCloseIcon}
            style={{ cursor: "pointer" }}
          >
            <CloseIcon onClick={onClose} />
          </span>
        </DialogTitle>

        <DialogContent style={{ display: "flex", flexDirection: "column" }}>
          {advertiserDetailReducer && advertiserDetailReducer.contentHtml ? (
            <>
            <div className={styles.wrap}>
              <iframe
                frameBorder="0"
                title="Advertiser Detail"
                srcDoc={contentHtml.replace("<img", "<img style='width: 350px'")}                
              />
            </div>
              {hasButton &&
              <BtnSubscribeNow
                url={advertiserDetailReducer.url}
                callToActionButtonTitle={advertiserDetailReducer.callToActionButtonTitle}
              />}
              </>
            
          ) : (
            <div
              style={{
                width: 200,
                height: 200,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <CircularProgress />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
