import {
  GET_RELEVANT_FACT_REDUCER,
  GET_RELEVANT_FACTS_DETAIL_REDUCER,
  CLEAR_ALL_REDUCER,
} from '../actions';

export const getRelevantFactReducer = (state = null, action) => {
  switch (action.type) {
    case GET_RELEVANT_FACT_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return null;
    default:
      return state;
  }
};

export const getRelevantFactDetailReducer = (state = null, action) => {
  switch (action.type) {
    case GET_RELEVANT_FACTS_DETAIL_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return null;
    default:
      return state;
  }
};
