import styled from "styled-components";

export const Container = styled.div``;

export const Card = styled.div`
	width: 25vw;
	min-width: 280px;
	/* width: 420px; */
	/* height: 450px; */

	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #dedede;
	border-radius: 11px;

	color: #262626;
	margin-bottom: 20px;
	padding-bottom: 15px;

	@media only screen and (max-width: 700px) {
		margin-bottom: 20px;
		width: 90vw;
		max-width: 380px;
	}
`;

export const Top = styled.div`
	/* width: 418px; */
	height: 67px;
	background: #f7f8fa 0% 0% no-repeat padding-box;
	border-radius: 11px 11px 0px 0px;
	opacity: 1;

	display: flex;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 700px) {
	}
`;

export const Title = styled.h2`
	/* text-align: left; */
	font: normal normal 800 34px/41px Usual;
`;

export const Desc = styled.h3`
	/* text-align: left; */
	font: normal normal normal 15px/18px Usual;

    line-height: 30px;
    border-bottom: 1px solid #DEDEDE;
    font-size: 15px;
    /* margin-top: -12px; */
    margin-left: 4px;
}
`;

export const Body = styled.div`
	padding: 11px 20px;
`;

export const List = styled.div`
	margin-bottom: 15px;
`;

export const LoadingDiv = styled.div`
	display: flex;
	height: 200px;
	justify-content: center;
	align-items: center;
`;

export const ListRow = styled.div`
	border-bottom: 1px solid #dedede;
	padding: 15px 0 11px;

	display: flex;
	justify-content: space-between;
`;

export const RowItem = styled.div`
	text-align: left;
	font: normal normal 800 17px/20px Usual;
	letter-spacing: 0px;
	color: #262626;
	opacity: 1;
`;

export const RowItemBtn = styled.div`
	text-align: left;
	font: normal normal 800 17px/20px Usual;
	letter-spacing: 0px;
	color: #262626;
	opacity: 1;
	transition: 0.5s;

	&:hover {
		background: #262626;
		color: #fff;
		border-radius: 5px;
		padding: 4px;
	}
`;

export const RowDiv1 = styled.div`
	display: flex;
    justify-content: space-between;
}
`;
// export const Title = styled.div``;
