import {
  DIVIDEND_CALENDAR_SYMBOL_DATA_REDUCER
} from './index';

export function setDivCalendarSymbolData(payload) {
  return {
    type: DIVIDEND_CALENDAR_SYMBOL_DATA_REDUCER,
    payload,
  };
}
