import {
  STOCK_GET_SYMBOL_REDUCER,
  CLEAR_ALL_REDUCER,
  GET_STOCK_MARKET_MOVERS_REDUCER,
  STOCK_SET_ID_REDUCER
} from '../actions';

export const stocksBySymbolReducer = (state = [], action) => {
  switch (action.type) {
    case STOCK_GET_SYMBOL_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return null;
    default:
      return state;
  }
};

export const getStockMarketMoversReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_STOCK_MARKET_MOVERS_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return null;
    default:
      return state;
  }
};

export const idStockReducer = (state = null, action) => {
  switch (action.type) {
    case STOCK_SET_ID_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return null;
    default:
      return state;
  }
};
