import styled from "styled-components";

export const Row = styled.div`
	gap: 10px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 15px;
	flex-wrap: wrap;

    @media only screen and (min-width: 530px) {
        min-width: 522px;
	}
`;
