import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import SearchIcon from "@material-ui/icons/Search";

import {
	getSearch,
	setDivCalendarSymbolData,
	clearDividendsListDetail,
} from "../../../store/actions";
import CustomAutocomplete from "../../../components/common/CustomAutocomplete";
// import { dividendCalendarSymbolLink } from '../../../helpers/internalLinks';
import { Container } from "./styles";

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			getSearch,
		},
		dispatch
	);
}

function mapStateToProps(state) {
	return {
		searchReducer: state.searchReducer,
	};
}

function SearchBySymbol({ searchReducer, getSearch }) {
	const dispatch = useDispatch();
	const history = useHistory();

	const [dividendName, setDividendName] = useState(null);

	const [userInput, setUserInput] = useState(null);

	useEffect(() => {
		if (userInput) {
			// const payload = { symbol: userInput, country: specificCountry };
			const payload = { name: userInput };
			getSearch(payload);
		}
	}, [getSearch, userInput]);

	const handleChangeDividendName = (event) => {
		if (event) {
			dispatch(clearDividendsListDetail());

			setDividendName(event);
			dispatch(setDivCalendarSymbolData(event));

			setTimeout(() => {
				history.push({
					pathname: `/${event.symbol}`,
					obj: event,
				});
			}, 800);
			// history.push(`${dividendCalendarSymbolLink}?symbol=${event.symbol}`)
		} else {
			setDividendName(null);
		}
	};

	return (
		<Container>
			<div style={{ width: "70%", minWidth: 170 }}>
				{/* <ThemeProvider theme={themeReducer} > */}
				<CustomAutocomplete
					optionName="label"
					showLogo
					options={
						searchReducer
							? searchReducer.map((item) => {
									const { symbol, name } = item;
									const auxName = name ? `(${name})` : "";
									return {
										label: `${symbol} ${auxName}`,
										...item,
									};
							  })
							: []
					}
					// options={searchReducer}
					label={"Nome do ativo"}
					setValue={handleChangeDividendName}
					value={dividendName}
					setInput={setUserInput}
				/>
				{/* </ThemeProvider> */}
			</div>
			<SearchIcon
				style={{
					marginLeft: -51,
					zIndex: 2,
					marginTop: 10,
					color: "#262626",
					background: "#fff",
					cursor: "pointer",
				}}
			/>
		</Container>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBySymbol);
