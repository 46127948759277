import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import IndicatorsTab from "./IndicatorsTab";
import CommodityTab from "./CommodityTab";
import ForexTab from "./ForexTab";
import CryptoTab from "./CryptoTab";

import { clearIndicatorsByType } from "../../../../store/actions";

// import {createHit} from '../../../../store/helpers';
import {
	IndicatorsDiv,
	AreaMenuSearch,
	BtnMenuSearch,
	BtnMenuSearchText,
	List,
	Container,
	// BoxData,
} from "./styles";

export default function IndicatorsTabsComp() {
	const dispatch = useDispatch();

	const menuItems = [
		{
			key: "indices",
			label: "Índices",
			component: <IndicatorsTab />,
			offset: 0,
		},
		{
			key: "commodities",
			label: "Commodities",
			component: <CommodityTab />,
			offset: 0,
		},
		{
			key: "forexTab",
			label: "Moedas",
			component: <ForexTab />,
			offset: 0,
		},
		{
			key: "cryptoTab",
			label: "Criptomoedas",
			component: <CryptoTab />,
			offset: 0,
		},
	];

	const [loading, setLoading] = useState(true);
	const [refreshing, setRefreshing] = useState(false);
	const [responseData, setResponseData] = useState(null);
	const [activeMenu, setActiveMenu] = useState("indices");

	useEffect(() => {
		// createHit("...");
	}, [dispatch]);

	useEffect(() => {
		//
	}, []);

	function handleMenuClick(item) {
		dispatch(clearIndicatorsByType());
		setActiveMenu(item);
	}

	// function handleRefreshScreen() {
	// 	setRefreshing(true);
	// }

	return (
		<IndicatorsDiv>
			{/* <Header title="Indicadores" /> */}

			<>
				<AreaMenuSearch>
					<List>
						{menuItems &&
							menuItems.map((item) => (
								<BtnMenuSearch
									key={item.key}
									active={
										activeMenu === item.key ? true : false
									}
									onClick={() => handleMenuClick(item.key)}
									// className={activeMenu === item.key && styles.btnMenuSearch}
								>
									<BtnMenuSearchText
										active={
											activeMenu === item.key
												? true
												: false
										}
										allowFontScaling={false}
									>
										{item.label}
									</BtnMenuSearchText>
								</BtnMenuSearch>
							))}
					</List>
				</AreaMenuSearch>
				<Container>
					{activeMenu === "indices" && (
						<IndicatorsTab responseData={responseData} />
					)}
					{activeMenu === "commodities" && (
						<CommodityTab responseData={responseData} />
					)}
					{activeMenu === "forexTab" && (
						<ForexTab responseData={responseData} />
					)}
					{activeMenu === "cryptoTab" && (
						<CryptoTab responseData={responseData} />
					)}
				</Container>
			</>
		</IndicatorsDiv>
	);
}
