import {GET_RELEVANT_FACTS_SAGA, GET_RELEVANT_FACTS_DETAIL_SAGA} from './index';

export function getRelevantFacts(payload) {
  return {
    type: GET_RELEVANT_FACTS_SAGA,
    payload: payload,
  };
}

export function getRelevantFactDetail(payload) {
  return {
    type: GET_RELEVANT_FACTS_DETAIL_SAGA,
    payload,
  };
}
