import { api } from "./apiBase";

const url = "/Dividend";
const urlCalendarList = "/DividendCalendar/list";
const urlCalendarListOnlyMy = "/DividendCalendar/list-only-my-stocks";
const urlCalendarListByStock = '/DividendCalendar/list-stock-type';

export function getDividendApi(accessToken) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.get(`v4${url}`);
}

export function editDividendApi(accessToken, data) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.put(`${url}/${data.idDividend}`, data);
}

export function addDividendApi(accessToken, data) {
  const idStock = data.idStock || data.guidStock
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.post(`${url}/${idStock}`, data);
}

export function deleteDividendApi(accessToken, idDividend) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.delete(`${url}/${idDividend}`);
}

export function getDividendList(accessToken, data) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.get(
    `${urlCalendarList}?year=${data.year}&mouth=${data.mouth}&countryType=${data.countryType}&dividendCalendarType=${data.dividendCalendarType}`,
  );
}

export function getDividendListOnlyMy(accessToken, data) {
  api.headers.Authorization = `Bearer ${accessToken}`;
  return api.get(
    `${urlCalendarListOnlyMy}?year=${data.year}&mouth=${data.mouth}&countryType=${data.countryType}&dividendCalendarType=${data.dividendCalendarType}`,
  );
}

export function getDividendListByType(accessToken, data) {
  api.headers.Authorization = `Bearer ${accessToken}`;
  return api.get(
    `${urlCalendarListByStock}?year=${data.year}&mouth=${data.mouth}&countryType=${data.countryType}&dividendCalendarType=${data.dividendCalendarType}&stockTypes=${data.stockTypes}`,
  );
}

export function getDividendListDetail(accessToken, data) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.get(
    `${urlCalendarList}/${data.symbol}/detail?startDate=${data.startDate}&endDate=${data.endDate}&dividendCalendarType=${data.dividendCalendarType}`,
  );
}

export function getDividendListApi(accessToken, data) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.get(
    `${urlCalendarList}?year=${data.year}&mouth=${data.mouth}&countryType=${data.countryType}&dividendCalendarType=${data.dividendCalendarType}`
  );
}

export function getDividendListDetailApi(accessToken, data) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.get(
    `${urlCalendarList}/${data.symbol}/detail?startDate=${data.startDate}&endDate=${data.endDate}&dividendCalendarType=${data.dividendCalendarType}`
  );
}

export function getDividendListOnlyMyApi(accessToken, data) {
  api.headers.Authorization = `Bearer ${accessToken}`;
  return api.get(
    `${urlCalendarListOnlyMy}?year=${data.year}&mouth=${data.mouth}&countryType=${data.countryType}&dividendCalendarType=${data.dividendCalendarType}`
  );
}

export function getDividendPeriodApi(accessToken, guidPortfolioSub, payload) {
  let portfolioSub

  if(payload && payload.guidPortfolioSub) {
    portfolioSub = payload.guidPortfolioSub
  }else {
    portfolioSub = guidPortfolioSub
  }

  api.headers.Authorization = `Bearer ${accessToken}`;

  let urlSellDetails;

  if (payload.startDate !== null && payload.endDate !== null) {
    urlSellDetails = `${url}/dividendDetails/${portfolioSub}?startDate=${payload.startDate}&endDate=${payload.endDate}`;
  } else {
    urlSellDetails = `${url}/dividendDetails/${portfolioSub}`;
  }

  return api.get(urlSellDetails);
}

export function getDividendYieldListApi(
  accessToken,
  guidPortfolioSub,
  payload,
) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  let urlYieldList;
  let startDate = payload.startDate ? `&startDate=${payload.startDate}` : '';
  let endDate = payload.endDate ? `&endDate=${payload.endDate}` : '';
  let idStock = payload.idStock ? `&idStock=${payload.idStock}` : '';
  let idStockType =
    payload.idStockType && payload.idStockType !== '0'
      ? `&idStockType=${payload.idStockType}`
      : '';

  urlYieldList = `${url}/dividendyieldlist/${guidPortfolioSub}?filter=true${
    startDate + endDate + idStock + idStockType
  }`;

  return api.get(urlYieldList);
}

export function getDividendYieldDetailApi(
  accessToken,
  guidPortfolioSub,
  payload,
) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  let urlYieldDetail;
  let year = payload.year ? `&year=${payload.year}` : '';
  let month = payload.month ? `&month=${payload.month}` : '';
  let idStock = payload.idStock ? `&idStock=${payload.idStock}` : '';
  let idStockType =
    payload.idStockType && payload.idStockType !== '0'
      ? `&idStockType=${payload.idStockType}`
      : '';

  urlYieldDetail = `${url}/dividendlist/${guidPortfolioSub}?filter=true${
    year + month + idStock + idStockType
  }`;

  //console.log(urlYieldDetail);
  return api.get(urlYieldDetail);
}

export function getDividendRankingApi(accessToken, guidPortfolioSub, payload) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  let urlRequest;
  let year = payload.year ? `&year=${payload.year}` : '';
  let month = payload.month ? `&month=${payload.month}` : '';
  let idStock = payload.idStock ? `&idStock=${payload.idStock}` : '';
  let idStockType =
    payload.idStockType && payload.idStockType !== '0'
      ? `&idStockType=${payload.idStockType}`
      : '';

  urlRequest = `${url}/rankingdividendyield/${guidPortfolioSub}?filter=true${
    year + month + idStock + idStockType
  }`;

  //console.log(urlRequest);

  return api.get(urlRequest);
}

export function restoreDividendsApi(accessToken, data) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.put(`${url}/restoredividends/${data.guidportfolio}`);
}
