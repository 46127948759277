import React, {
	useEffect,
	useState,
	useLayoutEffect,
	useCallback,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";

import {
	getDividendCalendarListByType,
	setDivCalendarSymbolData,
	clearDividendsListDetail,
} from "../../store/actions";
import { countries } from "../../helpers/countries";

// import VerticalAds from '../../components/Ads/VerticalAds';
import Table from "../../components/dividensDetails/Table/index";
import Filters from "../../components/dividensDetails/Filters/index";

import { months } from "../../data/months";
// import { getBgColor, getTextColor, getTextColor2 } from "../../styles/themes/themeFunctions";
import HeaderTabsComp from "../../components/common/MoversGenericPage/HeaderTabsComp";
import { Header, List } from "../../components/common/MoversGenericPage/styles";
import HorizontalAds from "../../components/common/Annoucements/HorizontalAds";
import { getStateByType } from "./functions";

import styles from "./styles.module.scss";
import { PageContainer } from "./styles";

function DividendCalendarByType({ hideHeader }) {
	const dispatch = useDispatch();
	const loadingReducer = useSelector((state) => state.loadingReducer);
	const getDividendsListByTypeReducer = useSelector(
		(state) => state.getDividendsListByTypeReducer
	);
	// const loadingDividendDetailReducer = useSelector((state) => state.isDividendDetailLoading);
	// const dividendListDetailReducer = useSelector((state) => state.dividendListDetailGetReducer);

	const history = useHistory();
	const location = useLocation();

	const state = getStateByType(location.pathname);

	const [active, setActive] = useState(0);

	const [dividendCalendarType, setDividendCalendarType] =
		useState("ByDataCom");
	const [country, setCountry] = useState(state.country || "Brazil");
	const [open, setOpen] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedMonth, setselectedMonth] = useState(
		new Date().getUTCMonth() + 1
	);
	const [selectedYear, setselectedYear] = useState(
		new Date().getUTCFullYear()
	);
	const [titleResult, setTitleResult] = useState("");
	// const [tableHeight, setTableHeight] = React.useState(0);
	const [width /*height*/] = useWindowSize();
	const [screenTitle, setScreenTitle] = useState("");

	// React.useEffect(() => {
	//   const pageDetailContainer = document.getElementById('page-detail-container');
	//   setTableHeight(pageDetailContainer.clientHeight);
	// }, [width])

	useEffect(() => {
		function initTitlePage() {
			setTitleResult(
				`${months[selectedMonth - 1]} ${selectedYear.toString()}`
			);
		}
		initTitlePage();

		loadData();
	}, []);

	const loadData = useCallback(async () => {
		setScreenTitle(state.screenTitle);
		// setStockType(state.stockType);

		const payload = {
			year: selectedYear,
			mouth: selectedMonth,
			countryType: country,
			dividendCalendarType: dividendCalendarType,
			stockTypes: state.stockType,
		};
		dispatch(getDividendCalendarListByType(payload));
	}, [
		location.pathname,
		dispatch,
		dividendCalendarType,
		country,
		selectedMonth,
		selectedYear,
	]);
	useEffect(() => {
		loadData();
	}, [dividendCalendarType, country, selectedMonth, selectedYear]);

	useEffect(() => {
		if (!_.isNull(getDividendsListByTypeReducer)) {
			// console.log('getDividendsListByTypeReducer')
			// console.log(getDividendsListByTypeReducer)
		}
	}, [getDividendsListByTypeReducer]);

	function useWindowSize() {
		const [size, setSize] = React.useState([0, 0]);
		useLayoutEffect(() => {
			function updateSize() {
				setSize([window.innerWidth, window.innerHeight]);
			}
			window.addEventListener("resize", updateSize);
			updateSize();
			return () => window.removeEventListener("resize", updateSize);
		}, []);
		return size;
	}

	const handleChangeDividendCalendarType = (event) => {
		setDividendCalendarType(event.target.value);
	};

	const handleCountryChange = (event) => {
		setCountry(event.target.value);
	};

	const handleDateChange = (date) => {
		const selectedMonth = date.getUTCMonth();
		const selectedYear = date.getUTCFullYear();
		setTitleResult(`${months[selectedMonth]} ${selectedYear.toString()}`);
		setselectedMonth(selectedMonth + 1);
		setselectedYear(selectedYear);
		setSelectedDate(date);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	// right filter
	const handleClickRow = (event, tableCell) => {
		dispatch(clearDividendsListDetail());

		dispatch(setDivCalendarSymbolData(tableCell));

		history.push({
			pathname: `/${tableCell.stockName}`,
			obj: { ...tableCell, symbol: tableCell.stockName },
			country
		});
		// history.push(`${dividendCalendarSymbolLink}?symbol=${tableCell.stockName}`);
	};

	const textColor = "#434343"; // getTextColor(themeReducer);
	const page = "generalDividendsSchedule";

	return (
		<div className={styles.upCommingDividendsView}>
			<div style={{ display: "flex", flexDirection: "row" }}>
				<PageContainer>
					<Grid
						container
						className={styles.gridContainer}
						style={{ width: "100vw" }}
					>
						<div className={styles.upCommingDividendsContent}>
							<HorizontalAds />

							<Header page={page}>
								<HeaderTabsComp
									page={page}
									firstTabMoreImportant
									tabs={[{ title: screenTitle }]}
									active={active}
									setActive={setActive}
								/>

								{/* List Header Filters */}
								<Filters
									countries={countries}
									country={country}
									dividendCalendarType={dividendCalendarType}
									handleChangeDividendCalendarType={
										handleChangeDividendCalendarType
									}
									handleCloseCountrySelectInput={handleClose}
									handleOpenCountrySelectInput={handleOpen}
									handleCountryChange={handleCountryChange}
									handleDateChange={handleDateChange}
									openCountrySelec={open}
									selectedDate={selectedDate}
								/>
							</Header>

							<List page={page}>
								{/* ----------------------- List ----------------------- */}
								<div className={styles.dividendsResults}>
									<h2 style={{ color: textColor }}>
										{titleResult}
									</h2>
									{!getDividendsListByTypeReducer || loadingReducer.isLoading ? (
										<CircularProgress />
									) : (
										<div
											className={
												styles.dividendsResultsContainer
											}
										>
											{getDividendsListByTypeReducer.dividendCalendarWrappers.map(
												(dividend) => {
													return (
														<div
															className={
																styles.dividendsResultsContent
															}
															key={dividend.date}
														>
															<span
																style={{
																	backgroundColor:
																		"#f7f8fa" /*getBgColor(themeReducer)*/,
																}}
															>
																<h3
																	style={{
																		color: textColor,
																	}}
																>
																	{dividendCalendarType ===
																	"ByPaymentDate"
																		? "Pagamentos"
																		: "Data Com"}{" "}
																	dia{" "}
																	{
																		dividend.date
																	}
																</h3>
															</span>
															<Table // EnhancedTable
																dateIdType={
																	dividendCalendarType
																}
																tableItems={
																	dividend.dividends
																}
																dividendCalendarType={
																	dividendCalendarType
																}
																onClickCallback={
																	handleClickRow
																}
																country={
																	country
																}
															/>
														</div>
													);
												}
											)}
										</div>
									)}
								</div>
							</List>
						</div>
					</Grid>
				</PageContainer>

				{/* {purchaseReducer && !purchaseReducer.active && (
            <div className="ads-container">
              <VerticalAds />
            </div>
          )} */}
			</div>
		</div>
	);
}

export default DividendCalendarByType;
