import styled from "styled-components";

export const Container = styled.div`
	/* display: flex; */
	margin: 0 21.56%;

	@media only screen and (max-width: 1400px) {
		margin: 0 10%;
	}

	@media only screen and (max-width: 950px) {
		margin: 10px;
	}
`;

export const PageHeader = styled.div`
	@media only screen and (max-width: 700px) {
		margin: 0 5%;
	}
`;

export const Title = styled.h1`
	font-size: 30px;
	margin-top: 49px;

	@media only screen and (max-width: 1900px) {
		font-size: 25px;
	}
`;

export const Text = styled.h2`
	text-align: left;
	font: normal normal normal 22px/26px Usual;
	letter-spacing: 0px;
	color: #262626;
	margin-bottom: 20px;

	@media only screen and (max-width: 1900px) {
		font-size: 17px;
	}

	@media only screen and (max-width: 1200px) {
		font-size: 14px;
	}
`;

export const Container2 = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 1.92%;

	@media only screen and (max-width: 700px) {
		flex-wrap: wrap;
	}
`;

export const Content = styled.div`
	/* display: flex; */
	width: 70vw;
	max-width: 1093px;

	@media only screen and (max-width: 700px) {
		width: 100%;
	}
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media only screen and (min-width: 860px) {
		margin-bottom: ${({ page }) => {
			if (page === "bestDividendPayers") {
				return "10px";
			} else if (page === "generalDividendsSchedule") {
				return "0px";
			} else {
				return "unset";
			}
		}};
	}

	@media only screen and (max-width: 860px) {
		flex-direction: column-reverse;
		gap: 15px;
	}
`;

export const Filters = styled.div`
	/* display: flex; */
	align-items: center;
`;

export const DivYield = styled.div`
	height: 53px;
	background: #f7f8fa;
	border-radius: 9px;
	margin-bottom: 19px;

	display: flex;
	justify-content: center;
	align-items: center;
`;

export const EmptyList = styled.div`
	height: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const List = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #dedede;
	padding: 3.12% 2.23%;
	/* padding: 62px 43px; */
	min-height: 40vh;

	@media only screen and (max-width: 700px) {
		width: ${(props) =>
			props.page === "generalDividendsSchedule" ? "90vw" : "unset"};
	}
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	height: 102px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #dedede;
	border-radius: 8px;
	padding: 0 23px;
	margin-bottom: 20px;

	@media only screen and (max-width: 1900px) {
		height: 80px;
	}

	@media only screen and (max-width: 700px) {
		font-size: 15px;
		height: 80px;
		padding: 0 5px;
	}
`;

export const LogoDiv = styled.div`
	display: flex;
	align-items: center;

	@media only screen and (max-width: 470px) {
		flex-direction: column;
	}
`;

export const Logo = styled.img`
	height: 46px;
	height: auto;
	width: auto;
	max-height: 46px;
	max-width: 61px;
`;

export const StockName = styled.span`
	font: normal normal 800 20px/24px Usual;
	color: #535353;
	margin-left: 16px;

	@media only screen and (max-width: 830px) {
		font-size: 15px;
		margin-left: 5px;
	}
`;

export const PercDiv = styled.div``;

export const YieldText = styled.div`
	text-align: left;
	font: normal normal 800 20px/24px Usual;
	letter-spacing: 0px;
	color: #535353;

	@media only screen and (max-width: 1900px) {
		font-size: 15px;
	}
`;
export const MarketPriceDiv = styled.div`
	display: flex;
	flex-direction: column;

	text-align: left;
	font: normal normal 800 20px/24px Usual;
	color: #535353;

	span:last-child {
		font-weight: 500;
	}

	@media only screen and (max-width: 1900px) {
		font-size: 17px;
	}

	@media only screen and (max-width: 830px) {
		font-size: 15px;
	}
`;

export const LoadingDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 42vh;
`;

export const RightDiv = styled.div`
	display: flex;
	flex-direction: column;
`;
