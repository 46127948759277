import {api} from './apiBase';

const url = '/Indicators';
const urlCompany = '/CompanyIndicators';

export function getIndicatorsApi(accessToken) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.get(`${url}`);
}

export function getCompanyIndicatorsApi(accessToken, data) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.get(`${urlCompany}/${data.guidStock}`);
}

export function getIndicatorsByTypeApi(accessToken, data) {
  api.headers.Authorization = `Bearer ${accessToken}`;

  return api.get(`${url}/${data.indicatorType}`);
}
