import React from 'react';
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ThemeProvider } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from "date-fns/locale";

import { useStyles } from './style'
// import { getBgColor } from '../../../styles/themes/themeFunctions';

const Filters = ({
  openCountrySelec,
  handleCloseCountrySelectInput,
  handleOpenCountrySelectInput,
  handleCountryChange,
  country,
  countries,
  handleDateChange,
  selectedDate,
  handleChangeDividendCalendarType,
  dividendCalendarType
}) => {
  const themeReducer = useSelector((state) => state.themeReducer);

  const classes = useStyles();

  const bgColor = "#fff" // getBgColor(themeReducer)
  
  return (
    <div className={classes.filtersContent}>
      {/* -------------- Country Filter -------------- */}
      <form autoComplete="off">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="open-select" />
          <Select
            open={openCountrySelec}
            onClose={(e) => handleCloseCountrySelectInput(e)}
            onOpen={(e) => handleOpenCountrySelectInput(e)}
            value={country}
            name="country"
            onChange={(e) => handleCountryChange(e)}
            IconComponent={KeyboardArrowDownIcon}
            inputProps={{
              id: "open-select"
            }}
            style={{ backgroundColor: bgColor, height: 40, width: 85, marginLeft: '3vw' }}
          >
            {countries.map((option, key) => (
              <MenuItem value={option.value} key={key}>
                <img src={option.src} alt={option.label} height="30px" />{" "}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </form>

      {/* -------------- Date Filter -------------- */}
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <ThemeProvider theme={themeReducer}>
          <DatePicker
            className={classes.datePicker}
            openTo="month"
            inputVariant="outlined"
            views={["year", "month"]}
            variant="inline"
            value={selectedDate}
            onChange={(e) => handleDateChange(e)}
            style={{ width: 170, background: bgColor }}
            
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>

      {/* -------------- Select (ByDataCom or ByPaymentDate) -------------- */}
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={dividendCalendarType}
          onChange={(e) => handleChangeDividendCalendarType(e)}
          IconComponent={KeyboardArrowDownIcon}
          style={{ backgroundColor: bgColor, height: 40, minWidth: 132 }}
        >
          <MenuItem value={'ByDataCom'}>Data-Com</MenuItem>
          <MenuItem value={'ByPaymentDate'}>Pagamento</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}

export default Filters;
