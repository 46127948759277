import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { getIndicatorsByType } from "../../../../store/actions";

// import {createHit} from '../../../../store/helpers';
import {
	ScrollView,
	BoxData,
	BoxDataHeader,
	BoxDataAreaName,
	BoxDataName,
	// BoxDataBtnMenu,
	AreaIndicatorValue,
	AreaIndicatorValueText,
	LoadingDiv,
} from "./styles";
import { ArrowDown, ArrowUp } from "./icons/Arrow";
import { CircularProgress } from "@material-ui/core";

export default function ForexTab({ responseData }) {
	const dispatch = useDispatch();

	const themeReducer = useSelector((state) => state.themeReducer);
	const indicatorsByTypeReducer = useSelector(
		(state) => state.indicatorsByTypeReducer
	);

	useEffect(() => {
		dispatch(getIndicatorsByType({ indicatorType: "Forex" }));
	}, [dispatch]);

	useEffect(() => {
		if (!_.isNull(indicatorsByTypeReducer)) {
			//console.log(indicatorsByTypeReducer);
		}
	}, [indicatorsByTypeReducer]);

	return (
		<React.Fragment>
			<ScrollView
				style={{ flex: 1 }}
				showsVerticalScrollIndicator={false}
			>
				{indicatorsByTypeReducer ? (
					_.isArray(indicatorsByTypeReducer) &&
					indicatorsByTypeReducer.map((indicator, index) => (
						<BoxData key={index}>
							<BoxDataHeader>
								<BoxDataAreaName>
									<BoxDataName allowFontScaling={false}>
										{indicator.name}
									</BoxDataName>
								</BoxDataAreaName>
								<AreaIndicatorValue>
									{indicator.value.split("-").length > 1 ? (
										<ArrowDown />
									) : (
										indicator.value.split("+").length >
											1 && <ArrowUp />
									)}
									<AreaIndicatorValueText
										allowFontScaling={false}
										style={{
											color:
												indicator.value.split("-")
													.length > 1
													? "#f00"
													: indicator.value.split("+")
															.length > 1
													? "#00da7f"
													: themeReducer
													? "#ccc"
													: "#535353",
										}}
									>
										{indicator.value}
									</AreaIndicatorValueText>
								</AreaIndicatorValue>
							</BoxDataHeader>
						</BoxData>
					))
				) : (
					<LoadingDiv>
						<CircularProgress />
					</LoadingDiv>
				)}
			</ScrollView>
		</React.Fragment>
	);
}
