import "firebase/analytics";
import * as firebase from "firebase/app";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { store } from "./store";
// import './index.css';
import App from "./App";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
firebase.initializeApp({
	apiKey: "AIzaSyD-5CL0iSqp90axPPlC_n-rU-3z2tmRSKw",
	authDomain: "dividendos-7350e.firebaseapp.com",
	databaseURL: "https://dividendos-7350e.firebaseio.com",
	projectId: "dividendos-7350e",
	storageBucket: "dividendos-7350e.appspot.com",
	messagingSenderId: "536925717607",
	appId: "1:536925717607:web:17fd0b33b8d9e33979c0c2",
	measurementId: "G-TJQFJWTZDR",
});

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

firebase.analytics();