import styled from "styled-components";

export const Main = styled.div`
	width: 100%;
	object-fit: cover;
	height: 70vh;

	/* background: transparent url("images/home_bg.png") 0% 0% no-repeat
		padding-box; */
	opacity: 1;

	height: 520px;

	@media only screen and (max-width: 1455px) {
		height: 680px;
	}

	@media only screen and (min-width: 1258px) {
		height: auto;
	}
`;

export const Sections = styled.div`
`;

export const BgImage = styled.img`
	float: left;
	width: 100%;
	height: 100%;
	object-fit: cover;

	/* margin-top: -4%; */
	/* @media only screen and (max-width: 1200px) {
        height: 100%;

	} */
`;

export const LogoDiv = styled.div`
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	/* height: calc(40vh); */
	flex-direction: column;
	top: 290px;

	@media only screen and (max-width: 1375px) {
		/* width: 58%; */
	}

	@media only screen and (max-width: 700px) {
		top: 470px;
		width: 100vw;
		height: 31vh;
	}
`;

export const Logo = styled.img`
	width: 340px;

	@media only screen and (max-width: 1375px) {
		width: 30vw;
	}

	@media only screen and (max-width: 700px) {
		width: 250px;
	}
`;

export const LogoTitle = styled.h2`
	color: #ffffff;
	font-size: 20px;
	font-weight: 500;
`;

export const AreaAds = styled.div`
	width: 94%;
	height: 100px;
	margin-top: 20px;
`;