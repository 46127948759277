import React from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { countries } from '../../../../../helpers/countries';
import { getMoverTypes } from "./data";
import { useStyles } from "./style";
// import { getBgColor } from '../../../styles/themes/themeFunctions';

const Filters = ({
	page,
	country,
	// countries,
	// openCountrySelec,
	// handleCloseCountrySelectInput,
	// handleOpenCountrySelectInput,
	handleCountryChange,
  paidOrYield,
  handleChangePaidOrYield,
	moverType,
	handleChangeMoverType,
	// moverType
}) => {
	// const themeReducer = useSelector((state) => state.themeReducer);
	const classes = useStyles();

	// const [open, setOpen] = useState(false);

	const bgColor = "#fff"; //getBgColor(themeReducer)

	return (
		<div className={classes.filtersContent}>
			{/* -------------- Country Filter -------------- */}
			<form autoComplete="off">
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel htmlFor="open-select" />
					<Select
						// open={openCountrySelec}
						// onClose={(e) => handleCloseCountrySelectInput(e)}
						// onOpen={(e) => handleOpenCountrySelectInput(e)}
						value={country}
						name="country"
						onChange={(e) => handleCountryChange(e)}
						IconComponent={KeyboardArrowDownIcon}
						inputProps={{
							id: "open-select",
						}}
						style={{
							backgroundColor: bgColor,
							height: 40,
							marginRight: "1vw",
						}}
					>
						{countries.map((option, key) => (
							<MenuItem value={option.value} key={key}>
								<img
									src={option.src}
									alt={option.label}
									height="30px"
								/>{" "}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</form>

			{/* -------------- Select (paid or yield) -------------- */}
			{page === "bestDividendPayers" && (
				<FormControl variant="outlined" className={classes.formControl}>
					<Select
						labelId="paid_yield_label"
						id="paid_yield"
						value={paidOrYield}
						onChange={(e) => handleChangePaidOrYield(e)}
						IconComponent={KeyboardArrowDownIcon}
						style={{ backgroundColor: bgColor, height: 40, width: 110, marginRight: "1vw" }}
					>
						<MenuItem value="Paid" key={"0"}>
							Pagos
						</MenuItem>
						<MenuItem value="Yield" key={"1"}>
							Yield
						</MenuItem>
					</Select>
				</FormControl>
			)}

			{/* -------------- Select (tipo de mover) -------------- */}
			<FormControl variant="outlined" className={classes.formControl}>
				<Select
					labelId="demo-simple-select-outlined-label"
					id="demo-simple-select-outlined"
					value={moverType}
					onChange={(e) => handleChangeMoverType(e)}
					IconComponent={KeyboardArrowDownIcon}
					style={{ backgroundColor: bgColor, height: 40, minWidth: 171 }}
				>
					{getMoverTypes(country, page).map((item, index) => (
						<MenuItem value={item.value} key={index}>
							{item.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default Filters;
