import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

export const Container = styled.div`
	flex: 1;
	/* background-color: ${(props) =>
		props.theme.isDark ? "#131A20" : "#f7f8fa"}; */
	padding: 0 18px;
	overflow-y: auto;
	border-radius: 10px;
	min-height: 67vh;

	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #dedede;
	padding: 3.12% 2.23%;
	max-width: 1500px;
	margin: 0 auto;
    margin-top: 40px;
	padding-top: 10px;
`;

export const Title = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	color: ${(props) => (props.theme.isDark ? "#ebebeb" : "#535353")};
	margin-bottom: 2px;
	font-weight: bold;
	margin-top: 20px;
`;

export const DividerTitle = styled.div`
	width: 100%;
	height: 2px;
	background-color: #01cc7f;
	margin-top: 4px;
`;

export const BtnRelevantFact = styled.div`
	width: 90px;
	margin-right: -14px;
	display: flex;
	align-items: center;
	justify-content: center;

	a {
		text-decoration: none;
		cursor: pointer;
		color: #01cc7f;
		font-size: 13px;
		font-weight: bold;
		transition: 0.5s;

		&:hover {
			color: #fff;
			font-size: 15px;
			background: #01cc7f;
			padding: 4px 21px;
			border-radius: 5px;
		}
	}
`;

export const AreaFilter = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	margin: 0 auto;
	margin-top: 15px;
	margin-bottom: 20px;
	max-width: 502px;
`;

export const CustomTextField = styled(TextField)`
	@media (min-width: 1000px) {
		margin-left: 120px;
	}
`;

export const CheckboxContainer = styled.div`
	padding: 1px;
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-width: 1px;
	border-color: #ccc;
`;

export const NoDataText = styled.span`
	font-size: 15px;
	font-weight: bold;
	color: #535353;
	display: flex;
	flex: 1;
	justify-content: center;
	text-align: center;
	margin-top: 20px;
`;

export const DataTableStyled = styled.div`
	padding: 2px;
	margin-bottom: 30px;
	background-color: #fff;

	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	max-width: 502px;
	margin: 0 auto;


	@media (min-width: 800px) {
		min-width: 500px;
	}

	@media (min-width: 1700px) {
		width: 615px;
	}
`;

export const TableRow = styled.div`
	display: flex;
	border-bottom: 1px solid #ccc9;
	justify-content: space-between;

	width: 100%;
`;

export const TableCell = styled.div`
	display: flex;
	align-items: center;
`;
