import React, { useState } from "react";

import IndicatorValueComponent from "../../common/IndicatorValueComponent/IndicatorValueComponent";
import { Indicator, Text } from "./styles";

function IndicatorItem({ indicator }) {
	const { name, value } = indicator;
	const [neutral /*setNeutral*/] = useState(value.indexOf("R$") > -1);

	return (
		<Indicator>
			<Text>{name}</Text>
			<IndicatorValueComponent
				value={value}
				neutral={neutral}
				showPercentage={!neutral}
				fontSize={17}
			/>
		</Indicator>
	);
}

export default IndicatorItem;
