import styled from "styled-components";

export const PageContainer = styled.div`
	height: auto;
	width: 100%;
	max-width: 900px;
`;

/* --------------- Area Menu --------------- */
export const AreaMenu = styled.div`
	width: 100%;
	padding: 2px 0px;
	margin-top: 20px;
	position: relative;
	display: flex;
	justify-content: start;
	overflow-x: auto;

	max-width: 80vw;
	margin: 0 auto;
	margin-bottom: 15px;
	max-width: 100vw;

	@media only screen and (max-width: 600px) {
		max-width: 100vw;
    background: #cccccc61;
    padding-bottom: 10px;
	}
`;

export const BtnMenu = styled.button`
	cursor: pointer;
	flex: 1;
	width: 120px;
	max-width: 135px;
	height: 90px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	background-color: ${(props) => (props.theme.isDark ? "#1D262F" : "#fff")};
	border-width: 1px;
	border-color: ${(props) => (props.theme.isDark ? "#222" : "#ccc")};
	margin: 1px;
	margin-bottom: 0;
	margin-right: 10px;
	/* elevation: 3; */
	box-shadow: 0px 0px 2px ${(props) => (props.theme.isDark ? "#444" : "#ccc")};
	transition: 0.5s;

	&:hover {
		background-color: ${(props) =>
			props.theme.isDark ? "#334251" : "#ddd"};
	}
`;

export const BtnMenuIcon = styled.img`
	width: 28px;
	height: 28px;
`;

export const BtnMenuText = styled.span`
	font-size: 15px;
	font-weight: bold;
	color: ${(props) => (props.theme.isDark ? "#fff" : "#444")};
	text-align: center;
	margin-top: 4px;
`;
