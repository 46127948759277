import {takeLeading, call, put} from 'redux-saga/effects';
import {
  INDICATORS_GET_SAGA,
  INDICATORS_GET_REDUCER,
  GET_COMPANY_INDICATORS_SAGA,
  GET_COMPANY_INDICATORS_REDUCER,
  GET_INDICATORS_BY_TYPE_SAGA,
  GET_INDICATORS_BY_TYPE_REDUCER,
} from '../actions';
import {
  getIndicatorsApi,
  getCompanyIndicatorsApi,
  getIndicatorsByTypeApi,
} from '../apis';
import {clearStorage, getToken} from './index';

export function* watchGetIndicatorsAsync() {
  yield takeLeading(INDICATORS_GET_SAGA, getIndicators);
}

export function* watchGetCompanyIndicatorsAsync() {
  yield takeLeading(GET_COMPANY_INDICATORS_SAGA, getCompanyIndicators);
}

export function* watchGetIndicatorsByTypeAsync() {
  yield takeLeading(GET_INDICATORS_BY_TYPE_SAGA, getIndicatorsByType);
}

export function* getIndicators() {
  try {
    let token = yield call(getToken);
    const response = yield call(getIndicatorsApi, token);

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];

      yield put({type: INDICATORS_GET_REDUCER, payload});
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getCompanyIndicators(action) {
  try {
    let token = yield call(getToken);
    const response = yield call(getCompanyIndicatorsApi, token, action.payload);

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];

      yield put({type: GET_COMPANY_INDICATORS_REDUCER, payload});
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getIndicatorsByType(action) {
  try {
    let token = yield call(getToken);
    const response = yield call(getIndicatorsByTypeApi, token, action.payload);

    if (response.ok) {
      const payload =
        response.data && response.data.value ? response.data.value : [];

      yield put({type: GET_INDICATORS_BY_TYPE_REDUCER, payload});
    } else {
      switch (response.status) {
        case 401:
          yield call(clearStorage);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}
