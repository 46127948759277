import React from "react";
import styles from "./styles.module.scss";

export function ScrollView({ children }) {
  return <div className={styles.ScrollView}>{children}</div>;
}

export function BoxData({ children, style }) {
  return <div className={styles.BoxData} style={style}>{children}</div>;
}

export function BoxDataHeader({ children }) {
  return <div className={styles.BoxDataHeader}>{children}</div>;
}

export function BoxDataLogo({ source }, ...rest) {
  // image
  return (
    <img
      className={styles.BoxDataLogo}
      src={source}
      alt="ativo" // box data logo
      {...rest}
    />
  );
}

export function BoxDataAreaName({ children }) {
  return <div className={styles.BoxDataAreaName}>{children}</div>;
}

export function BoxDataName({ children }) {
  return <div className={styles.BoxDataName}>{children}</div>;
}

export function BoxDataBody({ children }) {
  return <div className={styles.BoxDataBody}>{children}</div>;
}

export function BoxDataBodySide({ children }) {
  return <div className={styles.BoxDataBodySide}>{children}</div>;
}

export function BoxDataBodySideRow({ children }) {
  return <div className={styles.BoxDataBodySideRow}>{children}</div>;
}

export function BoxDataBodyTitle({ children }) {
  // text
  return <span className={styles.BoxDataBodyTitle}>{children}</span>;
}

export function BoxDataBodyValue({ children }) {
  // text
  return <span className={styles.BoxDataBodyValue}>{children}</span>;
}
