import {
  INDICATORS_GET_SAGA,
  GET_COMPANY_INDICATORS_SAGA,
  GET_INDICATORS_BY_TYPE_SAGA,
  CLEAR_INDICATORS_BY_TYPE_REDUCER,
} from './index';

export function getIndicators() {
  return {
    type: INDICATORS_GET_SAGA,
  };
}

export function getCompanyIndicators(payload) {
  return {
    type: GET_COMPANY_INDICATORS_SAGA,
    payload: payload,
  };
}

export function getIndicatorsByType(payload) {
  return {
    type: GET_INDICATORS_BY_TYPE_SAGA,
    payload: payload,
  };
}

export function clearIndicatorsByType(payload) {
  return {
    type: CLEAR_INDICATORS_BY_TYPE_REDUCER,
    payload: payload,
  };
}
