import styled from "styled-components";

export const Navbar = styled.nav`
	top: 0px;
	left: 0px;
	width: 100vw;
	/* height: 74px; */
	background: #262626 0% 0% no-repeat padding-box;
	opacity: 1;
	z-index: 3;
	position: fixed;
	.icon {
		display: none;
	}
}

	@media only screen and (max-width: 650px) {
		/* height: 440px; */
		background-color: transparent;
		
		/* position: relative; */
		/* margin-bottom: -179px; */
		.icon {
			display: block;
			float: right;

			//responsive
			position: absolute;
			right: 10px;
			top: 10px;
		}
	}
`;

export const ButtonIcon = styled.button`
	border: none;
`;

export const MenuList = styled.ul`
	box-shadow: 0px 5px 12px #00000029;

	background-color: #262626;

	margin: 0;
	display: flex;
	justify-content: space-evenly;
	overflow: hidden;

	@media only screen and (max-width: 650px) {
		flex-direction: column;
		/* padding-bottom: 20px; */
	}
`;

export const MenuItem = styled.li`
	display: flex;
	height: 74px;
	margin: 0 14px;
	transition: 0.5s;

	&:hover {
		background: #000;
    	padding: 0 9px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	@media only screen and (max-width: 650px) {
		height: 45px;
		display: ${(props) => (props.showList ? "flex" : "none")};
	}
`;

export const ItemText = styled.span`
	/* text */
	text-align: left;
	/* font: normal normal 800 15px/18px Usual; */
	font: normal normal ${(props) => (props.active ? 800 : "normal")} 15px/18px
		Usual;
	letter-spacing: 0px;
	color: #f7f8fa;
	opacity: 1;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 66px;

	@media only screen and (max-width: 650px) {
		height: 25px;
	}
`;

export const ActiveBottom = styled.div`
	transition: 0.2s;
	height: ${(props) => (props.active ? 8 : 0)}px;
	background: #01cc7f 0% 0% no-repeat padding-box;
	border-radius: 3px 3px 0px 0px;
	opacity: 1;

	@media only screen and (min-width: 1200px) {
		min-width: 62px;
	}
`;
