import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  filtersContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '10px 0',
    // paddingBottom: 20,
    // borderBottom: '1px solid #cfcfcf'
  },
  formControl: {
    // minWidth: 100,
    backgroundColor: "transparent"
  },
  select: {
    textAlign: "center",
    textDecoration: "none",
  },
  datePicker: {
    marginLeft: 20,
    marginRight: 20
  }
}));