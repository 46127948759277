import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { CircularProgress } from "@material-ui/core";
// import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import { getMover } from "../../../store/actions";
import IndicatorValueComponent from "../IndicatorValueComponent/IndicatorValueComponent";
import Filters from "./components/Filters";

import {
	Container,
	PageHeader,
	Title,
	Text,
	Container2,
	Content,
	Header,

	// Filters,
	// DivYield,
	List,
	Row,
	LogoDiv,
	Logo,
	StockName,
	PercDiv,
	YieldText,
	MarketPriceDiv,
	LoadingDiv,
	RightDiv,
	Announcement,
} from "./styles";
// import Indicators from "./components/Indicators";
import HeaderTabsComp from "./HeaderTabsComp";
import VerticalAds from "../Annoucements/VerticalAds";

export default function MoversGenericPage({ page, title, text, tabs }) {
	const dispatch = useDispatch();
	const marketMoversReducer = useSelector(
		(state) => state.getStockMarketMoversReducer
	);

	const [active, setActive] = useState(0);
	const [data, setData] = useState([]);
	//Form
	const [moverType, setMoverType] = useState("all");
	const [paidOrYield, setPaidOrYield] = useState("Paid");
	const [country, setCountry] = useState("Brazil");

	useEffect(() => {
		if (!_.isEmpty(marketMoversReducer)) {
			// console.warn("marketMoversReducer");
			// console.log(marketMoversReducer);

			if (page === "highAndLow") {
				if (active === 0) {
					setData(marketMoversReducer.highs);
				} else {
					setData(marketMoversReducer.falls);
				}
			} else if (page === "bestDividendPayers") {
				setData(marketMoversReducer);
			}
		}
	}, [active, marketMoversReducer, page]);

	useEffect(() => {
		dispatch(getMover({ page }));
	}, [dispatch, page]);

	const handleCountryChange = (event) => {
		const { value } = event.target;

		setCountry(value);
		const payload = { page, value: moverType, country: value };

		if (page === "bestDividendPayers") {
			setMoverType("all");
			payload.value = "all";
			payload.paidOrYield = paidOrYield;
		}
		dispatch(getMover(payload));
		setData(false);
	};

	const handleChangePaidOrYield = (e) => {
		const { value } = e.target;
		setPaidOrYield(value);
		const payload = { page, country, value: moverType };

		if (page === "bestDividendPayers") {
			payload.paidOrYield = value;
		}
		dispatch(getMover(payload));
		setData(false);
	};

	const handleChangeMoverType = (e) => {
		const { value } = e.target;
		setMoverType(value);
		const payload = { page, value, country };

		if (page === "bestDividendPayers") {
			payload.paidOrYield = paidOrYield;
		}

		dispatch(getMover(payload));
		setData(false);
	};

	const getCurrency = () => country === "Brazil" ? "R$" : "$"

	return (
		<Container>
			<PageHeader>
				<Title>{title}</Title>
				<Text>{text}</Text>
			</PageHeader>

			<Container2>
				<Content>
					<Header page={page}>
						<HeaderTabsComp
							tabs={tabs}
							active={active}
							setActive={setActive}
						/>
						<Filters
							page={page}
							country={country}
							// handleCloseCountrySelectInput={handleClose}
							// handleOpenCountrySelectInput={handleOpen}
							handleCountryChange={handleCountryChange}
							// openCountrySelec={open}
							paidOrYield={paidOrYield}
							handleChangePaidOrYield={handleChangePaidOrYield}
							handleChangeMoverType={handleChangeMoverType}
							moverType={moverType}
						/>
					</Header>
					<List>
						{/* {page === "bestDividendPayers" && (
							<DivYield>
								<ArrowDownwardIcon
									style={{ marginRight: 10 }}
								/>{" "}
								DIV. YELD %
							</DivYield>
						)} */}
						{data && data.map ? (
							data.map((item, index) => {
								return (
									<Row key={index}>
										<LogoDiv>
											<Logo src={item.logo} />
											<StockName>{item.stock}</StockName>
										</LogoDiv>

										<PercDiv>
											{page === "bestDividendPayers" && (
												<YieldText>
													Dividendo Yield 12 meses
												</YieldText>
											)}
											<IndicatorValueComponent
												// fontSize={30}
												value={(page === 'bestDividendPayers' && paidOrYield === "Paid" )? `${getCurrency()} ${item.value}` : item.value}
												neutral={
													page === "highAndLow"
														? false
														: true
												}
												showPercentage={page !== 'bestDividendPayers' || paidOrYield === "Yield"}
												type={1}
											/>
										</PercDiv>
										<MarketPriceDiv>
											<span>Cot. Atual</span>
											<span>
												{getCurrency()}{" "}
												{item.marketPrice}
											</span>
										</MarketPriceDiv>
									</Row>
								);
							})
						) : (
							<LoadingDiv>
								<CircularProgress />
							</LoadingDiv>
						)}
					</List>
				</Content>

				<RightDiv>
					{/* <Indicators /> */}
					<VerticalAds />
				</RightDiv>
			</Container2>
		</Container>
	);
}
