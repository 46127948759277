import {
  GET_ADVERTISER_EXTERNAL_SAGA,
  GET_ADVERTISER_EXTERNAL_DETAIL_SAGA
} from "./index";

export function getAdvertiserExternal() {
  return {
    type: GET_ADVERTISER_EXTERNAL_SAGA
  };
}

export function getAdvertiserExternalDetail(payload) {
  return {
    type: GET_ADVERTISER_EXTERNAL_DETAIL_SAGA,
    payload
  };
}
