import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { getRelevantFacts } from "../../store/actions";
import {
	Container,
	BtnRelevantFact,
	NoDataText,
	AreaFilter,
	CustomTextField,
	DataTableStyled,
	TableRow,
	TableCell,
} from "./styles";

export default function RelevantFact() {
	const dispatch = useDispatch();
	const location = useLocation();

	const themeReducer = useSelector((state) => state.themeReducer);
	const getRelevantFactReducer = useSelector(
		(state) => state.getRelevantFactReducer
	);

	const [relevantFacts, setRelevantFacts] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [checkedOnlyMyUser, setCheckedOnlyMyUser] = useState(false);
	const [locationReady, setLocationReady] = useState(false);


	useEffect(() => {
		if(relevantFacts && relevantFacts.length > 0 && !locationReady) {
			const name = location.pathname.split('/')[2]
			// console.log('name:', name)
			if(name) setSearchQuery(name)
			setLocationReady(true)
		}

	}, [location, location.pathname, locationReady, relevantFacts])

	useEffect(() => {
		// console.log('checkedOnlyMyUser: ', checkedOnlyMyUser)
		dispatch(getRelevantFacts({ onlyMyUser: checkedOnlyMyUser }));
	}, [dispatch, checkedOnlyMyUser]);

	const handleSearch = useCallback(
		(query) => {
			if (query && query !== "") {
				const filteredData = getRelevantFactReducer.filter((res) => {
					return res.companyName
						.toLowerCase()
						.includes(query.toLowerCase());
				});
				setRelevantFacts(filteredData);
				//console.log(filteredData);
			} else {
				setRelevantFacts(getRelevantFactReducer);
			}
		},
		[getRelevantFactReducer]
	);

	useEffect(() => {
		handleSearch(searchQuery);
	}, [handleSearch, getRelevantFactReducer, searchQuery]);

	return (
		<React.Fragment>
			<Container>
				<h1 style={{ display: "none" }}>Pesquisar Fatos Relevantes</h1>{" "}
				{/* improve SEO */}
				<AreaFilter>
					<CustomTextField
						label={"Pesquisar"}
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						variant="outlined"
					/>
				</AreaFilter>
				{getRelevantFactReducer &&
				_.isArray(getRelevantFactReducer) &&
				!_.isEmpty(getRelevantFactReducer) ? (
					<>
						<DataTableStyled>
							{_.isArray(relevantFacts) &&
								relevantFacts.map((item, ind) => (
									<TableRow
										key={"stocks-" + ind}
										// borderless={true}
										style={{ minHeight: 30 }}
									>
										<TableCell
											style={{
												marginLeft: 10,
												marginRight: 30,
												width: "50%",
											}}
										>
											<span
												style={{
													color: themeReducer
														? "#CCC"
														: "#535353",
													fontSize: 13,
												}}
											>
												{item.companyName}
											</span>
										</TableCell>
										<TableCell>
											<span
												style={{
													color: themeReducer
														? "#CCC"
														: "#535353",
													fontSize: 13,
												}}
											>
												{item.referenceDate}
											</span>
										</TableCell>
										<BtnRelevantFact>
											<a
												href={item.url}
												target="_blank"
												without
												rel="noopener noreferrer"
											>
												Ler
											</a>
										</BtnRelevantFact>
									</TableRow>
								))}
						</DataTableStyled>
					</>
				) : (
					<DataTableStyled>
						<NoDataText>
							Nenhum fato relevante no momento!
						</NoDataText>
					</DataTableStyled>
				)}
			</Container>
		</React.Fragment>
	);
}
