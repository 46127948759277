import styled from "styled-components";

export const IndicatorDiv = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	margin-top: 9px;
	// height: 61px;
	font: normal normal 800 17px/20px Usual;
    font-size: ${({fontSize}) => fontSize ? fontSize : 17}px;

	@media only screen and (max-width: 1900px) {
		font-size: 18px;
	}

	@media only screen and (max-width: 700px) {
		font-size: 17px;
	}

	// .positive {
	//     color: #01CC7F;
	// }

	// .negative {
	//     color: #BB0303;
	// }

	img {
		margin-right: 5px;
	}

	span {
		font-family: "Usual Bold";
		text-align: center;
	}
`;

export const Value = styled.span`
`;
