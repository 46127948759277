import BrazilFlagPNG from "../assets/images/BRASIL.png";
import EUAFlagPNG from "../assets/images/EUA.png";

export const countries = [
	{
		label: "Brasil",
		src: BrazilFlagPNG,
		link: " ",
		value: "Brazil",
	},
	{
		label: "EUA",
		src: EUAFlagPNG,
		link: " ",
		value: "EUA",
	},
];