import {
  GET_SEARCH_REDUCER,
  GET_FOLLOW_SEARCH_REDUCER,
  GET_SPLIT_BYSTOCK_REDUCER,
  CLEAR_ALL_REDUCER
} from "../actions";

export const searchReducer = (state = null, action) => {
  switch (action.type) {
    case GET_SEARCH_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return null;
    default:
      return state;
  }
};

export const followSearchReducer = (state = null, action) => {
  switch (action.type) {
    case GET_FOLLOW_SEARCH_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return null;
    default:
      return state;
  }
};

export const splitByStockReducer = (state = null, action) => {
  switch (action.type) {
    case GET_SPLIT_BYSTOCK_REDUCER:
      return action.payload;
    case CLEAR_ALL_REDUCER:
      return null;
    default:
      return state;
  }
};
