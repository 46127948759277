import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import _ from "lodash";

import {getAdvertiserExternal} from '../../store/actions';

import Indicators from "../../components/Indicators";
import LearnerCenter from "../../components/LearnerCenter";
import Rankings from "../../components/Rankings";
import SearchBySymbol from "./components/SearchBySymbol";
import ContainerPartnerComp from "./components/ContainerPartnerComp";

import {
	// Container,
	Main,
	Sections,
	BgImage,
	LogoDiv,
	Logo,
	LogoTitle,
	AreaAds,
} from "./styles";
import HorizontalAds from "../../components/common/Annoucements/HorizontalAds";
import IndicatorsComp from "./components/IndicatorsComp";

export default function Home() {	
	const dispatch = useDispatch();

	const advertiserExternalReducer = useSelector((state) => state.advertiserExternalReducer);

	const [advertisesExternal, setAdvertisesExternal] = useState([]);

	useEffect(() => {
		dispatch(getAdvertiserExternal());
	}, []);

	useEffect(() => {
		if (!_.isNull(advertiserExternalReducer)) {
		  setAdvertisesExternal(advertiserExternalReducer);
		  console.log(advertiserExternalReducer);
		}
	  }, [advertiserExternalReducer]);

	return (
		<>
			<Main>
				{/* <h1>Guia Dividendos (Home)</h1> */}
				<BgImage src={"images/home_bg.png"} alt="Informações sobre investimentos" />

				<Indicators />

				<LogoDiv>
					<Logo src="images/app-logo@2x.png" alt="Guia Dividendos - autoridade em proventos" />
					<h1 style={{ display: 'none' }}>Guia Dividendos</h1> {/* improve SEO */}
					<LogoTitle>autoridade em proventos</LogoTitle>

					<SearchBySymbol />

					<AreaAds>
						{advertisesExternal &&
							advertisesExternal.length > 0 && (
								<ContainerPartnerComp
									advertisesExternal={advertisesExternal}
								/>
						)}
					</AreaAds>
				</LogoDiv>

			</Main>

			<Sections>
				<Rankings />

				{/* ---------- Advertiser External ----------- */}
				{/* {advertisesExternal && advertisesExternal.length > 0 && (
					<ContainerPartnerComp
						advertisesExternal={advertisesExternal}
					/>
				)} */}

				<HorizontalAds />

				<IndicatorsComp />
				<LearnerCenter />
			</Sections>
		</>
	);
}