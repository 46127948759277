export const formatDate = (date) => {
    let newDate = new Date(date.toISOString());
    let year = newDate.getFullYear();
    let month = newDate.getMonth()+1;
    let dt = newDate.getDate();
  
    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    const newFormatedDate = `${month}-${dt}-${year}`
    return newFormatedDate
}