import React from "react";
import styles from "./styles.module.scss";

export function Container({ children }) {
  return <div className={styles.Container}>{children}</div>;
}

export function Content({ children }) {
  return <div className={styles.Content}>{children}</div>;
}
