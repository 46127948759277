import {
	CHART_DIVIDEND_CALENDAR_YEAR_GET_REDUCER,
	CLEAR_ALL_REDUCER,
	GET_DIVIDENDS_LIST_REDUCER,
	GET_DIVIDENDS_LIST_BY_TYPE_REDUCER,
    GET_DIVIDENDS_LIST_DETAIL_CLEAR_REDUCER,
	GET_DIVIDENDS_LIST_DETAIL_REDUCER,
	GET_DIVIDENDS_LIST_ONLY_MY_REDUCER,
	GET_DIVIDENDS_LIST_ERROR_REDUCER,
} from "../actions";

export const dividendCalendarReducer = (state = null, action) => {
	switch (action.type) {
		case CHART_DIVIDEND_CALENDAR_YEAR_GET_REDUCER:
			return action.payload;
		case CLEAR_ALL_REDUCER:
			return null;
		default:
			return state;
	}
};

export const getDividendsListReducer = (state = null, action) => {
	switch (action.type) {
		case GET_DIVIDENDS_LIST_REDUCER:
			return action.payload;
		default:
			return state;
	}
};

export const getDividendsListByTypeReducer = (state = null, action) => {
	switch (action.type) {
		case GET_DIVIDENDS_LIST_BY_TYPE_REDUCER:
			return action.payload;
		default:
			return state;
	}
};

export const getDividendsListDetailReducer = (state = null, action) => {
	switch (action.type) {
		case GET_DIVIDENDS_LIST_DETAIL_REDUCER:
			return action.payload;
		case GET_DIVIDENDS_LIST_DETAIL_CLEAR_REDUCER:
			return null;
		default:
			return state;
	}
};

export const getDividendsListOnlyMyReducer = (state = null, action) => {
	switch (action.type) {
		case GET_DIVIDENDS_LIST_ONLY_MY_REDUCER:
			return action.payload;
		default:
			return state;
	}
};

export const getDividendsListError = (state = null, action) => {
	switch (action.type) {
		case GET_DIVIDENDS_LIST_ERROR_REDUCER:
			return action.response;
		default:
			return state;
	}
};
