import styled from "styled-components";
import { Link } from "react-router-dom";

export const PageContainer = styled.div`
	height: auto;
	width: 100%;
	max-width: 900px;
`;

export const LinkBack = styled(Link)`
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #535353;
`;

export const DivFilter = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;

	@media only screen and (max-width: 800px) {
		flex-wrap: wrap;
		gap: 10px 0;
	}

	@media only screen and (min-width: 801px) {
		width: 100%;
	}
`;

export const FilterTitle = styled.h2`

	display: flex;
	align-items: center;

    margin-top: 0px;
	margin-left: 10px;
	color: #262626;
	font-size: 16px;
	font-weight: 600;

	width: 66%;
	margin-bottom: 15px;
	max-width: 601px;

	@media only screen and (max-width: 800px) {
		width: 100%;
	}
`;
