export const Types = {
	IS_LOADING: "IS_LOADING",
	IS_DIVIDEND_DETAIL_LOADING: "IS_DIVIDEND_DETAIL_LOADING",
	// IS_GET_AFFILIATION_LOADING: "IS_GET_AFFILIATION_LOADING",
};

export const loadingReducer = (state = { isLoading: false }, action) => {
	switch (action.type) {
		case Types.IS_LOADING:
			return { ...state, isLoading: action.isLoading };
		default:
			return state;
	}
};

export function isLoading(isLoading) {
	return {
		type: Types.IS_LOADING,
		isLoading,
	};
}

export function isDividendDetailLoading(state = { isLoading: false }, action) {
	switch (action.type) {
		case Types.IS_DIVIDEND_DETAIL_LOADING:
			return { ...state, isLoading: action.isLoading };
		default:
			return state;
	}
}

//   export function isGetAffiliationLoading(state = { isLoading: false }, action) {
//     switch (action.type) {
//       case Types.IS_GET_AFFILIATION_LOADING:
//         return { ...state, isLoading: action.isLoading };
//       default:
//         return state;
//     }
//   }
