import styled from 'styled-components'

export const Container = styled.div`
    margin-top: 6vh;
    /* margin-top: 74.5px; */
    display: flex;
    gap: 15px;
    /* width: 640px; */
    width: 30vw;
    justify-content: center;

	@media only screen and (max-width: 700px) {
        margin-top: 16px;
    }
`;
