import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import { getCompanyIndicators, getSearch } from "../../store/actions";

import { countries } from "../../helpers/countries";
import { formatDate } from "../../helpers/dateHelper";
import { getCurrency } from "../../helpers";
import { generalDividendsScheduleLink } from "../../helpers/internalLinks";
// import VerticalAds from '../../components/Ads/VerticalAds';
import GeneralFilters from "./GeneralFilters/index";
// import DividendTable from './components/SpecificTable/DividentTable'
import IncomeTab from "./IncomeTab";
import DataTab from "./DataTab";
import YieldTab from "./YieldTab";
import EventsTab from "./EventsTab";

// import { getTextColor2 } from "../../styles/themes/themeFunctions";
import styles from "./styles.module.scss";
import { PageContainer, LinkBack, DivFilter, FilterTitle } from "./styles";
import HeaderTabsComp from "../../components/common/MoversGenericPage/HeaderTabsComp";
import {
	Header,
	List,
	EmptyList,
} from "../../components/common/MoversGenericPage/styles";
import HorizontalAds from "../../components/common/Annoucements/HorizontalAds";
import { getLabel } from "../../helpers/autocompleteStocks";

function DividendsCalendarSymbolView() {
	const location = useLocation();
	const auxCountry = location.country || "Brazil";
	const dispatch = useDispatch();

	const companyIndicatorsReducer = useSelector(
		(state) => state.companyIndicatorsReducer
	);
	const searchReducer = useSelector((state) => state.searchReducer);
	const divCalendarSymbolDataReducer = useSelector(
		(state) => state.divCalendarSymbolDataReducer
	);

	// const history = useHistory();
	// const themeReducer = useSelector((state) => state.themeReducer);

	const page = "dividendsCalendarSymbol";

	// const [dividendCalendarType, setDividendCalendarType] = useState('ByPaymentDate');
	// const [country, setCountry] = useState("Brazil");
	// const [open, setOpen] = useState(false);
	// const [selectedDate, setSelectedDate] = useState(new Date());

	// const [tableHeight, setTableHeight] = React.useState(0);

	// Assets filter
	const [initialDate, setInitialDate] = useState(() => {
		const date = new Date();
		return new Date(date.getFullYear(), 0, 1);
	});
	const [endDate, setEndDate] = useState(() => {
		const date = new Date();
		return new Date(date.getFullYear(), 11, 31);
	});
	const [selectedInitialDate, setSelectedInitialDate] = useState(() => {
		const date = new Date();
		return formatDate(new Date(date.getFullYear(), 0, 1));
	});
	const [selectedEndDate, setSelectedEndDate] = useState(() => {
		const date = new Date();
		return formatDate(new Date(date.getFullYear(), 11, 31));
	});
	const [specificDividendCalendarType, setSpecificDividendCalendarType] =
		useState("ByDataCom");

	const [specificCountryOpen, setSpecificCountryOpen] = useState(false);
	const [specificCountry, SetSpecificCountry] = useState(auxCountry);
	const [dividendName, setDividendName] = useState(null);
	const [dividendNameTitle, setDividendNameTitle] = useState("Nome do ativo");

	const [active, setActive] = useState(1);

	// other tabs
	const [companyIndicators, setCompanyIndicators] = useState(null);
	const [currency, setCurrency] = useState("R$");
	const [stocks, setStocks] = useState([]);
	// const symbol = query.get("symbol");
	const symbol = location.pathname.replace("/", "");

	const cleanDividendNameTitle = useCallback(() => {
		const aux = "Nome do ativo";
		if (dividendNameTitle !== aux) setDividendNameTitle(aux);
	}, [dividendNameTitle]);

	useEffect(() => {
		// const symbol = location.pathname.split("/")[2];
		const symbol = location.pathname.replace('/', '');
		if(symbol === 'agenda-de-dividendos-por-ativo') return;
		setDividendName({ symbol });
	}, []);

	useEffect(() => {
		// console.log("location");
		// console.log(location);

		if (!location.obj) {
			// digitou direto na URL
			if (symbol) {
				let payload = {
					name: symbol,
				};

				dispatch(getSearch(payload));
			}
		} else {
		}
	}, [location, location.obj]);

	const getData = (item) => {
		// console.log('item')
		// console.log(item)
		if (!item) return;

		setDividendName(item);

		const { guidStock, idCountry } = item;

		if (guidStock) dispatch(getCompanyIndicators({ guidStock }));

		if (idCountry) {
			const auxC = idCountry === 1 ? "Brazil" : "EUA";
			SetSpecificCountry(auxC);
		}

		if (symbol) setDividendNameTitle(getLabel(item));
	};

	useEffect(() => {
		//const auxCountry = query.get("country");
		// const guidStock = query.get("guidStock");
		if (
			!_.isNull(
				divCalendarSymbolDataReducer
			) /*&& (!dividendName || symbol !== dividendName.symbol)*/
		) {
			const { guidStock, stockName } = divCalendarSymbolDataReducer;
			// getSearch({ name: symbol })
			// console.log(divCalendarSymbolDataReducer);

			if (guidStock) {
				getData(divCalendarSymbolDataReducer);
			} else {
				const payload = { name: stockName };
				dispatch(getSearch(payload));
			}
		}
	}, [dispatch, divCalendarSymbolDataReducer]);

	useEffect(() => {
		if (stocks.length === 1 && symbol === stocks[0].symbol) {
			getData(stocks[0]);
		} else if (stocks.length > 0 && !location.obj) {
			const filteredArr = stocks.filter((item) => item.symbol === symbol);
			getData(filteredArr[0]);
		}
	}, [stocks, location, location.obj, symbol]);

	const handleInitialDate = (date) => {
		const formatedDate = formatDate(date);
		setSelectedInitialDate(formatedDate);
		setInitialDate(date);
	};

	const handleEndDate = (date) => {
		const formatedDate = formatDate(date);
		setSelectedEndDate(formatedDate);
		setEndDate(date);
	};

	const handleChangeDividendSpecificCalendarType = (event) => {
		setSpecificDividendCalendarType(event.target.value);
	};

	const handleSpecificCountryClose = () => {
		setSpecificCountryOpen(false);
	};

	const handleSpecificCountryOpen = () => {
		setSpecificCountryOpen(true);
	};

	const handleSpeficicCountryChange = (event) => {
		SetSpecificCountry(event.target.value);
		setDividendName(null);
		cleanDividendNameTitle();
	};

	const handleChangeDividendName = (event) => {
		// handleSelectAutoComplete
		if (event) {
			setDividendName(event);
			//setDividendNameTitle(event.symbol);

			const item = event;
			setCurrency(getCurrency(item.idCountry));
			// setResponseData(item);
			dispatch(getCompanyIndicators({ guidStock: item.guidStock }));
		} else {
			setDividendName(null);
			cleanDividendNameTitle();
			// history.push('generalDividendsSchedule')
		}
	};

	useEffect(() => {
		if (!_.isNull(companyIndicatorsReducer)) {
			// console.log('companyIndicatorsReducer')
			// console.log(companyIndicatorsReducer)
			setCompanyIndicators(companyIndicatorsReducer);
		}
	}, [companyIndicatorsReducer]);

	useEffect(() => {
		if (!_.isNull(searchReducer)) {
			// console.log("searchReducer");
			// console.log(searchReducer);
			setStocks(searchReducer);

			// if(!location.obj) { // pesquisou direto pela URL
			// 	searchReducer[0]
			// }
		}
	}, [searchReducer, location, location.obj]);

	return (
		<div className={styles.upCommingDividendsView}>
			<div style={{ display: "flex", flexDirection: "row" }}>
				<PageContainer>
					<Grid container className={styles.gridContainer}>
						<div className={styles.upCommingDividendsContent}>
							<HorizontalAds />

							<Header
								page={page}
								style={{
									flexDirection: "column-reverse",
									gap: 15,
								}}
							>
								<HeaderTabsComp
									page={page}
									tabs={[
										{
											title: (
												<LinkBack
													to={
														generalDividendsScheduleLink
													}
												>
													<KeyboardBackspaceIcon
														style={{
															marginRight: 2,
														}}
													/>
													Agenda Geral de Dividendos
												</LinkBack>
											),
										},
										{ title: "Agenda Dividendos" }, // Calendário de Dividendos
										{ title: "Dados" },
										{ title: "Dividendos Yield" },
										{ title: "Eventos" },
									]}
									active={active}
									setActive={setActive}
								/>
								<DivFilter>
									<h1 style={{ display: "none" }}>
										Agenda de Dividendos por ativo
									</h1>{" "}
									{/* improve SEO */}
									<FilterTitle>
										Pesquise por ativo
									</FilterTitle>
									{/* List Header Filters */}
									<GeneralFilters
										countries={countries}
										country={specificCountry}
										dividendCalendarType={
											specificDividendCalendarType
										}
										handleChangeDividendCalendarType={
											handleChangeDividendSpecificCalendarType
										}
										handleCloseCountrySelectInput={
											handleSpecificCountryClose
										}
										handleOpenCountrySelectInput={
											handleSpecificCountryOpen
										}
										handleCountryChange={
											handleSpeficicCountryChange
										}
										openCountrySelec={specificCountryOpen}
										initialDate={initialDate}
										endDate={endDate}
										handleInitialDate={handleInitialDate}
										handleEndDate={handleEndDate}
										handleChangeDividendName={
											handleChangeDividendName
										}
										dividendName={dividendName}
										stocks={stocks}
									/>
								</DivFilter>
							</Header>
							<List page={page}>
								{dividendName && dividendName.symbol ? (
									<>
										{active === 1 && ( // calendário div
											<IncomeTab
												country={specificCountry}
												symbol={dividendName.symbol}
												dividendCalendarType={
													specificDividendCalendarType
												}
												currency={currency}
												selectedInitialDate={
													selectedInitialDate
												}
												selectedEndDate={
													selectedEndDate
												}
											/>
										)}
										{active === 2 && ( // dados
											<DataTab
												responseData={dividendName}
												// followsItems={followsItems}
												// loadAlerts={loadAlerts}
												// itemGuid={responseData.guidStock}
												companyIndicators={
													companyIndicators
												}
												currency={currency}
											/>
										)}
										{active === 3 && ( // Dividendos Yield
											<YieldTab
												companyIndicators={
													companyIndicators
												}
												currency={currency}
											/>
										)}
										{active === 4 && (
											<EventsTab
												stockGuid={
													dividendName.guidStock
												}
												selectedInitialDate={
													selectedInitialDate
												}
												selectedEndDate={
													selectedEndDate
												}
											/>
										)}
									</>
								) : (
									<EmptyList>pesquise por um ativo</EmptyList>
								)}
							</List>
						</div>
					</Grid>
				</PageContainer>

				{/* {purchaseReducer && !purchaseReducer.active && (
            <div className="ads-container">
              <VerticalAds />
            </div>
          )} */}
			</div>
		</div>
	);
}

export default DividendsCalendarSymbolView;
