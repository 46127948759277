import styled from "styled-components";

export const HeaderTabs = styled.div`
	display: flex;
	align-items: center;

	width: ${props => props.page === 'dividendsCalendarSymbol' ? '100%' : 'unset'};

	@media only screen and (max-width: 540px) {
		overflow-x: auto;
	}
`;

export const Tab = styled.div`
	width: 266px;
	padding: 0 2.57%;
	height: 60px;

	// active
	background: ${(props) => (props.active ? "#ffffff" : "transparent")};
	margin-bottom: ${(props) => (props.active ? "-1px" : "0")};
	border: ${(props) => (props.active ? "1px solid #dedede" : "none")};

	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	border-bottom: none;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: 0.4s;

	&:hover {
		background: #eee;

		span {
			color: #000;
			font-weight: 950;
		}
	}

	@media only screen and (max-width: 1900px) {
		width: ${props => {
			if(props.page === 'generalDividendsSchedule') {
				return '240px'
			} else if(props.page === 'learnerCenter') {
				return '24vw'
			} else {
				return '130px'
			}
		}};
		/* max-width: ${props => props.page === 'learnerCenter' ? '115px' : 'unset' }; */
	}
`;

export const TabText = styled.span`
	font-weight: 600;
	color: ${(props) => (props.active ? "#262626" : "#535353")};
	transition: 0.4s;

	h1, h2 {
		font-size: 16px;
		margin-bottom: 3px;
	}
`;

export const TabActive = styled.div`
	height: 5px;
	background-color: #01cc7f;
	width: 100%;
	margin-top: 2px;
`;