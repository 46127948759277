import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { getSplitByStock } from "../../../store/actions";
import { isAnnuity, isGold } from "../../../helpers";
// import { formatDate } from '../../../helpers/dateHelper'
import { Container, Content } from "../IncomeTab/components/index";
import SpecificTable from "./components/SpecificTable";
// import AreaFilters from "../components/common/AreaFilters";

export default function EventsTab({ stockGuid, selectedInitialDate, selectedEndDate }) {
  // console.log("stockGuid: ", stockGuid);

  const dispatch = useDispatch();
  // const dNow = new Date();
  // const yearNow = dNow.getFullYear().toString();

    const themeReducer = useSelector(state => state.themeReducer);
  const splitByStockReducer = useSelector(state => state.splitByStockReducer);

  const [userGold, setUserGold] = useState(false);
  const [visibleLoadDiag, setVisibleLoadDiag] = useState(false);
  //   const [dialogOnlyPremium, setDialogOnlyPremium] = useState(false);

  const [dividends, setDividends] = useState(null);
  const [dividendsReady, setDividendsReady] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [canAccess, setCanAccess] = useState(false);


  /* ---------------- Filters ---------------- */
  // const [startDate, setStartDate] = useState("01-01-" + yearNow);
  // const [endDate, setEndDate] = useState("31-12-" + yearNow);
  /*const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    return new Date(date.getFullYear(), 0, 1);
  });
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    return new Date(date.getFullYear(), 11, 31);
  });
  const [selectedInitialDate, setSelectedInitialDate] = useState(() => {
    const date = new Date();
    return formatDate(new Date(date.getFullYear(), 0, 1))
  })
  const [selectedEndDate, setSelectedEndDate] = useState(() => {
    const date = new Date();
    return formatDate(new Date(date.getFullYear(), 11, 31))
  })*/

  const reloadDividends = useCallback(
    (selectedInitialDate, selectedEndDate, stockGuidV) => {
      if (selectedInitialDate && selectedEndDate && stockGuidV) {
        setTimeout(() => {
          const payload = {
            stockGuid: stockGuidV,
            startDate: selectedInitialDate, //: newFormatStartDate,
            endDate: selectedEndDate, //: newFormatEndDate
          }

          dispatch(
            getSplitByStock(payload)
          );
        }, 1500);
      } else {
        setVisibleLoadDiag(false);
        setDividendsReady(true);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    reloadDividends(selectedInitialDate, selectedEndDate, stockGuid);
  }, [selectedInitialDate, selectedEndDate, stockGuid, reloadDividends]);

  const loadData = useCallback(async () => {
    //setVisibleLoadDiag(true);

    reloadDividends(selectedInitialDate, selectedEndDate, stockGuid);
  }, [reloadDividends, selectedEndDate, selectedInitialDate, stockGuid]);

  useEffect(() => {
    setDividendsReady(false);
  }, []);

  useEffect(() => {
    isGold()
      .then(gold => {
        isAnnuity()
          .then(annuity => {
            setUserGold(gold);
            setCanAccess(annuity || gold);
          })
          .catch(() => console.log("erro annuity"));
      })
      .catch(() => console.log("erro gold"));

    loadData();
  }, [loadData]);

  useEffect(() => {
    if (!_.isNull(splitByStockReducer)) {
      setDividends(splitByStockReducer);
      dividendsReducerUpdade();
      setVisibleLoadDiag(false);
      setDividendsReady(true);
      //console.log(splitByStockReducer);
    }
  }, [splitByStockReducer]);

  async function dividendsReducerUpdade() {
    setDividendsReady(true);
    setRefreshing(false);
    setVisibleLoadDiag(false);
  }

  //   function goToPremium() {
  //     setDialogOnlyPremium(false);
  //     history.push("/Premium");
  //   }


  /*function handleChangeStartDate(value) {
    const formatedDate = formatDate(value)
    setSelectedInitialDate(formatedDate)

    setStartDate(value);
    setVisibleLoadDiag(true);
    setDividendsReady(false);

    reloadDividends(formatedDate, selectedEndDate, stockGuid);
    // reloadDividends(value, endDate, stockGuid);
  }

  function handleChangeEndDate(value) {
    const formatedDate = formatDate(value)
    setSelectedEndDate(formatedDate)

    setEndDate(value);
    setVisibleLoadDiag(true);
    setDividendsReady(false);

    reloadDividends(selectedInitialDate, formatedDate, stockGuid);
    // reloadDividends(startDate, value, stockGuid);
  }*/

  //   function handleRefreshScreen() {
  //     setRefreshing(true);
  //     setDividendsReady(false);

  //     reloadDividends(startDate, endDate, stockGuid);
  //   }

  return (
    <React.Fragment>
      <Container showsVerticalScrollIndicator={false}>
        {/* <AreaFilters
          initialDate={startDate}
          endDate={endDate}
          handleInitialDate={handleChangeStartDate}
          handleEndDate={handleChangeEndDate}
        /> */}

        <Content>
          {dividendsReady ? (
            <div
              style={{
                paddingLeft: 20,
                backgroundColor: themeReducer ? "#131A20" : "#fff",
              }}
            >
              {/* <MounthTitle>
                  {dividends.month} {year}
                </MounthTitle> */}

              {dividends ? (
                <SpecificTable
                    // dividendName={symbol}
                    // dividendCalendarType={dividendCalendarType}
                    loadingDividendDetailReducer={{ isLoading: !dividendsReady }}
                    dividendListDetailReducer={dividends}
                />
              ) : <div>Não há dados para mostrar</div>}
            </div>
          ) : (
            <div
              style={{
                height: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <CircularProgress />
            </div>
            // <ShimmerComponent />
          )}
        </Content>
        {/* {country === 'EUA' ? <BlurComponent ... /> : null} */}
      </Container>

      {/* Dialog Only premium */}
      {/* <h4>Dialog Premium</h4> */}
    </React.Fragment>
  );
}
