export const getBgColor = (t) => t ? "#131A20" : "#f7f8fa"
export const getBgColor2 = (t) => t ? "#000" : "#fff"

export const getBorderColor = (t) => t ? "#535353" : "#cfcfcf"
export const getBoxShadowColor = (t) => t ? "#333333" : "#cfcfcfab"

export const getChartBgColor = (t) => t ? "#131A20" : "#ffffff"

export const getTextColor = (t) => t ? "#ccc" : "#434343"
export const getTextColor2 = (t) => t ? "#fff" : "#000"
export const getTextColor3 = (t) => t ? "#cfcfcfab" : "#535353"
export const getTextColor4 = (t) => t ? "#ccc" : "#8C8C8C"
export const getBlueTextColor = (t) => t ? "#4689db" : "#1f4068"
export const getErrorTextColor = (t) => t ? "#ffa500" : "#ff0000"
