export const getLabel = (item) => {
    const { symbol, name, /*companyName, description*/ } = item;
    let aux = '';

    if(name) {
      aux = `(${name})`
    }/* else if(companyName) {
      aux = `(${companyName})`
    } else if(description) {
      aux = `(${description})`
    }*/

    return `${symbol} ${aux}`
}

export const getStockOptions = (arr) => {
    if(!arr) return [];

    return arr.map(item => {
      return {
        label: getLabel(item),
        ...item
      };
    });
  };
  