import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { CircularProgress } from "@material-ui/core";

import { getAffiliationType } from "../../store/actions";

import {
	Container,
	PageHeader,
	Title,
	Text,
	Container2,
	Content,
	Header,
	List,
	LoadingDiv,
} from "../../components/common/MoversGenericPage/styles";
import { SuggestionsText } from "./styles";
import Affiliation from "./Affiliation";

import HeaderTabsComp from "../../components/common/MoversGenericPage/HeaderTabsComp";

function LearnerCenterView() {
	const dispatch = useDispatch();

	const affiliationTypeReducer = useSelector(
		(state) => state.affiliationTypeReducer
	);
	// const isGetAffiliationLoading = useSelector(
	// 	(state) => state.isGetAffiliationLoading
	// );

	const tabs = [
		{ title: "E-books", type: "Ebook" },
		{ title: "Livros", type: "Book" },
		{ title: "Cursos", type: "Course" },
	];

	const [active, setActive] = useState(1);
	const [data, setData] = useState(null);

	useEffect(() => {
		if (!_.isEmpty(affiliationTypeReducer)) {
			// console.warn("affiliationTypeReducer");
			// console.log(affiliationTypeReducer);
			setData(affiliationTypeReducer);
		}
	}, [active, affiliationTypeReducer]);

	const handleClickAffiliationType = useCallback(
		(type) => {
			setData(null);

			dispatch(
				getAffiliationType({
					type: type,
				})
			);
		},
		[dispatch]
	);

	useEffect(() => {
		handleClickAffiliationType("Book");
	}, []);

	useEffect(() => {
		console.log("type: ", tabs[active].type);
		handleClickAffiliationType(tabs[active].type);
	}, [active]);

	return (
		<Container>
			<PageHeader style={{ maxWidth: 1093, margin: "0 auto" }}>
				<Title>Central do Aprendiz</Title>
				<Text>
					Acompanhe a seleção de materiais de estudos que nossa equipe preparou para você
				</Text>
			</PageHeader>

			<Container2>
				<Content style={{ margin: "0 auto", width: '100%' }}>
					<Header page="learnerCenter">
						<HeaderTabsComp
							tabs={tabs}
							active={active}
							setActive={setActive}
						/>
					</Header>
					<List>
						{data ? (
							<>
								{/* <SuggestionsText>Sugestões para você</SuggestionsText> */}
								<Affiliation
									Affiliations={data}
									type={tabs[active].type || "EBook"}
								/>
							</>
						) : (
							<LoadingDiv>
								<CircularProgress />
							</LoadingDiv>
						)}
					</List>
				</Content>
			</Container2>
		</Container>
	);
}

export default LearnerCenterView;
