import styled from "styled-components";

export const Container = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	margin-top: 70px;
	padding: 24px 10% 14px;
`;

export const Title2 = styled.h2`
	text-align: left;
	font: normal normal bold 20px/24px Usual;
	letter-spacing: 0px;
	color: #262626;
	opacity: 1;
`;

export const Title3 = styled.h3`
	text-align: left;
	font: normal normal bold 20px/24px Usual;
	letter-spacing: 0px;
	color: #262626;
	opacity: 1;
`;

export const List = styled.div`
	display: flex;
	gap: 4%;
	margin-top: 31px;
	flex-wrap: wrap;
`;

export const Card = styled.div`
	width: 21.7%;
	min-width: 350px;
	max-width: 417px;
	/* height: 258px; */
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 6px #cfcfcfab;
	border: 1px solid #dedede;
	opacity: 1;
	border-radius: 15px;

	padding: 11.71px 9.52px;
	margin-bottom: 20px;

	@media only screen and (max-width: 700px) {
		min-width: unset;
		width: 100%;
	}
`;

export const Top = styled.div`
	display: flex;
	align-items: center;

	@media only screen and (max-width: 700px) {
		flex-wrap: wrap;
	}
`;

// export const ImageDiv = styled.div``;

export const Image = styled.img`
	/* width: 104px; */
	height: 88px;
	object-fit: cover;
	margin-right: 17.57px;
	border-radius: 15px;
`;

export const Body = styled.div`
	max-height: 107px;
	overflow-y: hidden;

	text-align: left;
	font: normal normal normal 14px/17px Usual;
	letter-spacing: -0.14px;
	color: #535353;
	opacity: 1;
	margin-top: 9px;
`;

export const FooterLink = styled.a`
	text-decoration: none;
	height: 31.06px;
	color: #535353;
	cursor: pointer;
	transition: 0.3s;

	display: flex;
	justify-content: flex-end;
	align-items: center;

	&:hover {
		border-radius: 5px;
		padding: 0 5px;
		background: #01cc7f;
		color: #fff;
	}

	@media only screen and (min-width: 400px) {
		margin-left: 69%;
	}
`;

export const FooterText = styled.span`
	font: normal normal 800 13px/16px Usual;
	letter-spacing: -0.07px;
	margin-right: 6.14px;
`;

export const ButtonExpand = styled.div`
	margin-top: 28.76px;
	cursor: pointer;
	text-align: center;

	font: normal normal 800 13px/16px Usual;
	letter-spacing: -0.07px;
	color: #535353;
	max-width: 170px;
   	margin: 0 auto;
	transition: 0.5s;

	&:hover {
		background: #262626;
		color: #fff;
		border-radius: 5px;
		padding: 4px;

	}
`;
